/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  colors,
  StoreProduct,
  UpdateProduct,
  UpdateStoreProduct,
} from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Controller, Field, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../../../api';
import {
  adminAtom,
  merchantAtom,
  selectedStoreAtom,
} from '../../../recoil/atoms';

interface Props {
  item: StoreProduct;
  isEditing?: boolean;
  setProductValues?: (values: any) => void;
}

interface IStoreProduct {
  merchant_id: string;
  store_id: string;
  updateStoreProductDto: UpdateStoreProduct;
}

const ProductDetailsCardForm = ({
  item,
  isEditing,
  setProductValues,
}: Props) => {
  const [isWeighted, setIsWeighted] = useState(false);
  const [isSoldOnline, setIsSoldOnline] = useState(false);
  const [isForsale, setIsForSale] = useState(false);
  const [isRangeModalOpen, setIsRangeModalOpen] = useState(false); // State for modal
  const merchant = useRecoilValue(merchantAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [loadingButton, setLoadingButton] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const employee = useRecoilValue(adminAtom);
  const toast = useToast();

  // Determine if inventory is low
  const isInventoryLow = item.inventory < (item.fraction || 0);

  const { control, handleSubmit, reset, watch, getValues, setValue } = useForm({
    defaultValues: {
      name: '',
      price: 0,
      cost: 0,
      unit: '',
      quantity_in_unit_package: 0,
      max_selling_price: 0,
      min_selling_price: 0,
      low_stock_threshold: 0,
      is_available_for_sale: false,
      is_sold_by_weight: false,
      weight_kg: 0,
      is_available_in_store: false,
      is_manually_controlled: false,
      is_sold_by_range: false,
      is_sold_online: false,
    },
  });

  // Separate form for the Range Modal
  const {
    control: controlRange,
    handleSubmit: handleSubmitRange,
    formState: { errors: errorsRange },
    watch: watchRangeForm,
    reset: resetRangeForm,
  } = useForm({
    defaultValues: {
      min_selling_price: 0,
      max_selling_price: 0,
    },
  });

  useEffect(() => {
    if (item?.id) {
      // Use reset to populate the form with fetched data
      reset({
        name: item.name,
        price: item.price,
        cost: Math.ceil(item.cost),
        unit: item.unit,
        quantity_in_unit_package: item.quantity_in_unit_package,
        max_selling_price: item.max_selling_price,
        min_selling_price: item.min_selling_price,
        low_stock_threshold: item.low_stock_threshold,
        is_available_for_sale: item.is_available_for_sale,
        is_sold_online: item.is_sold_online,
        is_sold_by_weight: item.is_sold_by_weight,
        weight_kg: item.weight_kg,
        is_available_in_store: item.is_available_in_store,
        is_manually_controlled: item.is_manually_controlled,
        is_sold_by_range: item.is_sold_by_range,
      });
      setIsWeighted(item.is_sold_by_weight);
      setIsSoldOnline(item.is_sold_online);
      setIsForSale(item.is_available_for_sale);
    }
  }, [item, reset]);

  // Mutation for updating store product
  const { mutateAsync: updateStoreProduct, isLoading: isUpdatingStoreProduct } =
    useMutation((data: IStoreProduct) => apiAdapter.updateStoreProduct(data), {
      onSuccess: (data) => {
        if (!data?.id) return;
        queryClient.invalidateQueries(`get-product-${data.id}`);
        queryClient.invalidateQueries(`store-products`);
        toast({
          title: 'Product Updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error: any) => {
        if (error?.response?.data?.message) {
          toast({
            title: 'Error Updating Product',
            description: `${error?.response?.data?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Error Updating Product',
            description: `${error}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onSettled: () => {
        setLoadingButton(null); // Reset loading button
      },
    });

  // Mutation for updating product
  const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } =
    useMutation((data: UpdateProduct) => apiAdapter.updateProduct(data), {
      onSuccess: (data) => {
        if (!data?.id) return;
        queryClient.invalidateQueries(`get-product-${data.id}`);
        queryClient.invalidateQueries(`store-products`);
        toast({
          title: 'Product Updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error: any) => {
        if (error?.response?.data?.message) {
          toast({
            title: 'Error Updating Product',
            description: `${error?.response?.data?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        } else {
          toast({
            title: 'Error Updating Product',
            description: `${error}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      },
      onSettled: () => {
        setLoadingButton(null); // Reset loading button
      },
    });

  // Mutation for updating selling price range
  const updateSellingPriceRangeMutation = useMutation(
    (data: {
      id: string;
      min_selling_price: number;
      max_selling_price: number;
    }) =>
      apiAdapter.updateStoreProduct({
        merchant_id: merchant?.id as string,
        store_id: currentStore?.id as string,
        updateStoreProductDto: {
          id: data.id,
          min_selling_price: data.min_selling_price,
          max_selling_price: data.max_selling_price,
          merchant_id: merchant?.id as string,
          store_id: currentStore?.id as string,
          updator_id: employee?.id as string,
        },
      }),
    {
      onSuccess: (data) => {
        if (!data?.id) return;
        queryClient.invalidateQueries(`get-product-${data.id}`);
        queryClient.invalidateQueries(`store-products`);
        toast({
          title: 'Selling Price Range Updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error: any) => {
        toast({
          title: 'Error Updating Selling Price Range',
          description: `${error}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const onSubmit = async (
    formData: {
      name?: string;
      price?: number;
      cost?: number;
      unit?: string;
      quantity_in_unit_package?: number;
      max_selling_price?: number;
      min_selling_price?: number;
      low_stock_threshold?: number;
      is_available_for_sale?: boolean;
      is_sold_by_weight?: boolean;
      weight_kg?: number;
      is_sold_online?: boolean;
      is_available_in_store?: boolean;
      is_manually_controlled?: boolean;
      is_sold_by_range?: boolean;
      [key: string]: any;
    },
    buttonName: string,
  ) => {
    console.log('Form Data:', formData);
    console.log('Item State:', item);
    setLoadingButton(buttonName);

    const updatedProductData = {} as any;
    const updatedStoreProductData = {} as any;

    Object.keys(formData).forEach((key) => {
      // Exclude 'cost' from being updated if it's read-only
      if (key === 'cost' && item.parent_id !== item.id) return;

      // Check if item has a key and if its value differs from the form value
      if (item && formData[key] !== item[key as keyof StoreProduct]) {
        // Determine if the field belongs to UpdateProduct or UpdateStoreProduct
        if (['quantity_in_unit_package', 'unit'].includes(key)) {
          // Field belongs to UpdateProduct
          updatedProductData[key] = formData[key];
        } else {
          // Field belongs to UpdateStoreProduct
          updatedStoreProductData[key] = formData[key];
        }
      }
    });

    // Check if there are changes
    if (
      Object.keys(updatedProductData).length === 0 &&
      Object.keys(updatedStoreProductData).length === 0
    ) {
      toast({
        title: 'No Changes Made',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Ensure numerical fields are correctly parsed
    const parseNumberFields = (data: any, fields: string[]) => {
      fields.forEach((field) => {
        if (data[field] !== undefined) {
          data[field] = Number(data[field]);
        }
      });
    };

    parseNumberFields(updatedProductData, [
      'quantity_in_unit_package',
      'weight_kg',
    ]);
    parseNumberFields(updatedStoreProductData, [
      'price',
      'cost',
      'quantity_in_unit_package',
      'max_selling_price',
      'min_selling_price',
      'low_stock_threshold',
      'weight_kg',
    ]);

    // Add the missing data
    if (Object.keys(updatedProductData).length > 0) {
      updatedProductData.id = item?.parent_id || item?.id;
      updatedProductData.merchant_id = merchant?.id as string;
      updatedProductData.updator_id = employee?.id as string;
    }

    if (Object.keys(updatedStoreProductData).length > 0) {
      updatedStoreProductData.id = item?.id as string;
      updatedStoreProductData.store_id = currentStore?.id as string;
      updatedStoreProductData.merchant_id = merchant?.id as string;
      updatedStoreProductData.updator_id = employee?.id as string;
    }

    // Validate selling price range if applicable
    if (
      updatedStoreProductData.min_selling_price !== undefined ||
      updatedStoreProductData.max_selling_price !== undefined
    ) {
      const minPrice =
        updatedStoreProductData.min_selling_price !== undefined
          ? updatedStoreProductData.min_selling_price
          : item.min_selling_price;
      const maxPrice =
        updatedStoreProductData.max_selling_price !== undefined
          ? updatedStoreProductData.max_selling_price
          : item.max_selling_price;

      if (minPrice < item.cost) {
        toast({
          title: 'Minimum Selling Price must be at least the cost.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (maxPrice < minPrice) {
        toast({
          title:
            'Maximum Selling Price must be at least the minimum selling price.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
        return;
      }
    }

    try {
      if (Object.keys(updatedProductData).length > 0) {
        await updateProduct(updatedProductData);
      }
      if (Object.keys(updatedStoreProductData).length > 0) {
        await updateStoreProduct({
          merchant_id: merchant?.id as string,
          store_id: currentStore?.id as string,
          updateStoreProductDto: updatedStoreProductData,
        });
      }

      // After mutation, check if 'is_sold_by_range' is set to true
      if (
        buttonName === 'save-is_sold_by_range' &&
        updatedStoreProductData.is_sold_by_range
      ) {
        setIsRangeModalOpen(true);
      }
    } catch (error) {
      console.log('Error in onSubmit:', error);
      // Error handling is already done in mutation hooks
    }
  };

  // Handler for submitting the Range Modal form
  const onRangeSubmit = async (data: {
    min_selling_price: number;
    max_selling_price: number;
  }) => {
    const { min_selling_price, max_selling_price } = data;

    // Validate: min >= cost, max >= min
    if (min_selling_price < item.cost) {
      toast({
        title: 'Minimum Selling Price must be at least the cost.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (max_selling_price < min_selling_price) {
      toast({
        title:
          'Maximum Selling Price must be at least the minimum selling price.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      // Update selling price range
      await updateSellingPriceRangeMutation.mutateAsync({
        id: item.id,
        min_selling_price,
        max_selling_price,
      });

      // Close the modal
      setIsRangeModalOpen(false);

      // Optionally reset the modal form
      resetRangeForm();
    } catch (error) {
      console.log('Error updating selling price range:', error);
      // Error handling is already done in mutation hooks
    }
  };

  // Set the modal form values when the modal opens
  useEffect(() => {
    if (isRangeModalOpen) {
      resetRangeForm({
        min_selling_price: item.min_selling_price || 0,
        max_selling_price: item.max_selling_price || 0,
      });
    }
  }, [isRangeModalOpen, item, resetRangeForm]);

  // Set the values in the setProductValues watch the values
  useEffect(() => {
    if (setProductValues) {
      setProductValues({
        name: watch('name'),
        price: Number(watch('price')),
        cost: Number(watch('cost')),
        unit: watch('unit'),
        quantity_in_unit_package: Number(watch('quantity_in_unit_package')),
        max_selling_price: Number(watch('max_selling_price')),
        min_selling_price: Number(watch('min_selling_price')),
        low_stock_threshold: Number(watch('low_stock_threshold')),
        is_available_for_sale: watch('is_available_for_sale'),
        is_sold_by_weight: watch('is_sold_by_weight'),
        weight_kg: Number(watch('weight_kg')),
        is_available_in_store: watch('is_available_in_store'),
        is_manually_controlled: watch('is_manually_controlled'),
        is_sold_by_range: watch('is_sold_by_range'),
        is_sold_online: watch('is_sold_online'),
      });
    }
  }, [
    watch('name'),
    watch('price'),
    watch('cost'),
    watch('unit'),
    watch('quantity_in_unit_package'),
    watch('max_selling_price'),
    watch('min_selling_price'),
    watch('low_stock_threshold'),
    watch('is_available_for_sale'),
    watch('is_sold_by_weight'),
    watch('weight_kg'),
    watch('is_available_in_store'),
    watch('is_manually_controlled'),
    watch('is_sold_by_range'),
    watch('is_sold_online'),
    setProductValues,
  ]);

  // Handler for updating selling price range directly from form fields (optional)
  const handleUpdateSellingPriceRange = async () => {
    const min_selling_price = Number(getValues('min_selling_price'));
    const max_selling_price = Number(getValues('max_selling_price'));

    // Perform validation
    if (min_selling_price < item.cost) {
      toast({
        title: 'Minimum Selling Price must be at least the cost.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (max_selling_price < min_selling_price) {
      toast({
        title:
          'Maximum Selling Price must be at least the minimum selling price.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      await updateSellingPriceRangeMutation.mutateAsync({
        id: item.id,
        min_selling_price,
        max_selling_price,
      });
    } catch (error) {
      console.log('Error updating selling price range:', error);
      // Error handling is already done in mutation hooks
    }
  };

  const isUpdating =
    isUpdatingStoreProduct ||
    isUpdatingProduct ||
    updateSellingPriceRangeMutation.isLoading;

  return (
    <Box p={4} borderRadius="md" maxW="full" mx="auto">
      <Text textAlign="center" fontSize="lg" fontWeight="bold" mb={4}>
        {item?.name}
      </Text>

      {/* Form Fields */}
      <Flex justify="space-between" gap={2} align="center" bg="white" p={2}>
        <Box flex="1">
          <Text color={'black'} fontSize={'sm'}>
            Unit
          </Text>
          <Flex
            justify={'center'}
            align={'center'}
            borderWidth={'1px'}
            borderColor={colors.primary}
            px={2}
            borderRadius={'10px'}>
            <Controller
              name="unit"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  border={0}
                  variant="flushed"
                  placeholder="Unit"
                />
              )}
            />

            {!isEditing && (
              <Box textAlign="right">
                <IconButton
                  isLoading={loadingButton === 'save-unit' && isUpdating}
                  onClick={() => {
                    if (getValues('unit') === item?.unit) {
                      toast({
                        title: 'No Changes Made',
                        status: 'info',
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }
                    handleSubmit((data) => onSubmit(data, 'save-unit'))();
                  }}
                  aria-label="Save"
                  variant={'none'}
                  color={'green.400'}
                  isDisabled={
                    isUpdating ||
                    getValues('unit') === item?.unit ||
                    (!!loadingButton && loadingButton !== 'save-unit')
                  }
                  icon={<FaSave />}
                />
              </Box>
            )}
          </Flex>
        </Box>

        <Box flex="1">
          <Text color={'black'} fontSize={'sm'}>
            Items in unit
          </Text>
          <Flex
            justify={'center'}
            align={'center'}
            borderWidth={'1px'}
            borderColor={colors.primary}
            px={2}
            borderRadius={'10px'}>
            <Controller
              name="quantity_in_unit_package"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  border={0}
                  variant="flushed"
                  placeholder="Unit Items"
                />
              )}
            />
            {!isEditing && (
              <Box textAlign="right">
                <IconButton
                  isLoading={
                    loadingButton === 'save-quantity_in_unit_package' &&
                    isUpdating
                  }
                  onClick={() => {
                    if (
                      getValues('unit') === item?.unit &&
                      Number(getValues('quantity_in_unit_package')) ===
                        item?.quantity_in_unit_package
                    ) {
                      toast({
                        title: 'No Changes Made',
                        status: 'info',
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }
                    handleSubmit((data) =>
                      onSubmit(data, 'save-quantity_in_unit_package'),
                    )();
                  }}
                  aria-label="Save"
                  variant={'none'}
                  color={'green.400'}
                  isDisabled={
                    isUpdating ||
                    (getValues('unit') === item?.unit &&
                      Number(getValues('quantity_in_unit_package')) ===
                        item?.quantity_in_unit_package) ||
                    (!!loadingButton &&
                      loadingButton !== 'save-quantity_in_unit_package')
                  }
                  icon={<FaSave />}
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>

      {/* Current Price and Cost */}
      <Flex
        justify="space-between"
        gap={2}
        my={2}
        align="center"
        bg="white"
        p={2}>
        <Box flex="1">
          <Text color={'black'} fontSize="sm">
            Current Price
          </Text>
          <Flex
            justify={'center'}
            align={'center'}
            borderWidth={'1px'}
            borderColor={colors.primary}
            px={2}
            borderRadius={'10px'}>
            <Controller
              name="price"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  border={0}
                  variant="flushed"
                  placeholder="Price"
                />
              )}
            />
            {!isEditing && (
              <Box textAlign="right">
                <IconButton
                  isLoading={loadingButton === 'save-price' && isUpdating}
                  onClick={() => {
                    // if nothing changed return toast
                    if (
                      Number(getValues('price')) === item?.price &&
                      Number(getValues('cost')) === item?.cost
                    ) {
                      toast({
                        title: 'No Changes Made',
                        status: 'info',
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }
                    handleSubmit((data) => onSubmit(data, 'save-price'))();
                  }}
                  aria-label="Save"
                  variant={'none'}
                  color={'green.400'}
                  isDisabled={
                    isUpdating ||
                    (Number(getValues('price')) === item?.price &&
                      Number(getValues('cost')) === item?.cost) ||
                    (!!loadingButton && loadingButton !== 'save-price')
                  }
                  icon={<FaSave />}
                />
              </Box>
            )}
          </Flex>
        </Box>

        <Box flex="1">
          <Text color={'black'} fontSize="sm">
            Cost
          </Text>
          <Flex
            justify={'center'}
            align={'center'}
            borderWidth={'1px'}
            borderColor={colors.primary}
            px={2}
            borderRadius={'10px'}>
            <Controller
              name="cost"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  _focus={{
                    boxShadow: 'none',
                  }}
                  isReadOnly={item?.parent_id !== item?.id}
                  border={0}
                  variant="flushed"
                  placeholder="Cost"
                />
              )}
            />
            {!isEditing && (
              <Box textAlign="right">
                <IconButton
                  isLoading={loadingButton === 'save-cost' && isUpdating}
                  onClick={() => {
                    // Cost cannot be updated if it's read-only
                    if (item?.parent_id !== item?.id) {
                      toast({
                        title: 'Cost cannot be updated.',
                        status: 'info',
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }

                    // If cost is editable, handle the save
                    if (getValues('cost') === item?.cost) {
                      toast({
                        title: 'No Changes Made',
                        status: 'info',
                        duration: 3000,
                        isClosable: true,
                      });
                      return;
                    }
                    handleSubmit((data) => onSubmit(data, 'save-cost'))();
                  }}
                  aria-label="Save"
                  variant={'none'}
                  color={'green.400'}
                  isDisabled={
                    isUpdating ||
                    (Number(getValues('price')) === item?.price &&
                      Number(getValues('cost')) === item?.cost) ||
                    (!!loadingButton && loadingButton !== 'save-cost')
                  }
                  icon={<FaSave />}
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>

      {/* Min and Max Selling Price Fields */}
      <Flex justify="space-between" gap={2} align="center" bg="white" p={2}>
        <Box flex="1" mr={2}>
          <Text color={'black'} fontSize="sm">
            Minimum Selling Price
          </Text>
          <Flex
            justify={'center'}
            align={'center'}
            borderWidth={'1px'}
            borderColor={colors.primary}
            px={2}
            borderRadius={'10px'}
            bg={watch('is_sold_by_range') ? 'white' : 'gray.100'}>
            <Controller
              name="min_selling_price"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  _focus={{
                    boxShadow: 'none',
                  }}
                  border={0}
                  variant="flushed"
                  placeholder="Min Price"
                  isDisabled={isUpdating || !watch('is_sold_by_range')}
                  min={item?.cost || 0}
                />
              )}
            />
            {watch('is_sold_by_range') && !isEditing && (
              <Box textAlign="right">
                <IconButton
                  isLoading={
                    loadingButton === 'save-min_selling_price' && isUpdating
                  }
                  onClick={() => {
                    handleUpdateSellingPriceRange();
                  }}
                  aria-label="Save Min Price"
                  variant={'none'}
                  color={'green.400'}
                  isDisabled={
                    isUpdating ||
                    Number(getValues('min_selling_price')) ===
                      item?.min_selling_price ||
                    (!!loadingButton &&
                      loadingButton !== 'save-min_selling_price')
                  }
                  icon={<FaSave />}
                />
              </Box>
            )}
          </Flex>
        </Box>

        <Box flex="1" ml={2}>
          <Text color={'black'} fontSize="sm">
            Maximum Selling Price
          </Text>
          <Flex
            justify={'center'}
            align={'center'}
            borderWidth={'1px'}
            borderColor={colors.primary}
            px={2}
            borderRadius={'10px'}
            bg={watch('is_sold_by_range') ? 'white' : 'gray.100'}>
            <Controller
              name="max_selling_price"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="number"
                  _focus={{
                    boxShadow: 'none',
                  }}
                  border={0}
                  variant="flushed"
                  placeholder="Max Price"
                  isDisabled={isUpdating || !watch('is_sold_by_range')}
                  min={watch('min_selling_price') || item?.cost || 0}
                />
              )}
            />
            {watch('is_sold_by_range') && !isEditing && (
              <Box textAlign="right">
                <IconButton
                  isLoading={
                    loadingButton === 'save-max_selling_price' && isUpdating
                  }
                  onClick={() => {
                    handleUpdateSellingPriceRange();
                  }}
                  aria-label="Save Max Price"
                  variant={'none'}
                  color={'green.400'}
                  isDisabled={
                    isUpdating ||
                    Number(getValues('max_selling_price')) ===
                      item?.max_selling_price ||
                    (!!loadingButton &&
                      loadingButton !== 'save-max_selling_price')
                  }
                  icon={<FaSave />}
                />
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>

      {/* Selling Price Range Toggle */}
      <Flex mt={5} justify={'space-between'} align={'center'}>
        <Flex justify="flex-start" gap={2} flex="1" align={'center'}>
          <Controller
            name="is_available_for_sale"
            control={control}
            render={({ field }) => (
              <Switch
                size="sm"
                colorScheme="green"
                {...(field as unknown as Field)}
                isChecked={field.value}
                isDisabled={isUpdating || isInventoryLow} // Disable based on inventory
                onChange={() => {
                  const newValue = !isForsale;
                  setValue('is_available_for_sale', newValue);
                  setIsForSale(newValue);
                  if (!isEditing) {
                    handleSubmit((data) =>
                      onSubmit(data, 'save-is_available_for_sale'),
                    )();
                  }
                }}
              />
            )}
          />
          <Text fontSize={'xs'}>For Sale </Text>
        </Flex>
        <Flex justify="flex-start" gap={2} flex="1" align={'center'}>
          <Controller
            name="is_available_in_store"
            control={control}
            render={({ field }) => (
              <Switch
                size="sm"
                colorScheme="green"
                {...(field as unknown as Field)}
                isChecked={field.value}
                isDisabled={isUpdating || isInventoryLow}
                onChange={() => {
                  const newValue = !field.value;
                  setValue('is_available_in_store', newValue);
                  if (!isEditing) {
                    handleSubmit((data) =>
                      onSubmit(data, 'save-is_available_in_store'),
                    )();
                  }
                }}
              />
            )}
          />
          <Text fontSize={'xs'}>In Store</Text>
        </Flex>
        <Flex justify="flex-start" gap={2} flex="1" align={'center'}>
          <Controller
            name="is_sold_online"
            control={control}
            render={({ field }) => (
              <Switch
                size="sm"
                colorScheme="green"
                {...(field as unknown as Field)}
                isChecked={field.value}
                isDisabled={isUpdating || isInventoryLow} // Optionally disable based on inventory
                onChange={() => {
                  const newValue = !isSoldOnline;
                  setIsSoldOnline(newValue);
                  setValue('is_sold_online', newValue);
                  if (!isEditing) {
                    handleSubmit((data) =>
                      onSubmit(data, 'save-is_sold_online'),
                    )();
                  }
                }}
              />
            )}
          />
          <Text fontSize={'xs'}>Is Online</Text>
        </Flex>
      </Flex>
      <Flex justify={'space-between'} align={'center'}>
        <Flex justify="flex-start" gap={2} flex="1" align={'center'}>
          <Controller
            name="is_manually_controlled"
            control={control}
            render={({ field }) => (
              <Switch
                size="sm"
                colorScheme="green"
                {...(field as unknown as Field)}
                isChecked={field.value}
                isDisabled={isUpdating || isInventoryLow} // Disable based on inventory
                onChange={() => {
                  const newValue = !field.value;
                  setValue('is_manually_controlled', newValue);
                  if (!isEditing) {
                    handleSubmit((data) =>
                      onSubmit(data, 'save-is_manually_controlled'),
                    )();
                  }
                }}
              />
            )}
          />
          <Text fontSize={'xs'}>In Manual</Text>
        </Flex>
        <Flex justify="flex-start" gap={2} flex="1" align={'center'}>
          <Controller
            name="is_sold_by_range"
            control={control}
            render={({ field }) => (
              <Switch
                size="sm"
                colorScheme="green"
                {...(field as unknown as Field)}
                isChecked={field.value}
                isDisabled={isUpdating || isInventoryLow} // Optionally disable based on inventory
                onChange={() => {
                  const newValue = !field.value;
                  setValue('is_sold_by_range', newValue);
                  if (!isEditing) {
                    handleSubmit((data) =>
                      onSubmit(data, 'save-is_sold_by_range'),
                    )();
                  }
                }}
              />
            )}
          />
          <Text fontSize={'xs'}>By Range</Text>
        </Flex>
        <Flex justify="flex-start" gap={2} flex="1" align={'center'}>
          <Controller
            name="is_sold_by_weight"
            control={control}
            render={({ field }) => (
              <Switch
                size="sm"
                colorScheme="green"
                {...(field as unknown as Field)}
                isChecked={field.value}
                isDisabled={isUpdating || isInventoryLow} // Optionally disable based on inventory
                onChange={() => {
                  const newValue = !isWeighted;
                  setIsWeighted(newValue);
                  setValue('is_sold_by_weight', newValue);
                  if (!isEditing) {
                    handleSubmit((data) =>
                      onSubmit(data, 'save-is_sold_by_weight'),
                    )();
                  } // Trigger form submission on switch change
                }}
              />
            )}
          />
          <Text fontSize={'xs'}>By Weight</Text>
        </Flex>
      </Flex>

      {/* Divider */}
      <Divider my={4} />

      {/* Range Selling Price Modal */}
      <Modal
        isOpen={isRangeModalOpen}
        onClose={() => setIsRangeModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Selling Price Range</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <HStack width="100%">
              <Text fontWeight={'bold'}>Current Price: </Text>
              <Spacer />
              <Text>{item.price}</Text>
            </HStack>
            <HStack width="100%" mt={2}>
              <Text fontWeight={'bold'}>Cost: </Text>
              <Spacer />
              <Text>{item.cost}</Text>
            </HStack>

            <FormControl isInvalid={!!errorsRange.min_selling_price} mt={4}>
              <FormLabel>Minimum Selling Price</FormLabel>
              <Controller
                name="min_selling_price"
                control={controlRange}
                rules={{
                  required: 'Minimum selling price is required.',
                  min: {
                    value: item.cost,
                    message: `Minimum selling price must be at least the cost (${item.cost}).`,
                  },
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    placeholder="Minimum Selling Price"
                    min={item.cost}
                  />
                )}
              />
              <FormErrorMessage>
                {errorsRange.min_selling_price &&
                  errorsRange.min_selling_price.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!errorsRange.max_selling_price} mt={4}>
              <FormLabel>Maximum Selling Price</FormLabel>
              <Controller
                name="max_selling_price"
                control={controlRange}
                rules={{
                  required: 'Maximum selling price is required.',
                  validate: (value) =>
                    value >=
                      (watchRangeForm('min_selling_price') || item.cost) ||
                    'Maximum must be at least the minimum selling price.',
                }}
                render={({ field }) => (
                  <Input
                    {...field}
                    type="number"
                    placeholder="Maximum Selling Price"
                    min={watchRangeForm('min_selling_price') || item.cost}
                  />
                )}
              />
              <FormErrorMessage>
                {errorsRange.max_selling_price &&
                  errorsRange.max_selling_price.message}
              </FormErrorMessage>
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsRangeModalOpen(false)}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleSubmitRange(onRangeSubmit)}
              isLoading={updateSellingPriceRangeMutation.isLoading}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProductDetailsCardForm;
