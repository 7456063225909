// StoreSelectionModal.tsx
import React, { useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  RadioGroup,
  Stack,
  Radio,
} from '@chakra-ui/react';
import { Store } from '@bofrak-backend/shared';

interface StoreSelectionModalProps {
  availableStores: Store[];
  isOpen: boolean;
  onClose: () => void;
  onSelectStore: (storeId: string) => void;
}

export const StoreSelectionModal: React.FC<StoreSelectionModalProps> = ({
  availableStores,
  isOpen,
  onClose,
  onSelectStore,
}) => {
  const [selectedStoreId, setSelectedStoreId] = React.useState<string>('');

  useEffect(() => {
    if (availableStores.length > 0) {
      setSelectedStoreId(availableStores[0].id);
    }
  }, [availableStores]);

  const handleSelect = () => {
    if (selectedStoreId) {
      onSelectStore(selectedStoreId);
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      isCentered
      closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Your Store</ModalHeader>
        {/* Prevent closing the modal without selection */}
        <ModalCloseButton onClick={() => {}} />
        <ModalBody>
          <RadioGroup onChange={setSelectedStoreId} value={selectedStoreId}>
            <Stack direction="column">
              {availableStores.map((store) => (
                <Radio key={store.id} value={store.id}>
                  {store.name}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={handleSelect}
            isDisabled={!selectedStoreId}>
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
