import { Box, Center, Grid, Text, VStack } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  merchantAtom,
  selectedStoreAtom,
  storeProductsAtom,
} from '../../../recoil/atoms';
import SearchBarWithAddButton from '../../search-products';
import ProductCard from '../ProductCard';

import { colors, StoreProduct } from '@bofrak-backend/shared';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { apiAdapter } from '../../../api';
import { Loader } from '@bofrak-backend/shared-ui';

const ProductList = () => {
  const merchant = useRecoilValue(merchantAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [storeProductsData, setStoreProductsData] =
    useRecoilState(storeProductsAtom);
  const [filteredProducts, setFilteredProducts] = useState<StoreProduct[]>([]);

  // Fetch store products using React Query
  const { status } = useQuery(
    'store-products',
    () =>
      apiAdapter.getStoreProducts({
        merchant_id: merchant?.id as string,
        store_id: currentStore?.id as string,
      }),
    // () => apiAdapter.getStoreProducts({ merchant_id:merchant?.id as string, store_id:currentStore?.id as string, limit: 100}),
    {
      enabled: !!merchant && !!currentStore,
      keepPreviousData: true,
      onSuccess: (data) => {
        setStoreProductsData(data.products?.filter((i) => i.is_parent));
        // setStoreProducts(data || []);
        setFilteredProducts(data.products?.filter((i) => i.is_parent) || []);
      },
    },
  );

  // Set up Fuse.js search options
  const fuse = new Fuse(storeProductsData?.length ? storeProductsData : [], {
    keys: ['name', 'description'],
    threshold: 0.3,
  });

  // Function to handle search
  const handleSearch = (query: string) => {
    if (query.trim() === '') {
      setFilteredProducts(storeProductsData as StoreProduct[]);
    } else {
      const result = fuse.search(query).map(({ item }) => item);
      setFilteredProducts(result);
    }
  };

  useEffect(() => {
    setFilteredProducts(storeProductsData as StoreProduct[]);
  }, [storeProductsData]);

  const sortedProducts = [...(filteredProducts || [])]?.sort((a, b) => {
    if (a.created_at > b.created_at) {
      return -1;
    }
    if (a.created_at < b.created_at) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Center my={4} p={2} flexDirection={'column'}>
        <Text color={colors.blue} fontSize="lg" my={3} fontWeight="bold">
          {currentStore?.name}
        </Text>
        {/* Pass handleSearch to the search bar */}
        <SearchBarWithAddButton onSearch={handleSearch} />
      </Center>
      {status === 'loading' ? (
        <Loader />
      ) : sortedProducts.length ? (
        <Grid
          width="full"
          gap={2}
          templateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(4, 1fr)',
            lg: 'repeat(6, 1fr)',
            xl: 'repeat(6, 1fr)',
          }}
          justifyItems="start"
          p={4}>
          {sortedProducts.map((product) => (
            <Box key={product.id}>
              <ProductCard product={product} />
            </Box>
          ))}
        </Grid>
      ) : (
        <VStack minH={'100vh'}>
          <Text>No products found in this store</Text>
        </VStack>
      )}
    </>
  );
};

export default ProductList;
