// StoreSwitcher.tsx
import { Store } from '@bofrak-backend/shared';
import { FormControl, Select } from '@chakra-ui/react';
import React from 'react';

interface StoreSwitcherProps {
  availableStores: Store[];
  currentStoreId: string;
  onChangeStore: (storeId: string) => void;
}

export const StoreSwitcher: React.FC<StoreSwitcherProps> = ({
  availableStores,
  currentStoreId,
  onChangeStore,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedStoreId = event.target.value;
    onChangeStore(selectedStoreId);
  };

  return (
    <FormControl id="store-switcher" maxW="300px">
      <Select value={currentStoreId} onChange={handleChange}>
        {availableStores.map((store) => (
          <option key={store.id} value={store.id}>
            {store.name}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
