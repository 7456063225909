/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useState } from 'react';
import {
  Image as ChakraImage,
  Flex,
  IconButton,
  Input,
  Text,
  Box,
  useToast,
} from '@chakra-ui/react';
import { FaCamera, FaPencilAlt } from 'react-icons/fa';
import { apiAdapter } from '../../../api';
import { colors, EntityTypes, Image } from '@bofrak-backend/shared';

interface UploadImageProps {
  image_id?: string;
  merchant_id: string;
  entity_type: EntityTypes;
  entity_id: string;
  currentImage?: string; // Optional current image URL
  onImageUpload: (image: Image) => Promise<void>;
}

const UploadImage: React.FC<UploadImageProps> = ({
  image_id,
  merchant_id,
  entity_type,
  entity_id,
  currentImage,
  onImageUpload,
}) => {
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);

  // For displaying a temporary preview of the selected file
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  // The fully uploaded image URL (if any)
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(
    currentImage || null,
  );

  /**
   * Convert a File to a base64 string
   */
  const fileToBase64 = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () =>
        resolve(reader.result ? reader.result.toString() : '');
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  /**
   * Trigger file picker
   */
  const handleIconClick = () => {
    inputRef.current?.click();
  };

  /**
   * Upload the base64 image to the server
   */
  const doUpload = async (base64Image: string) => {
    // Check for required fields first
    if (!entity_id || !entity_type || !base64Image) {
      toast({
        title: 'Missing Required Data',
        description:
          'Please ensure entity ID, entity Type, and the selected image are set.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const imageId = image_id || `${Date.now()}`;

    try {
      const uploadedImage = await apiAdapter.uploadImage({
        image_id: imageId,
        base64Image,
        entity_type,
        entity_id,
        merchant_id,
      });

      // Update the uploaded image URL in state
      setUploadedImageUrl(uploadedImage.image_url);

      // Clear the local preview
      setPreviewImage(null);

      // Callback
      await onImageUpload(uploadedImage);
    } catch (error: any) {
      console.error('Failed to upload image:', error);
      toast({
        title: 'Upload Error',
        description: error?.message || 'Something went wrong while uploading.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  /**
   * Handle file selection
   */
  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files || event.target.files.length === 0) return;
    const file = event.target.files[0];

    // 1. Preview the selected image
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setPreviewImage(reader.result.toString());
      }
    };
    reader.readAsDataURL(file);

    // 2. Convert file to base64 and upload
    try {
      const base64 = await fileToBase64(file);
      await doUpload(base64);
    } catch (err) {
      console.error('File to Base64 conversion error:', err);
      toast({
        title: 'File Conversion Error',
        description: 'Could not convert the file to base64. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex
      justify="center"
      mb={4}
      border="1px solid"
      borderColor="gray.400"
      borderRadius="5px"
      padding={4}>
      <Flex justify="center" direction="column" align="center" mb={4}>
        {/* If there is no preview and no uploaded image, show prompt */}
        {previewImage || uploadedImageUrl ? null : (
          <Text color="gray.500" textAlign="center" fontSize="sm" width="150px">
            Upload Product Image
          </Text>
        )}

        {(previewImage || uploadedImageUrl) && (
          <Box position="relative" width="150px" height="150px">
            <ChakraImage
              src={previewImage || uploadedImageUrl || ''}
              alt="Preview or Uploaded Image"
              boxSize="150px"
              mb={4}
              objectFit="cover"
              borderRadius="md"
              onError={() => {
                // If the image fails to load, reset
                setPreviewImage(null);
                setUploadedImageUrl(null);
              }}
            />
            <IconButton
              aria-label="Edit image"
              icon={
                <FaPencilAlt
                  style={{
                    color: 'white', // Only the icon is green
                  }}
                />
              }
              position="absolute"
              top="5px"
              right="5px"
              size="sm"
              variant="ghost"
              onClick={handleIconClick}
              cursor="pointer"
              isRound
              bg={colors.black}
            />
          </Box>
        )}

        {/* If no image is shown yet, show camera icon */}
        {!previewImage && !uploadedImageUrl && (
          <IconButton
            aria-label="Upload image"
            icon={<FaCamera />}
            colorScheme="gray"
            size="lg"
            variant="outline"
            onClick={handleIconClick}
          />
        )}

        {/* Hidden file input */}
        <Input
          type="file"
          accept="image/*"
          borderRadius={0}
          _focus={{ boxShadow: 'none' }}
          ref={inputRef}
          display="none"
          onChange={handleFileChange}
        />
      </Flex>
    </Flex>
  );
};

export default UploadImage;
