import {
  colors,
  defaultImage,
  formatCurrency,
  InventoryChangeReason,
  StoreProduct,
  StoreProductFraction,
  StoreProductChangeEvents,
} from '@bofrak-backend/shared';
import {
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import { useState } from 'react';
import { CiBoxes } from 'react-icons/ci';
import { GiShoppingCart } from 'react-icons/gi';
import { MdModeEditOutline } from 'react-icons/md';
import { TbClockDollar } from 'react-icons/tb';
import BlinkingDot from '../resuable/BlinkingDot';
import ModalComponent from '../resuable/ModalComponent';
import EditProduct from './products/EditProduct';
import ProductInventoryHistory from './products/ProductInventoryHistory';
import ProductPurchaseHistory from './products/ProductPurchaseHistory';
import ProductHistory from './products/ProductHistory';

interface ProductCardProps {
  product: StoreProduct;
}

const ProductCard = ({ product }: ProductCardProps) => {
  const {
    isOpen: isEditProductOpen,
    onOpen: onOpenEditProduct,
    onClose: onCloseEditProduct,
  } = useDisclosure();

  const {
    isOpen: isInventoryHistoryOpen,
    onOpen: onOpenInventoryHistory,
    onClose: onCloseInventoryHistory,
  } = useDisclosure();

  const {
    isOpen: isPriceHistoryOpen,
    onOpen: onOpenPriceHistory,
    onClose: onClosePriceHistory,
  } = useDisclosure();

  const {
    isOpen: isPurchaseHistoryOpen,
    onOpen: onOpenPurchaseHistory,
    onClose: onClosePurchaseHistory,
  } = useDisclosure();

  const [selectedProduct, setSelectedProduct] = useState<StoreProduct | null>();

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="auto"
      p={2}
      bg="white"
      boxShadow="md"
      textAlign="center">
      <Flex justify={'space-between'} align={'center'}>
        <Flex justify="space-between" direction={'column'} align="center">
          <Flex
            justify="center"
            my={3}
            width={'80px'}
            wrap={'wrap'}
            height={'80px'}>
            <IconButton
              aria-label="edit"
              icon={<MdModeEditOutline />}
              bg={'transparent'}
              fontSize={'3xl'}
              color={colors.blue}
              onClick={() => {
                setSelectedProduct(product);
                onOpenEditProduct();
              }}
              size="sm"
              mx={1}
            />
            <IconButton
              aria-label="Add to Cart"
              icon={<GiShoppingCart />}
              bg={'transparent'}
              fontSize={'3xl'}
              color={colors.blue}
              size="sm"
              mx={1}
              onClick={() => {
                setSelectedProduct(product);
                onOpenPurchaseHistory();
              }}
            />
            <IconButton
              aria-label="Inventory History"
              icon={<CiBoxes />}
              bg={'transparent'}
              fontSize={'3xl'}
              color={colors.blue}
              size="sm"
              mx={1}
              onClick={() => {
                setSelectedProduct(product);
                onOpenInventoryHistory();
              }}
            />
            <IconButton
              aria-label="Price History"
              icon={<TbClockDollar />}
              bg={'transparent'}
              fontSize={'3xl'}
              color={colors.blue}
              size="sm"
              mx={1}
              onClick={() => {
                setSelectedProduct(product);
                onOpenPriceHistory();
              }}
            />
          </Flex>
        </Flex>

        <Image
          src={product.image || defaultImage}
          alt={product.name || 'Product Image'}
          onError={(e) => {
            e.currentTarget.src = defaultImage;
          }}
          objectFit="contain"
          ml="5px"
          width="80px"
        />
      </Flex>

      <Text
        my={1}
        width="full"
        fontSize="small"
        fontWeight="bold"
        color="black"
        textAlign={'center'}>
        {product?.name}
      </Text>

      <Divider m={0} />

      {/* Product Details */}
      <Flex
        justify={'space-between'}
        width={'full'}
        align={'center'}
        fontWeight="bold"
        overflowY={'auto'}>
        <Text fontSize="x-small" color="black" textAlign={'left'}>
          Stock
        </Text>
        <Text fontSize="x-small" color="black">
          {product?.inventory.toFixed(2)}
        </Text>
      </Flex>

      <Divider m={0.5} />

      <Flex justify={'space-between'} align={'center'}>
        <Stack direction="column" spacing={0} align={'start'}>
          <Flex justify={'center'} align="center" gap={2}>
            <BlinkingDot
              enableBlinking={!product.is_available_for_sale}
              isGreen={product.is_available_for_sale}
            />
            <Text fontSize={'xs'}>For Sale</Text>
          </Flex>
          <Flex justify={'center'} align="center" gap={2}>
            <BlinkingDot
              enableBlinking={!product.is_available_in_store}
              isGreen={product.is_available_in_store}
            />
            <Text fontSize={'xs'}>In Store</Text>
          </Flex>

          <Flex justify={'center'} align="center" gap={2}>
            <BlinkingDot
              enableBlinking={!product.is_sold_online}
              isGreen={product.is_sold_online}
            />
            <Text fontSize={'xs'}>Online</Text>
          </Flex>
        </Stack>
        <Stack direction="column" spacing={0} align={'start'}>
          <Flex justify={'center'} align="center" gap={2}>
            <BlinkingDot
              enableBlinking={!product.is_manually_controlled}
              isGreen={product.is_manually_controlled}
            />
            <Text fontSize={'xs'}>In Manual</Text>
          </Flex>
          <Flex justify={'center'} align="center" gap={2}>
            <BlinkingDot
              enableBlinking={!product.is_sold_by_range}
              isGreen={product.is_sold_by_range}
            />
            <Text fontSize={'xs'}>By Range</Text>
          </Flex>

          <Flex justify={'center'} align="center" gap={2}>
            <BlinkingDot
              enableBlinking={!product.is_sold_by_weight}
              isGreen={product.is_sold_by_weight}
            />
            <Text fontSize={'xs'}>By Weight</Text>
          </Flex>
        </Stack>
      </Flex>

      <Divider m={0.5} />

      <VStack spacing={1}>
        <Text fontWeight={'bold'} fontSize="small" color="black">
          {product?.unit}
        </Text>

        {product.is_sold_by_range ? (
          <HStack width="full" justify="space-between">
            <Text fontSize="x-small">
              <strong>Cost: </strong> &nbsp; {formatCurrency(product?.cost)}
            </Text>
            <VStack spacing={0}>
              <Text width={'full'} textAlign={'left'} fontSize="xx-small">
                <strong>Min Price:</strong> &nbsp;
                {formatCurrency(product?.min_selling_price)}
              </Text>
              <Text width={'full'} textAlign={'left'} fontSize="xx-small">
                <strong>Max Price:</strong> &nbsp;{' '}
                {formatCurrency(product?.max_selling_price)}
              </Text>
            </VStack>
          </HStack>
        ) : (
          <HStack width="full" justify="space-between">
            <Text fontSize="x-small">
              <strong>Cost: </strong> &nbsp; {formatCurrency(product?.cost)}
            </Text>
            <Text fontSize="x-small">
              <strong>Price: </strong> &nbsp; {formatCurrency(product?.price)}
            </Text>
          </HStack>
        )}
      </VStack>

      <Divider mt={0.5} mb={1} />

      <VStack mt={1} spacing={0} overflowY={'auto'}>
        {product?.is_parent &&
          product?.store_product_fractions?.map(
            (fraction: StoreProductFraction) => (
              // Sub Product Prices
              <VStack width={'full'} spacing={0} key={fraction.fraction_id}>
                <Text fontWeight={'bold'} fontSize="small" color="black">
                  {fraction?.unit}
                </Text>

                {fraction.is_sold_by_range ? (
                  <HStack width="full" justify="space-between">
                    <Text fontSize="x-small">
                      <strong>Cost: </strong> &nbsp;{' '}
                      {formatCurrency(fraction?.cost)}
                    </Text>
                    <VStack spacing={0}>
                      <Text
                        width={'full'}
                        textAlign={'left'}
                        fontSize="xx-small">
                        <strong>Min Price:</strong> &nbsp;
                        {formatCurrency(fraction?.min_selling_price)}
                      </Text>
                      <Text
                        width={'full'}
                        textAlign={'left'}
                        fontSize="xx-small">
                        <strong>Max Price:</strong> &nbsp;{' '}
                        {formatCurrency(fraction?.max_selling_price)}
                      </Text>
                    </VStack>
                  </HStack>
                ) : (
                  <HStack width="full" justify="space-between">
                    <Text fontSize="x-small">
                      <strong>Cost: </strong> &nbsp;{' '}
                      {formatCurrency(fraction?.cost)}
                    </Text>
                    <Text fontSize="x-small">
                      <strong>Price: </strong> &nbsp;{' '}
                      {formatCurrency(fraction?.price)}
                    </Text>
                  </HStack>
                )}
                <Divider my={0.5} />
              </VStack>
            ),
          )}
        <Text
          width={'full'}
          textAlign={'right'}
          fontSize="xx-small"
          color={'green'}>
          Updated {moment(product.created_at).format('ddd, DD MMM YY @ hh:mm')}
        </Text>
      </VStack>

      <ModalComponent
        isOpen={isEditProductOpen}
        onClose={onCloseEditProduct}
        size={'lg'}>
        <EditProduct selectedProduct={selectedProduct as StoreProduct} />
      </ModalComponent>
      <Modal
        isOpen={isInventoryHistoryOpen}
        onClose={onCloseInventoryHistory}
        size={'xl'}>
        <ModalOverlay />
        <ModalContent maxW={'100vw'}>
          <ModalHeader>
            <HStack>
              <Image
                src={selectedProduct?.image || defaultImage}
                alt={'Empty'}
                width={'75px'}
                height={'75px'}
                onError={(e) => {
                  e.currentTarget.src = defaultImage;
                }}
              />
              <Spacer />
              <Text fontSize={'lg'} fontWeight={'bold'}>
                Inventory History of{' '}
                <Text as="span" color={'blue'}>
                  {selectedProduct?.name}
                </Text>
              </Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProductInventoryHistory
              product={selectedProduct as StoreProduct}
              filters={
                Object.values(InventoryChangeReason) as InventoryChangeReason[]
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isPurchaseHistoryOpen}
        onClose={onClosePurchaseHistory}
        size={'xl'}>
        <ModalOverlay />
        <ModalContent maxW={'100vw'}>
          <ModalHeader>
            <HStack>
              <Image
                src={selectedProduct?.image || defaultImage}
                alt={'Empty'}
                width={'75px'}
                height={'75px'}
                onError={(e) => {
                  e.currentTarget.src = defaultImage;
                }}
              />
              <Spacer />
              <Text fontSize={'lg'} fontWeight={'bold'}>
                Purchase History of{' '}
                <Text as="span" color={'blue'}>
                  {selectedProduct?.name}
                </Text>
              </Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProductPurchaseHistory
              product={selectedProduct as StoreProduct}
              filters={[InventoryChangeReason.PURCHASE_ORDER]}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isPriceHistoryOpen}
        onClose={onClosePriceHistory}
        size={'xl'}>
        <ModalOverlay />
        <ModalContent maxW={'100vw'}>
          <ModalHeader>
            <HStack>
              <Image
                src={selectedProduct?.image || defaultImage}
                alt={'Empty'}
                width={'75px'}
                height={'75px'}
                onError={(e) => {
                  e.currentTarget.src = defaultImage;
                }}
              />
              <Spacer />
              <Text fontSize={'lg'} fontWeight={'bold'}>
                Price History of{' '}
                <Text as="span" color={'blue'}>
                  {selectedProduct?.name}
                </Text>
              </Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProductHistory
              product={selectedProduct as StoreProduct}
              filters={[
                StoreProductChangeEvents.STORE_PRODUCT_PRICE_UPDATED,
                StoreProductChangeEvents.STORE_PRODUCT_COST_UPDATED,
                StoreProductChangeEvents.STORE_PRODUCT_MIN_SELLING_PRICE_UPDATED,
                StoreProductChangeEvents.STORE_PRODUCT_MAX_SELLING_PRICE_UPDATED,
              ]}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProductCard;
