// CustomDatePicker.tsx
import { colors } from '@bofrak-backend/shared';
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import moment from 'moment';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

// Import the React DatePicker styles (base styles)
import 'react-datepicker/dist/react-datepicker.css';

// Define the component's props interface
interface CustomDatePickerProps {
  isRange?: boolean;
  onDateChosen?: (startDate: Date, endDate?: Date) => void;
  initialDate?: Date;
  initialEndDate?: Date;
  fontSize?: number;
  onSpecificDateChosen?: (date: Date) => void;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  isRange = false,
  onDateChosen,
  onSpecificDateChosen,
  initialDate,
  initialEndDate,
  fontSize,
}) => {
  // Set default date range from one week before to now
  const [startDate, setStartDate] = useState<Date | null>(
    initialDate || new Date(new Date().setDate(new Date().getDate() - 7)),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    initialEndDate || new Date(),
  );
  const [date, setDate] = useState<Date | null>(initialDate || new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isStartDateSet, setIsStartDateSet] = useState(false);

  // Quick selection options (same as before)
  const quickOptions = isRange
    ? [
        {
          label: 'Last 15 Min',
          value: [
            new Date(new Date().setMinutes(new Date().getMinutes() - 15)),
            new Date(),
          ],
        },
        {
          label: 'Last 30 Min',
          value: [
            new Date(new Date().setMinutes(new Date().getMinutes() - 30)),
            new Date(),
          ],
        },
        {
          label: 'Last Hour',
          value: [
            new Date(new Date().setHours(new Date().getHours() - 1)),
            new Date(),
          ],
        },
        {
          label: 'Last 6 Hours',
          value: [
            new Date(new Date().setHours(new Date().getHours() - 6)),
            new Date(),
          ],
        },
        {
          label: 'Last 12 Hours',
          value: [
            new Date(new Date().setHours(new Date().getHours() - 12)),
            new Date(),
          ],
        },
        { label: 'Today', value: [startOfDay(new Date()), new Date()] },
        {
          label: 'This Week',
          value: [startOfWeek(new Date()), endOfWeek(new Date())],
        },
        {
          label: 'This Month',
          value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          label: 'This Year',
          value: [startOfYear(new Date()), endOfYear(new Date())],
        },
      ]
    : [
        { label: 'Today', value: new Date() },
        {
          label: 'Yesterday',
          value: new Date(new Date().setDate(new Date().getDate() - 1)),
        },
        {
          label: '2 Days Ago',
          value: new Date(new Date().setDate(new Date().getDate() - 2)),
        },
        {
          label: '3 Days Ago',
          value: new Date(new Date().setDate(new Date().getDate() - 3)),
        },
        // 7 days ago
        {
          label: 'Last Week',
          value: new Date(new Date().setDate(new Date().getDate() - 7)),
        },
        // 2 weeks ago
        {
          label: '2 Weeks Ago',
          value: new Date(new Date().setDate(new Date().getDate() - 14)),
        },
        // 1 Month ago
        {
          label: 'Last Month',
          value: new Date(new Date().setMonth(new Date().getMonth() - 1)),
        },
      ];

  // Handle date change
  const handleDateChange = (
    update: [Date | null, Date | null] | Date | null,
  ) => {
    if (isRange) {
      if (Array.isArray(update)) {
        const [start, end] = update;
        setStartDate(start);
        setEndDate(end);
        setIsStartDateSet(true);
      } else if (update instanceof Date) {
        if (startDate && !endDate) {
          // Update startDate's time
          setStartDate(update);
          setIsStartDateSet(true);
        } else if (startDate && endDate) {
          if (isStartDateSet) {
            // Update endDate's time
            setEndDate(update);
            setIsStartDateSet(false);
          } else {
            // Update startDate's time
            setStartDate(update);
            setIsStartDateSet(true);
          }
        }
      }
    } else {
      if (update instanceof Date) {
        setStartDate(update);
        onSpecificDateChosen && onSpecificDateChosen(update);
        onDateChosen && onDateChosen(update);
        setDate(update);
      }
    }
  };

  // Format display text
  const displayValue = isRange
    ? startDate && endDate
      ? `${moment(startDate).format('DD/MM/YYYY hh:mm')} - ${moment(endDate).format('DD/MM/YYYY hh:mm')}`
      : 'Select date range'
    : date
      ? moment(date).format('DD/MM/YYYY hh:mm')
      : 'Select date';

  return (
    <Box width={'full'}>
      {/* Inject global styles for react-datepicker */}
      <Global
        styles={css`
          .react-datepicker {
            border: none;
            font-family: 'Inter', sans-serif;
          }

          .react-datepicker__header {
            background-color: white;
            border-bottom: none;
          }

          .react-datepicker__current-month,
          .react-datepicker-time__header {
            font-size: 1rem;
            font-weight: bold;
          }

          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            width: 2.5rem;
            line-height: 2.5rem;
          }

          .react-datepicker__day--selected,
          .react-datepicker__day--in-selecting-range,
          .react-datepicker__day--in-range {
            background-color: #3182ce;
            color: white;
          }

          .react-datepicker__day--keyboard-selected {
            background-color: #63b3ed;
          }

          .react-datepicker__time-container
            .react-datepicker__time
            .react-datepicker__time-box
            ul.react-datepicker__time-list
            li.react-datepicker__time-list-item--selected {
            background-color: #3182ce;
            color: white;
          }

          /* Adjust the inline display */
          .react-datepicker-popper {
            z-index: 1500;
          }
        `}
      />
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Input
            width={isRange ? '230px' : '130px'}
            value={displayValue}
            onClick={onOpen}
            readOnly
            cursor="pointer"
            fontWeight={'bold'}
            fontSize={
              isRange
                ? fontSize
                  ? `${fontSize}px`
                  : 'xx-small'
                : fontSize
                  ? `${fontSize}px`
                  : 'md'
            }
          />
        </PopoverTrigger>
        <PopoverContent width="auto">
          <VStack p={4} spacing={4}>
            <HStack
              width="full"
              maxW="100vw"
              overflowX="auto"
              spacing={2} // Optional: Adds space between buttons
            >
              {quickOptions.map((option) => (
                <Button
                  size="sm"
                  variant="outline"
                  key={option.label}
                  onClick={() => {
                    handleDateChange(option.value as any);
                    if (isRange) {
                      const [start, end] = option.value as [Date, Date];
                      onDateChosen && onDateChosen(start, end);
                    } else {
                      const date = option.value as Date;
                      onDateChosen && onDateChosen(date);
                      onSpecificDateChosen && onSpecificDateChosen(date);
                      setDate(date);
                    }
                    onClose();
                  }}
                  flexShrink={0} // Prevents the button from shrinking
                >
                  {option.label}
                </Button>
              ))}
            </HStack>

            {/* Date Picker */}
            <DatePicker
              selected={!isRange ? date : startDate || endDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={isRange}
              showTimeSelect
              timeIntervals={5}
              inline
              allowSameDay
            />

            {/* Apply and Restart Buttons */}
            <ButtonGroup mt={2} size="sm">
              {isRange && (
                <Button
                  borderRadius={'full'}
                  bg={colors.red}
                  color={'white'}
                  onClick={() => {
                    // Reset startDate and endDate to default values
                    setStartDate(null);
                    setEndDate(null);
                    setIsStartDateSet(false);
                  }}>
                  Restart
                </Button>
              )}
              <Button
                borderRadius={'full'}
                bg={colors.green}
                color={'white'}
                onClick={() => {
                  if (isRange) {
                    if (startDate && endDate) {
                      onDateChosen && onDateChosen(startDate, endDate);
                      onClose();
                    } else {
                      // Optionally, show a validation message
                    }
                  } else {
                    if (startDate) {
                      onDateChosen && onDateChosen(startDate);
                      onClose();
                    }
                  }
                }}
                isDisabled={isRange ? !(startDate && endDate) : !startDate}>
                Apply
              </Button>
            </ButtonGroup>
          </VStack>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

// Helper functions for date calculations
function startOfDay(date: Date): Date {
  const result = new Date(date);
  result.setHours(0, 0, 0, 0);
  return result;
}

function startOfWeek(date: Date): Date {
  const result = new Date(date);
  const day = result.getDay();
  const diff = result.getDate() - day + (day === 0 ? -6 : 1);
  result.setDate(diff);
  result.setHours(0, 0, 0, 0);
  return result;
}

function endOfWeek(date: Date): Date {
  const result = startOfWeek(date);
  result.setDate(result.getDate() + 6);
  result.setHours(23, 59, 59, 999);
  return result;
}

function startOfMonth(date: Date): Date {
  const result = new Date(date.getFullYear(), date.getMonth(), 1);
  result.setHours(0, 0, 0, 0);
  return result;
}

function endOfMonth(date: Date): Date {
  const result = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  result.setHours(23, 59, 59, 999);
  return result;
}

function startOfYear(date: Date): Date {
  const result = new Date(date.getFullYear(), 0, 1);
  result.setHours(0, 0, 0, 0);
  return result;
}

function endOfYear(date: Date): Date {
  const result = new Date(date.getFullYear(), 11, 31);
  result.setHours(23, 59, 59, 999);
  return result;
}
