import { LoyaltyProgram } from './customers.types';
import { ChangedAttributes, EntityTypes, Timestamps } from './shared.types';
import { Tax } from './taxes.types';

export interface ProductFraction extends Timestamps {
  parent_id: string;
  fraction_id: string;
  fraction: number;
  fraction_name: string;
  parent_name: string;
  merchant_id: string;
}

export type StoreProductFraction = StoreProduct & ProductFraction;

export interface Product extends Timestamps {
  id: string;
  name: string;
  description: string;
  image: string;
  barcode: number;
  quantity_in_unit_package: number;
  global_sku: number;
  weight_kg: number;
  unit: string;
  category_id?: string;
  merchant_id: string;
  fraction: number;
  parent_id: string;
  is_parent: boolean;
  product_fractions: ProductFraction[];
  taxes: string[] | Tax[];
  version: number;
}

export interface StoreProductAttributes extends Timestamps {
  id: string;
  store_sku: string;
  store_id: string;
  inventory: number;
  is_available_for_sale: boolean;
  is_sold_by_range: boolean;
  is_manually_controlled: boolean;
  should_display_cost_on_pos: boolean;
  is_sold_by_weight: boolean;
  is_sold_online: boolean;
  is_available_in_store: boolean;
  loyalty_programs: string[] | LoyaltyProgram[];
  price: number;
  cost: number;
  low_stock_threshold: number;
  store_prefix: string;
  is_parent: boolean;
  merchant_id: string;
  version: number;
  store_product_fractions: StoreProductFraction[];
  max_selling_price: number;
  min_selling_price: number;
}

export type StoreProduct = Product & StoreProductAttributes;

export interface GetProducts {
  merchant_id: string;
  is_parent?: boolean;
  cursor?: string;
  limit?: number;
}

export interface CreateProduct {
  creator_id: string;
  name: string;
  description: string;
  image: string;
  barcode: number;
  quantity_in_unit_package: number;
  weight_kg: number;
  unit: string;
  category_id?: string;
  merchant_id: string;
  taxes: string[];
}

export interface UpdateProduct {
  id: string;
  merchant_id: string;
  updator_id: string;
  name?: string;
  description?: string;
  image?: string;
  barcode?: number;
  quantity_in_unit_package?: number;
  global_sku?: number;
  weight_kg?: number;
  unit?: string;
  category_id?: string;
  add_taxes?: string[];
  remove_taxes?: string[];
}

export interface GetStoreProducts {
  store_id: string;
  is_parent?: boolean;
  cursor?: string;
  limit?: number;
}

export interface CreateStoreProduct {
  creator_id: string;
  product_id: string;
  store_id: string;
  inventory: number;
  is_available_for_sale: boolean;
  is_sold_by_range: boolean;
  max_selling_price: number;
  min_selling_price: number;
  is_manually_controlled: boolean;
  is_sold_by_weight: boolean;
  is_sold_online: boolean;
  loyalty_programs: string[];
  price: number;
  cost: number;
  low_stock_threshold: number;
  merchant_id: string;
}

export enum StoreProductChangeEvents {
  STORE_PRODUCT_SOLD_ONLINE_UPDATED = 'STORE_PRODUCT_SOLD_ONLINE_UPDATED',
  STORE_PRODUCT_DISPLAY_COST_ON_POS_UPDATED = 'STORE_PRODUCT_DISPLAY_COST_ON_POS_UPDATED',
  STORE_PRODUCT_CREATED = 'STORE_PRODUCT_CREATED',
  STORE_SKU_UPDATED = 'STORE_SKU_UPDATED',
  STORE_PRODUCT_IS_AVAILABLE_IN_STORE_UPDATED = 'STORE_PRODUCT_IS_AVAILABLE_IN_STORE_UPDATED',
  STORE_PRODUCT_SOLD_BY_WEIGHT_UPDATED = 'STORE_PRODUCT_SOLD_BY_WEIGHT_UPDATED',
  STORE_PRODUCT_INVENTORY_UPDATED = 'STORE_PRODUCT_INVENTORY_UPDATED',
  STORE_PRODUCT_PRICE_UPDATED = 'STORE_PRODUCT_PRICE_UPDATED',
  STORE_PRODUCT_COST_UPDATED = 'STORE_PRODUCT_COST_UPDATED',
  STORE_PRODUCT_LOW_STOCK_THRESHOLD_UPDATED = 'STORE_PRODUCT_LOW_STOCK_THRESHOLD_UPDATED',
  STORE_PRODUCT_IS_AVAILABLE_FOR_SALE_UPDATED = 'STORE_PRODUCT_IS_AVAILABLE_FOR_SALE_UPDATED',
  STORE_PRODUCT_IS_SOLD_BY_RANGE_UPDATED = 'STORE_PRODUCT_IS_SOLD_BY_RANGE_UPDATED',
  STORE_PRODUCT_IS_SOLD_BY_WEIGHT_UPDATED = 'STORE_PRODUCT_IS_SOLD_BY_WEIGHT_UPDATED',
  STORE_PRODUCT_IS_SOLD_ONLINE_UPDATED = 'STORE_PRODUCT_IS_SOLD_ONLINE_UPDATED',
  STORE_PRODUCT_MANUAL_CONTROL_UPDATED = 'STORE_PRODUCT_MANUAL_CONTROL_UPDATED',
  STORE_PRODUCT_MAX_SELLING_PRICE_UPDATED = 'STORE_PRODUCT_MAX_SELLING_PRICE_UPDATED',
  STORE_PRODUCT_MIN_SELLING_PRICE_UPDATED = 'STORE_PRODUCT_MIN_SELLING_PRICE_UPDATED',
  STORE_PRODUCT_LOYALTY_PROGRAMS_UPDATED = 'STORE_PRODUCT_LOYALTY_PROGRAMS_UPDATED',
  STORE_PRODUCT_DELETED = 'STORE_PRODUCT_DELETED',
  STORE_PREFIX_UPDATED = 'STORE_PREFIX_UPDATED',
  STORE_PRODUCT_FRACTIONS_UPDATED = 'STORE_PRODUCT_FRACTIONS_UPDATED',
}

export interface UpdateStoreProduct {
  id: string;
  store_id: string;
  merchant_id: string;
  updator_id: string;
  inventory?: number;
  is_available_for_sale?: boolean;
  is_sold_by_range?: boolean;
  max_selling_price?: number;
  min_selling_price?: number;
  is_manually_controlled?: boolean;
  is_sold_by_weight?: boolean;
  is_sold_online?: boolean;
  add_loyalty_programs?: string[];
  remove_loyalty_programs?: string[];
  price?: number;
  cost?: number;
  low_stock_threshold?: number;
  is_available_in_store?: boolean;
  should_display_cost_on_pos?: boolean;
}

export interface UpdateStoreProductInventory {
  id: string;
  store_id: string;
  merchant_id: string;
  source_id: string;
  source_type: EntityTypes;
  inventory: number;
}

export interface StoreProductPage {
  products: StoreProduct[];
  cursor?: string;
  count: number;
}

export interface ProductPage {
  products: Product[];
  cursor?: string;
  count: number;
}

export interface ProductFractionPage {
  fractions: ProductFraction[];
  cursor?: string;
  count: number;
}

export type StoreProductDBItem = StoreProductAttributes & {
  PK: string; // PK = `PRODUCT#${product_id}`
  SK: string; // SK = `STORE#${store_id}`
  GSI1PK: string; // GSI1PK = `STORE#${store_id}`
  GSI1SK: string; // GSI1SK = `PRODUCT#${product_id}`
  GSI2PK: string; // GSI2PK = `IS_PARENT#${is_parent}`
  GSI2SK: string; // GSI2SK = `STORE#${id}#PRODUCT#${id}`
  EntityIndexPK: string; // EntityIndexPK = `STORE_PRODUCT`
  EntityIndexSK: string; // EntityIndexSK = `PRODUCT#${product_id}#STORE#${store_id}`
};

export interface ProductDBItem extends Product {
  PK: string; // PK = `PRODUCT#${id}`
  SK: string; // SK = `PRODUCT#${id}`
  GSI1PK: string; // GSI1PK = `MERCHANT#${id}`
  GSI1SK: string; // GSI1SK = `PRODUCT#${id}`
  GSI2PK: string; // GSI2PK = `IS_PARENT#${is_parent}`
  GSI2SK: string; // GSI2SK = `MERCHANT#${id}#PRODUCT#${id}`
  EntityIndexPK: string; // EntityIndexPK = `PRODUCT`
  EntityIndexSK: string; // EntityIndexSK = `MERCHANT#${merchant_id}#DATE#${createdAt}`
}

export interface ProductFractionDBItem extends ProductFraction {
  PK: string; // PK = `PRODUCT#${product_id}`
  SK: string; // SK = `PRODUCT_FRACTION#${id}`
  GSI1PK: string; // GSI1PK = `MERCHANT#${id}`
  GSI1SK: string; // GSI1SK = `PRODUCT_FRACTION#${id}`
  GSI2PK: string; // GSI2PK = `PRODUCT_FRACTION#${id}`
  GSI2SK: string; // GSI2SK = `PRODUCT#${product_id}`
  EntityIndexPK: string; // EntityIndexPK = `PRODUCT_FRACTION`
  EntityIndexSK: string; // EntityIndexSK = `PRODUCT#${product_id}#PRODUCT_FRACTION#${id}`
}

export interface GetProductFractions {
  parent_id?: string;
  fraction_id?: string;
  cursor?: string;
  limit?: number;
}

export interface CreateProductFraction {
  fraction_id: string;
  parent_id: string;
  merchant_id: string;
  creator_id: string;
}

export interface UpdateProductFraction {
  id: string;
  parent_id?: string;
  fraction_name?: string;
  parent_name?: string;
  merchant_id: string;
  updator_id: string;
}

export interface SKU extends Timestamps {
  global_sku: number;
  merchant_id: string;
  unique_by_merchant: string;
  product_id: string;
}

export interface SKUDBItem extends SKU {
  // PK = MERCHANT#{merchant_id}
  // SK = GLOBAL_SKU#{sku}
  // GSI1PK = GLOBAL_SKU#{sku}
  // GSI1SK = MERCHANT#{merchant_id}
  // GSI2PK = GLOBAL_SKU#{sku}
  // GSI2SK = PRODUCT#{product_id}
  // GSI3PK = unique_by_merchant
  // GSI3SK = GLOBAL_SKU#{sku}
  // EntityIndexPK = GLOBAL_SKU
  // EntityIndexSK = MERCHANT#{merchant_id}#PRODUCT#{product_id}#GLCOBAL_SKU#{sku}

  PK: string;
  SK: string;
  GSI1PK: string;
  GSI1SK: string;
  GSI2PK: string;
  GSI2SK: string;
  GSI3PK: string;
  GSI3SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
}

export interface ChangeEvent {
  changes: ChangedAttributes;
  event: ProductChangeEvents | StoreProductChangeEvents;
}

export enum ProductChangeEvents {
  PRODUCT_CONVERTED_TO_PARENT = 'PRODUCT_CONVERTED_TO_PARENT',
  PRODUCT_CONVERTED_TO_CHILD = 'PRODUCT_CONVERTED_TO_CHILD',
  PRODUCT_CREATED = 'PRODUCT_CREATED',
  PRODUCT_NAME_UPDATED = 'PRODUCT_NAME_UPDATED',
  PRODUCT_DESCRIPTION_UPDATED = 'PRODUCT_DESCRIPTION_UPDATED',
  PRODUCT_IMAGE_UPDATED = 'PRODUCT_IMAGE_UPDATED',
  PRODUCT_BARCODE_UPDATED = 'PRODUCT_BARCODE_UPDATED',
  PRODUCT_QUANTITY_IN_UNIT_PACKAGE_UPDATED = 'PRODUCT_QUANTITY_IN_UNIT_PACKAGE_UPDATED',
  PRODUCT_GLOBAL_SKU_UPDATED = 'PRODUCT_GLOBAL_SKU_UPDATED',
  PRODUCT_WEIGHT_KG_UPDATED = 'PRODUCT_WEIGHT_KG_UPDATED',
  PRODUCT_UNIT_UPDATED = 'PRODUCT_UNIT_UPDATED',
  PRODUCT_CATEGORY_UPDATED = 'PRODUCT_CATEGORY_UPDATED',
  PRODUCT_FRACTION_UPDATED = 'PRODUCT_FRACTION_UPDATED',
  PRODUCT_PARENT_UPDATED = 'PRODUCT_PARENT_UPDATED',
  PRODUCT_TAXES_UPDATED = 'PRODUCT_TAXES_UPDATED',
  PRODUCT_DELETED = 'PRODUCT_DELETED',
  PRODUCT_FRACTIONS_UPDATED = 'PRODUCT_FRACTIONS_UPDATED',
  PRODUCT_DELETED_AT_UPDATED = 'PRODUCT_DELETED_AT_UPDATED',
  PRODUCT_CATEGORY_ID_UPDATED = 'PRODUCT_CATEGORY_ID_UPDATED',
}

export interface ProductChange extends Timestamps {
  event: ProductChangeEvents;
  version: string;
  updator_id: string;
  changed_attributes: ChangedAttributes;
  change_reason: string;
  id: string;
  merchant_id: string;
  updator_name: string;
}

export interface ProductChangeDBItem extends ProductChange {
  // PK = `PRODUCT#${id}`
  // SK = `PRODUCT_VERSION#${padded_version}`
  // GSI1PK = `MERCHANT#${merchant_id}`
  // GSI1SK = `PRODUCT_CHANGE#${id}#DATE#${createdAt}`
  // GSI2PK = `USER#${updator_id}`
  // GSI2SK = `PRODUCT_CHANGE#${id}#DATE#${createdAt}`
  // GSI3PK = `MERCHANT#${merchant_id}#EVENT#${event}`
  // GSI3SK = `PRODUCT_CHANGE#${id}#DATE#${createdAt}`
  // GSI4PK = `MERCHANT#${merchant_id}#EVENT#${event}`
  // GSI4SK = `PRODUCT_CHANGE#DATE#${createdAt}`
  // GSI5PK = `USER#${updator_id}`
  // GSI5SK = `PRODUCT_CHANGE#DATE#${createdAt}`
  // GSI6PK = `USER#${updator_id}#EVENT#${event}`
  // GSI6SK = `PRODUCT_CHANGE#${id}#DATE#${createdAt}`
  // EntityIndexPK = `PRODUCT_CHANGE`
  // EntityIndexSK = `MERCHANT#${merchant_id}#DATE#${createdAt}`
  PK: string;
  SK: string;
  GSI1PK: string;
  GSI1SK: string;
  GSI2PK: string;
  GSI2SK: string;
  GSI3PK: string;
  GSI3SK: string;
  GSI4PK: string;
  GSI4SK: string;
  GSI5PK: string;
  GSI5SK: string;
  GSI6PK: string;
  GSI6SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
}

export interface ProductChangesPage {
  changes: ProductChange[];
  cursor?: string;
  count: number;
}

export interface GetProductChanges {
  /**
   * Query all product changes made by a specific user.
   *
   * **How to Query:**
   * - **Index:** GSI2
   * - **GSI2PK:** `USER#${updator_id}`
   * - **GSI2SK:** starts with `PRODUCT_CHANGE#`
   */
  by_user?: boolean;

  /**
   * Query all product changes made by an user within a date range.
   *
   * **How to Query:**
   * - **Index:** GSI5
   * - **GSI5PK:** `USER#${updator_id}`
   * - **GSI5SK Range:** Between `PRODUCT_CHANGE#DATE#${start_date}` and `PRODUCT_CHANGE#DATE#${end_date}`
   */
  by_user_and_date_range?: boolean;
  /**
   * Query By user and product
   * - **Index:** GSI2
   * - **GSI6PK:** `USER#${updator_id}`
   * - **GSI6SK:** starts with `PRODUCT_CHANGE#${product_id}`
   */

  by_user_and_product?: boolean;

  /**
   * Query By user and product within a date range
   * - **Index:** GSI2
   * - **GSI6PK:** `USER#${updator_id}`
   * - **GSI6SK Range:** Between `PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
   * - **Note:** Requires `start_date` and `end_date`
   * - **Note:** Requires `updator_id`
   */
  by_user_product_and_date_range?: boolean;

  /**
   * Query By user and event type
   * - **Index:** GSI7
   * - **GSI7PK:** `USER#${updator_id}#EVENT#${event}`
   * - **GSI7SK:** starts with `PRODUCT_CHANGE#`
   */
  by_user_and_event?: boolean;

  /**
   * Query By user and event type and product within a date range
   * - **Index:** GSI7
   * - **GSI7PK:** `USER#${updator_id}#EVENT#${event}`
   * - **GSI7SK Range:** Between `PRODUCT_CHANGE#${id}#DATE#${start_date}` and `PRODUCT_CHANGE#DATE#${end_date}`
   * - **Note:** Requires `start_date` and `end_date`
   * - **Note:** Requires `updator_id`
   */
  by_user_and_product_and_event_and_date_range?: boolean;

  /**
   * Query all versions of a specific product.
   *
   * **How to Query:**
   * - **Table:** Main Table
   * - **Partition Key (PK):** `PRODUCT#${product_id}`
   * - **Sort Key (SK):** `PRODUCT_VERSION#${padded_version}`
   */
  by_product?: boolean;

  /**
   * Query all product changes for a specific merchant.
   *
   * **How to Query:**
   * - **Index:** GSI1
   * - **GSI1PK:** `MERCHANT#${merchant_id}`
   * - **GSI1SK:** starts with "PRODUCT_CHANGE#"
   */
  by_merchant?: boolean;

  /**
   * Query all changes for a specific product within a merchant.
   *
   * **How to Query:**
   * - **Index:** GSI1
   * - **GSI1PK:** `MERCHANT#${merchant_id}`
   * - **GSI1SK:** starts with 'PRODUCT_CHANGE#${product_id}'
   */
  by_merchant_and_product?: boolean;

  /**
   * Query all product changes for a merchant within a date range.
   *
   * **How to Query:**
   * - **Index:** EntityIndex
   * - **EntityIndexPK:** `PRODUCT_CHANGE`
   * - **EntityIndexSK Range:** Between `MERCHANT#${id}#DATE#${start_date}` and `MERCHANT#${id}#DATE#${end_date}`
   */
  by_merchant_and_date_range?: boolean;

  /**
   * Query all changes for a specific product under a merchant within a date range.
   *
   * **How to Query:**
   * - **Index:** GSI1
   * - **GSI1PK:** `MERCHANT#${merchant_id}`
   * - **GSI1SK Range:** Between `PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
   */
  by_merchant_product_and_date_range?: boolean;

  /**
   * Query all product changes of a specific event type for a merchant.
   *
   * **How to Query:**
   * - **Index:** GSI3
   * - **GSI3PK:** `MERCHANT#${merchant_id}#EVENT#${event}`
   * - **GSI3SK:** starts with `PRODUCT_CHANGE#`
   */
  by_merchant_and_event?: boolean;

  /**
   * Query all product changes of a specific event type for a merchant within a date range.
   *
   * **How to Query:**
   * - **Index:** GSI4
   * - **GSI4PK:** `MERCHANT#${merchant_id}#EVENT#${event}`
   * - **GSI4SK Range:** Between `PRODUCT_CHANGE#DATE#${start_date}` and `PRODUCT_CHANGE#DATE#${end_date}`
   */
  by_merchant_event_and_date_range?: boolean;

  /**
   * Query all changes by merchant and event type and product
   * - **Index:** GSI3
   * - **GSI3PK:** `MERCHANT#${merchant_id}#EVENT#${event}`
   * - **GSI3SK:** starts with `PRODUCT_CHANGE#${product_id}`
   */
  by_merchant_event_and_product?: boolean;

  /**
   * Query all changes by merchant and event type and product within a date range
   * - **Index:** GSI3
   * - **GSI3PK:** `MERCHANT#${merchant_id}#EVENT#${event}`
   * - **GSI3SK Range:** Between `PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
   */
  by_merchant_event_product_and_date_range?: boolean;

  /**
   * Query all product changes by product and version range.
   *
   * **How to Query:**
   * - **Table:** Main Table
   * - **PK:** `PRODUCT#${product_id}`
   * - **SK Range:** Between `PRODUCT_VERSION#${start_version}` and `PRODUCT_VERSION#${end_version}`
   * - **Note:** Requires that `padded_version`
   */
  by_product_and_version_range?: boolean;

  // Query parameters
  limit?: number;
  cursor?: string;

  /**
   * Start date for date range filtering.
   * - **Format:** ISO 8601 string (e.g., '2023-09-24T12:34:56Z')
   */
  start_date?: string;

  /**
   * End date for date range filtering.
   * - **Format:** ISO 8601 string
   */
  end_date?: string;

  /**
   * Merchant ID.
   * - Required for queries involving merchants.
   */
  merchant_id: string;

  /**
   * Product ID.
   * - Required for queries involving specific products.
   */
  product_id?: string;

  /**
   * Event type.
   * - Enum of possible product change events.
   */
  event?: ProductChangeEvents;

  /**
   * user (updator) ID.
   * - Required for queries involving specific users.
   */
  updator_id?: string;

  sort_order?: 'ASC' | 'DESC';

  /**
   * Product version.
   * - Required for queries involving specific product versions.
   */
  version?: number;

  /**
   * Start version for version range filtering.
   */
  start_version?: string;

  /**
   * End version for version range filtering.
   */
  end_version?: string;
}

export interface GetStoreProductChanges {
  /**
   * Query all store_product changes made by a specific user.
   *
   * **How to Query:**
   * - **Index:** GSI2
   * - **GSI2PK:** `USER#${updator_id}`
   * - **GSI2SK:** starts with `STORE_PRODUCT_CHANGE#`
   */
  by_user?: boolean;

  /**
   * Query all store_product changes made by an user within a date range.
   *
   * **How to Query:**
   * - **Index:** GSI5
   * - **GSI5PK:** `USER#${updator_id}`
   * - **GSI5SK Range:** Between `STORE_PRODUCT_CHANGE#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#DATE#${end_date}`
   */
  by_user_and_date_range?: boolean;
  /**
   * Query By user and store_product
   * - **Index:** GSI2
   * - **GSI6PK:** `USER#${updator_id}`
   * - **GSI6SK:** starts with `STORE_PRODUCT_CHANGE#${product_id}`
   */

  by_user_and_store_product?: boolean;

  /**
   * Query By user and store_product within a date range
   * - **Index:** GSI2
   * - **GSI6PK:** `USER#${updator_id}`
   * - **GSI6SK Range:** Between `STORE_PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
   * - **Note:** Requires `start_date` and `end_date`
   * - **Note:** Requires `updator_id`
   */
  by_user_store_product_and_date_range?: boolean;

  /**
   * Query By user and event type
   * - **Index:** GSI6
   * - **GSI6PK:** `USER#${updator_id}#EVENT#${event}`
   * - **GSI6SK:** starts with `STORE_PRODUCT_CHANGE#`
   */
  by_user_and_event?: boolean;

  /**
   * Query By user and event type and store_product
   * - **Index:** GSI6
   * - **GSI6PK:** `USER#${updator_id}#EVENT#${event}`
   * - **GSI6SK:** starts with `STORE_PRODUCT_CHANGE#${product_id}`
   */
  by_user_and_store_product_and_event?: boolean;

  /**
   * Query By user and event type and store_product within a date range
   * - **Index:** GSI6
   * - **GSI6PK:** `USER#${updator_id}#EVENT#${event}`
   * - **GSI6SK Range:** Between `STORE_PRODUCT_CHANGE#${id}#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#DATE#${end_date}`
   * - **Note:** Requires `start_date` and `end_date`
   * - **Note:** Requires `updator_id`
   */
  by_user_and_store_product_and_event_and_date_range?: boolean;

  /**
   * Query all versions of a specific store_product.
   *
   * **How to Query:**
   * - **Table:** Main Table
   * - **Partition Key (PK):** `PRODUCT#${product_id}#STORE#${store_id}`
   * - **Sort Key (SK):** starts with `STORE_PRODUCT_VERSION#`
   */
  by_store_product?: boolean;

  /**
   * Query all store_product changes for a specific store.
   *
   * **How to Query:**
   * - **Index:** GSI1
   * - **GSI1PK:** `STORE#${store_id}`
   * - **GSI1SK:** starts with "STORE_PRODUCT_CHANGE#"
   */
  by_store?: boolean;

  /**
   * Query all changes for a specific store_product within a store.
   *
   * **How to Query:**
   * - **Index:** GSI1
   * - **GSI1PK:** `STORE#${store_id}`
   * - **GSI1SK:** starts with 'STORE_PRODUCT_CHANGE#${product_id}'
   */
  by_store_and_store_product?: boolean;

  /**
   * Query all store_product changes for a store within a date range.
   *
   * **How to Query:**
   * - **Index:** EntityIndex
   * - **EntityIndexPK:** `STORE_PRODUCT_CHANGE`
   * - **EntityIndexSK Range:** Between `STORE#${id}#DATE#${start_date}` and `STORE#${id}#DATE#${end_date}`
   */
  by_store_and_date_range?: boolean;

  /**
   * Query all changes for a specific store_product under a store within a date range.
   *
   * **How to Query:**
   * - **Index:** GSI1
   * - **GSI1PK:** `STORE#${store_id}`
   * - **GSI1SK Range:** Between `STORE_PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
   */
  by_store_and_store_product_and_date_range?: boolean;

  /**
   * Query all store_product changes of a specific event type for a store.
   *
   * **How to Query:**
   * - **Index:** GSI3
   * - **GSI3PK:** `STORE#${store_id}#EVENT#${event}`
   * - **GSI3SK:** starts with `STORE_PRODUCT_CHANGE#`
   */
  by_store_and_event?: boolean;

  /**
   * Query all store_product changes of a specific event type for a store within a date range.
   *
   * **How to Query:**
   * - **Index:** GSI4
   * - **GSI4PK:** `STORE#${store_id}#EVENT#${event}`
   * - **GSI4SK Range:** Between `STORE_PRODUCT_CHANGE#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#DATE#${end_date}`
   */
  by_store_and_event_and_date_range?: boolean;

  /**
   * Query all changes by store and event type and store_product
   * - **Index:** GSI3
   * - **GSI3PK:** `STORE#${store_id}#EVENT#${event}`
   * - **GSI3SK:** starts with `STORE_PRODUCT_CHANGE#${product_id}`
   */
  by_store_event_and_store_product?: boolean;

  /**
   * Query all changes by store and event type and store_product within a date range
   * - **Index:** GSI3
   * - **GSI3PK:** `STORE#${store_id}#EVENT#${event}`
   * - **GSI3SK Range:** Between `STORE_PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
   */
  by_store_event_store_product_and_date_range?: boolean;

  /**
   * Query all store_product changes by store_product and version range.
   *
   * **How to Query:**
   * - **Table:** Main Table
   * - **PK:** `PRODUCT#${product_id}`
   * - **SK Range:** Between `PRODUCT_VERSION#${start_version}` and `PRODUCT_VERSION#${end_version}`
   * - **Note:** Requires that `padded_version`
   */
  by_store_product_and_version_range?: boolean;

  // Query parameters
  limit?: number;
  cursor?: string;

  /**
   * Start date for date range filtering.
   * - **Format:** ISO 8601 string (e.g., '2023-09-24T12:34:56Z')
   */
  start_date?: string;

  /**
   * End date for date range filtering.
   * - **Format:** ISO 8601 string
   */
  end_date?: string;

  /**
   * Merchant ID.
   * - Required for queries involving stores.
   */
  store_id?: string;

  /**
   * Product ID.
   * - Required for queries involving specific store_products.
   */
  product_id?: string;

  /**
   * Event type.
   * - Enum of possible store_product change events.
   */
  event?: ProductChangeEvents | StoreProductChangeEvents;

  /**
   * user (updator) ID.
   * - Required for queries involving specific users.
   */
  updator_id?: string;

  sort_order?: 'ASC' | 'DESC';

  /**
   * Product version.
   * - Required for queries involving specific store_product versions.
   */
  version?: number;

  /**
   * Start version for version range filtering.
   */
  start_version?: number;

  /**
   * End version for version range filtering.
   */
  end_version?: number;
}

export interface StoreProductChangesPage {
  changes: StoreProductChange[];
  cursor?: string;
  count: number;
}

export interface StoreProductChange extends Timestamps {
  event: StoreProductChangeEvents;
  version: number;
  updator_id: string;
  changed_attributes: ChangedAttributes;
  change_reason: string;
  id: string;
  store_id: string;
  merchant_id: string;
  updator_name: string;
}

export interface StoreProductChangeDBItem extends StoreProductChange {
  // PK = `PRODUCT#${product_id}#STORE#${store_id}`
  // SK = `STORE_PRODUCT_VERSION#${padded_version}`
  // GSI1PK = `STORE#${store_id}`
  // GSI1SK = `STORE_PRODUCT_CHANGE#${product_id}#DATE#${createdAt}`
  // GSI2PK = `USER#${updator_id}`
  // GSI2SK = `STORE_PRODUCT_CHANGE#${product_id}#DATE#${createdAt}`
  // GSI3PK = `STORE#${store_id}#EVENT#${event}`
  // GSI3SK = `STORE_PRODUCT_CHANGE#${product_id}#DATE#${createdAt}`
  // GSI4PK = `STORE#${store_id}#EVENT#${event}`
  // GSI4SK = `STORE_PRODUCT_CHANGE#DATE#${createdAt}`
  // GSI5PK = `USER#${updator_id}`
  // GSI5SK = `STORE_PRODUCT_CHANGE#DATE#${createdAt}`
  // GSI6PK = `USER#${updator_id}#EVENT#${event}`
  // GSI6SK = `STORE_PRODUCT_CHANGE#${product_id}#DATE#${createdAt}`
  // EntityIndexPK = `STORE_PRODUCT_CHANGE`
  // EntityIndexSK = `STORE#${store_id}#DATE#${createdAt}`

  PK: string;
  SK: string;
  GSI1PK: string;
  GSI1SK: string;
  GSI2PK: string;
  GSI2SK: string;
  GSI3PK: string;
  GSI3SK: string;
  GSI4PK: string;
  GSI4SK: string;
  GSI5PK: string;
  GSI5SK: string;
  GSI6PK: string;
  GSI6SK: string;
  EntityIndexPK: string;
  EntityIndexSK: string;
}
