// This is auto generated and will be overwritten. Do not make changes in this file

export enum AuthType {
    Admin = 'Admin',
    Customer = 'Customer',
    Merchant = 'Merchant'
}
export enum CognitoAttributeDataType {
    STRING = 'String',
    NUMBER = 'Number',
    DATETIME = 'DateTime',
    BOOLEAN = 'Boolean'
}
export interface CognitoAttributeDefinition {
    AttributeDataType?: CognitoAttributeDataType;
    Mutable: boolean;
    Name: string;
    Required?: boolean;
}
export interface MerchantAuthStore {
    store_id: string;
    store_name: string;
    merchant_id: string;
    roles: string[];
}
export interface AuthenticateUserResponse {
    encryptedCredentials: string;
    userData: MerchantUserAttributes;
    employee: Employee;
    credentials: EncryptCredentialsDto;
}
export interface AuthenticateUserRequest {
    phone_number: string;
    pin: string;
    client_id: string;
}
export interface DecryptCredentialsRequestBody {
    encryptedCredentials: string;
}
export interface SignOutUserRequestBody {
    accessToken: string;
}
export interface SignOutUserResponse {
    message: string;
}
export interface CreateMerchantUserRequestBody {
    phone_number: string;
    email?: string;
    address?: string;
    family_name?: string;
    given_name?: string;
    auth_type: AuthType;
    business_name: string;
    created_by: string;
    stores: MerchantAuthStore[];
    pin: string;
}
export interface CreateAdminUserRequestBody {
    phone_number: string;
    email: string;
    address?: string;
    family_name?: string;
    given_name?: string;
    auth_type: AuthType;
    password: string;
}
export interface UpdateAdminUserRequestBody {
    user_id: string;
    email: string;
    address?: string;
    family_name?: string;
    given_name?: string;
    stores?: MerchantAuthStore[];
}
export interface CreateAuthUserResponse {
    message: string;
    userSub?: string;
}
export interface CustomerAuthStore {
    store_id: string;
    store_name: string;
    merchant_id: string;
}
export interface CustomerUserAttributes {
    username: string;
    phone_number: string;
    email: string;
    address: string;
    family_name: string;
    given_name: string;
    gender: string;
    profile_picture: string;
    auth_type: AuthType;
    created_by: string;
    pin: string;
    stores: CustomerAuthStore[];
}
export interface MerchantUserAttributes {
    username: string;
    phone_number: string;
    email: string;
    address: string;
    family_name: string;
    given_name: string;
    auth_type: AuthType;
    business_name: string;
    created_by: string;
    pin: string;
    stores: MerchantAuthStore[];
}
export interface AdminUserAttributes {
    username: string;
    phone_number: string;
    email: string;
    address: string;
    family_name: string;
    given_name: string;
    auth_type: AuthType;
}
export interface UpdateMerchantUserRequestBody {
    user_id: string;
    email?: string;
    address?: string;
    family_name?: string;
    given_name?: string;
    business_name?: string;
    pin?: string;
    add_to_stores?: MerchantAuthStore[];
    remove_from_stores?: MerchantAuthStore[];
    is_email_verified?: boolean;
    add_to_roles?: string[];
    remove_from_roles?: string[];
}
export interface CreateCustomerUserRequestBody {
    phone_number: string;
    email?: string;
    address?: string;
    family_name?: string;
    given_name?: string;
    auth_type: AuthType;
    created_by: string;
    stores: MerchantAuthStore[];
    pin: string;
    gender?: string;
    profile_picture?: string;
}
export interface UpdateCustomerUserRequestBody {
    user_id: string;
    email?: string;
    address?: string;
    family_name?: string;
    given_name?: string;
    stores?: CustomerAuthStore[];
    pin?: string;
    gender?: string;
    profile_picture?: string;
}
export interface UpdateAuthUserResponse {
    message: string;
}
export type UserAttribute = {
    Name: string;
    Value: string;
};
export interface ResetUserPasswordRequestBody {
    user_id: string;
    new_pin?: string;
    auth_type: AuthType;
    new_password?: string;
}
export interface EncryptCredentialsDto {
    AccessToken: string;
    ExpiresIn: number;
    Employee: Employee;
    TokenType: string;
}

export interface Principal {
    id: string;
    created_at: string;
    deleted_at: string;
    updated_history: string[];
    merchant_id: string;
    role_id: string;
}
export interface PrincipalList {
    principals: Principal[];
    meta: Meta;
}
export interface Policy {
    id: string;
    policy: string;
    action_id: string;
    resource_id: string;
    description: string;
    created_at: string;
    deleted_at: string;
    updated_history: string[];
}
export interface Action {
    id: string;
    description: string;
    verb: string;
    resource_id: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    update_history: string[];
}
export interface Resource {
    id: string;
    description: string;
    path: string;
    created_at: string;
    updated_at: string;
    deleted_at: string;
    update_history: string;
}
export interface Meta {
    num_read: number;
    next_cursor: string;
}
export interface ActionList {
    actions: Action[];
    meta: Meta;
}
export interface ResourceList {
    resources: Resource[];
    meta: Meta;
}
export interface PolicyList {
    policies: Policy[];
    meta: Meta;
}
export interface Role {
    id: string;
    description: string;
    policies: Policy[];
    created_at: string;
    deleted_at: string;
    updated_history: string[];
    role_id: string;
}
export interface RoleList {
    roles: Role[];
    meta: Meta;
}

export interface Customer extends Timestamps {
    id: string; // The customer ID. If included in the POST request, it will cause an update instead of creating a new object.
    name: string; // <= 64 characters. The customer's name.
    email?: string; // <= 100 characters. The customer's email.
    phone_number?: string; // <= 15 characters. The customer's phone number.
    address?: string; // <= 192 characters. The customer's address.
    city?: string; // <= 64 characters. The customer's city, town, or village.
    region?: string; // <= 64 characters. The customer's region (province, state, or prefecture).
    postal_code?: string; // <= 20 characters. The customer's postal code.
    country_code?: string; // The two-letter country code in ISO 3166-1-alpha-2 format.
    customer_code?: string; // <= 40 characters.
    note?: string; // <= 255 characters. Note about the customer.
    first_visit?: string | null; // ISO 8601 date-time string. Default: null. The date of the first customer visit.
    last_visit?: string | null; // ISO 8601 date-time string. Default: null. The date of the most recent customer visit.
    total_visits: number; // Default: 0. The total number of visits.
    total_spent: number; // Default: 0. The total amount the customer has spent.
    total_points: number; // Default: 0. The customer's current points balance.
    permanent_deletion_at?: string | null; // ISO 8601 date-time string. The time when the customer data will be permanently deleted.
    pin?: string; // <= 4 characters. The customer's personal identification number.
    merchant_id: string; // The merchant ID.
    date_of_birth?: string;
}
export interface CreateCustomer {
    name: string; // <= 64 characters.
    email?: string; // <= 100 characters.
    phone_number?: string; // <= 15 characters.
    address?: string; // <= 192 characters.
    city?: string; // <= 64 characters.
    region?: string; // <= 64 characters.
    postal_code?: string; // <= 20 characters.
    country_code?: string; // The two-letter country code in ISO 3166-1-alpha-2 format.
    customer_code?: string; // <= 40 characters.
    note?: string; // <= 255 characters.
    first_visit?: string | null; // ISO 8601 date-time string. Default: null.
    last_visit?: string | null; // ISO 8601 date-time string. Default: null.
    pin: string; // <= 4 characters.
    merchant_id: string; // The merchant ID.
    id?: string; // The customer ID.
}
export interface UpdateCustomer {
    id: string; // The customer ID to update.
    name?: string; // <= 64 characters.
    email?: string; // <= 100 characters.
    phone_number?: string; // <= 15 characters.
    address?: string; // <= 192 characters.
    city?: string; // <= 64 characters.
    region?: string; // <= 64 characters.
    postal_code?: string; // <= 20 characters.
    country_code?: string; // The two-letter country code in ISO 3166-1-alpha-2 format.
    customer_code?: string; // <= 40 characters.
    note?: string; // <= 255 characters.
    first_visit?: string | null; // ISO 8601 date-time string.
    last_visit?: string | null; // ISO 8601 date-time string.
    pin?: string; // <= 4 characters.
}
export interface CustomerPage {
    cursor?: string;
    count?: number;
    customers: Customer[];
}
export interface GetCustomers {
    cursor?: string;
    limit?: number;
    merchant_id: string;
}
export interface CustomerDBItem extends Customer {
    PK: string; // CUSTOMER#id
    SK: string; // CUSTOMER#id
    GSI1PK: string; // MERCHANT#merchant_id
    GSI1SK: string; // CUSTOMER#id
    EntityIndexPK: string; // CUSTOMER
    EntityIndexSK: string; // DATE#created_at
}
export enum TransactionType {
    Sale = 'Sale',
    Refund = 'Refund'
}
export interface LoyaltyTransactionItem {
    product_id: string;
    product_name: string;
    price: number;
    quantity: number;
    eligible_for_points: boolean;
}
export interface LoyaltyTransaction {
    id: string;
    customer_id: string;
    type: TransactionType;
    items: LoyaltyTransactionItem[];
    total_amount: number;
    date: string;
    merchant_id: string;
}
export interface LoyaltyProduct {
    id: string;
    name: string;
    price: number;
    eligible_for_points: boolean;
    category?: string;
    store_id: string;
}
export interface UpdateLoyaltyAfterSaleOrRefund {
    customer_id: string;
    total_spent: number;
    visit_count: number;
    merchant_id: string;
    store_id: string;
    points_earned: number;
    points_deducted: number;
}
export interface RedeemPoints {
    merchant_id: string;
    customer_id: string;
    points_to_redeem: number;
    store_id: string;
}
export interface RedeemPointOutput {
    transaction: LoyaltyTransaction;
    discount_amount: number;
}
export enum LoyaltyType {
    MONEY_BASED = 'MONEY_BASED',
    VISIT_BASED = 'VISIT_BASED',
    PERCENTAGE_BASED = 'PERCENTAGE_BASED'
}
export interface LoyaltyProgram extends Timestamps {
    id: string;
    name: string;
    loyalty_type: LoyaltyType;
    description: string;
    points_per_threshold: number;
    threshold_amount: number;
    merchant_id: string;
    store_id: string;
    is_active: boolean;
}
export interface CreateLoyaltyProgram {
    name: string;
    loyalty_type: LoyaltyType;
    description: string;
    points_per_threshold: number;
    threshold_amount: number;
    merchant_id: string;
    is_active: boolean;
    store_id: string;
}
export interface UpdateLoyaltyProgram {
    id: string;
    merchant_id: string;
    store_id: string;
    name?: string;
    loyalty_type?: LoyaltyType;
    description?: string;
    points_per_threshold?: number;
    threshold_amount?: number;
    is_active?: boolean;
}
export interface LoyaltyProgramDBItem extends LoyaltyProgram {
    PK: string; // LOYALTY#id
    SK: string; // LOYALTY#id
    GSI1PK: string; // MERCHANT#merchant_id
    GSI1SK: string; // LOYALTY#id
    GSI2PK: string; // STORE#store_id
    GSI2SK: string; // LOYALTY#id
    EntityIndexPK: string; // LOYALTY
    EntityIndexSK: string; // DATE#created_at
}
export interface LoyaltyProgramPage {
    cursor?: string;
    count?: number;
    programs: LoyaltyProgram[];
}
export interface GetLoyaltyPrograms {
    cursor?: string;
    limit?: number;
    merchant_id: string;
}

export enum DebtOriginType {
    RECEIPT = 'RECEIPT',
    LOAN = 'LOAN',
    TRANSFER = 'TRANSFER'
}
/**
 * Enum representing the current status of a debt.
 */
export enum DebtStatus {
    ACTIVE = 'ACTIVE',
    CLOSED = 'CLOSED',
    OVERDUE = 'OVERDUE',
    DEFAULTED = 'DEFAULTED',
    CANCELLED = 'CANCELLED',
    TRANSFERRED = 'TRANSFERRED'
}
export interface DebtTransfer extends Timestamps {
    id: string;
    from_debt_id: string;
    to_debt_id: string;
    store_id: string;
    merchant_id: string;
    note: string;
    creator_id: string;
    creator_name: string;
    creator_type: EntityTypes;
    amount: number;
}
export interface Debt extends Timestamps {
    id: string;
    creator_type: EntityTypes; // Employees(user) or Receipts
    creator_id: string;
    creator_name: string;
    debtor_id: string; // Employee or Customer
    debtor_name: string;
    debtor_type: EntityTypes;
    store_id: string;
    merchant_id: string;
    transfers: DebtTransfer[];
    origin_type: DebtOriginType;
    principal_amount: number;
    outstanding_balance: number;
    interest_rate: number; // e.g., 5.5 for 5.5%
    status: DebtStatus;
    next_due_date: string;
    closed_date?: string;
    payment_plan: PaymentPlan;
    last_payment_date?: string;
    is_due_date_auto_calculated: boolean;
    reason: string;
    receipt_id?: string;
    version: number;
    cancellation_reason?: string;
    cancelled_at?: string;
}
export enum DebtEvent {
    DEBT_STATUS_UPDATED = 'debt_status_updated',
    DEBT_CREATED = 'debt_created',
    DEBT_TRANSFERRED = 'debt_transferred',
    DEBT_INTEREST_UPDATED = 'debt_interest_updated',
    DEBT_DUE_DATE_UPDATED = 'debt_due_date_updated',
    DEBT_CLOSING_DATE_UPDATED = 'debt_closing_date_updated',
    DEBT_PLAN_AMOUNT_UPDATED = 'debt_payment_plan_amount_updated',
    DEBT_PLAN_FREQUENCY_UPDATED = 'debt_payment_plan_frequency_updated',
    DEBT_PLAN_NOTE_UPDATED = 'debt_payment_plan_note_updated',
    DEBT_PAYMENT_TYPE_UPDATED = 'debt_payment_type_updated',
    DEBT_CREATOR_NAME_UPDATED = 'debt_creator_name_updated',
    DEBTOR_NAME_UPDATED = 'debtor_name_updated',
    DEBT_PRINCIPAL_AMOUNT_UPDATED = 'debt_principal_amount_updated',
    DEBT_OUTSTANDING_BALANCE_UPDATED = 'debt_outstanding_balance_updated',
    DEBT_PAYMENT_OUTSTANDING_BALANCE_UPDATED = 'debt_payment_outstanding_balance_updated',
    DEBT_DELETED = 'debt_deleted',
    DEBT_PAYMENT_UPDATED = 'debt_payment_updated',
    AUTO_CALCULATED_DUE_DATE_UPDATED = 'auto_calculated_due_date_updated'
}
export interface DebtChange extends Timestamps {
    id: string;
    event: DebtEvent;
    version: string;
    debt_version: number;
    updator_id: string;
    updator_name: string;
    updator_type: EntityTypes;
    changed_attributes: ChangedAttributes;
    store_id: string;
    merchant_id: string;
}
export interface GetDebtChanges {
    by_event: boolean; // get all changes for a specific event: use GSI2PK, GSI2SK
    by_event_and_debt: boolean; // get all changes for a specific event and debt: use GSI2PK, GSI2SK
    by_event_and_date_range: boolean; // get all changes for a specific event within a date range: use GSI2PK, GSI2SK
    by_debt: boolean; // get all changes for a debt: use PK, SK
    by_debt_and_date_range: boolean; // get all changes for a debt within a date range: use EntityIndexPK, EntityIndexSK
    by_updator: boolean; // get all changes made by a user: use GSI1PK, GSI1SK
    by_updator_and_date_range: boolean; // get all changes made by a user within a date range: use GSI1PK, GSI1SK
    by_updator_and_debt: boolean; // get all changes made by a user for a debt: use GSI1PK, GSI1SK
    event?: DebtEvent;
    debt_id?: string;
    updator_id?: string;
    updator_type?: EntityTypes;
    start_date?: string;
    end_date?: string;
    store_id?: string;
    merchant_id: string;
    limit?: number;
    cursor?: string;
}
export interface DebtPayment extends Timestamps {
    id: string;
    debt_id: string;
    amount: number;
    outstanding_balance: number;
    initial_amount: number;
    payment_type: PaymentMethodType;
    user_id: string;
    payer_name: string;
    payer_id: string;
    payer_type: EntityTypes;
    store_id: string;
    merchant_id: string;
    note: string;
}
export interface PaymentPlan extends Timestamps {
    amount: number;
    frequency: number; // in days
    store_id: string;
    merchant_id: string;
    note: string;
}
export interface DebtChangeEvent {
    changes: ChangedAttributes;
    event: DebtEvent;
}
export interface CreateDebtCommandPayload {
    creator_id: string;
    creator_type: EntityTypes; // e.g., 'EMPLOYEE' or 'RECEIPT' if using snake case for enums, adjust accordingly
    creator_name: string;
    debtor_id: string;
    debtor_type: EntityTypes;
    debtor_name: string;
    store_id: string;
    merchant_id: string;
    origin_type: DebtOriginType; // e.g., 'sale' | 'loan' | 'transfer'
    principal_amount: number;
    interest_rate: number; // e.g., 5.5 for 5.5%
    reason: string;
    is_due_date_auto_calculated?: boolean;
    initial_outstanding_balance?: number;
    next_due_date?: string; // ISO 8601, optional if auto-calculated
    receipt_id?: string;
    payment_plan?: {
        amount: number;
        frequency: number; // in days
        note?: string;
    };
}
export interface UpdateDebtStatusCommandPayload {
    debt_id: string;
    store_id: string;
    merchant_id: string;
    updator_id: string;
    updator_type: EntityTypes;
    updator_name: string;
    status: DebtStatus; // e.g., 'ACTIVE', 'OVERDUE', etc.
}
export interface CancelDebtCommandPayload {
    debt_id: string;
    store_id: string;
    merchant_id: string;
    updator_id: string;
    updator_type: EntityTypes;
    updator_name: string;
    reason: string;
}
export interface TransferDebtCommandPayload {
    from_debt_id: string;
    to_debt_id: string;
    amount: number;
    note: string;
    store_id: string;
    merchant_id: string;
    creator_id: string;
    creator_type: EntityTypes;
    creator_name: string;
}
export interface UpdateDateInterestRateCommandPayload {
    debt_id: string;
    store_id: string;
    merchant_id: string;
    updator_id: string;
    updator_type: EntityTypes;
    updator_name: string;
    interest_rate: number;
}
export interface UpdateDebtPrincipalCommandPayload {
    debt_id: string;
    store_id: string;
    merchant_id: string;
    updator_id: string;
    updator_type: EntityTypes;
    updator_name: string;
    principal_amount: number;
}
//
// Payment Commands Payloads
//
export interface AddDebtPaymentCommandPayload {
    debt_id: string;
    amount: number;
    payment_type: PaymentMethodType; // e.g., 'CASH', 'CARD'
    payer_id: string;
    payer_type: EntityTypes;
    payer_name: string;
    store_id: string;
    merchant_id: string;
    user_id: string; // who is recording the payment
    note?: string;
}
export interface UpdateDebtPaymentPlanCommandPayload {
    debt_id: string;
    amount?: number;
    frequency?: number;
    note?: string;
    store_id: string;
    merchant_id: string;
    updator_id: string;
    updator_type: EntityTypes;
    updator_name: string;
}
export enum DebtCommandType {
    CREATE_DEBT = 'CREATE_DEBT',
    UPDATE_DEBT_STATUS = 'UPDATE_DEBT_STATUS',
    CANCEL_DEBT = 'CANCEL_DEBT',
    TRANSFER_DEBT = 'TRANSFER_DEBT',
    UPDATE_INTEREST_RATE = 'UPDATE_INTEREST_RATE',
    UPDATE_PRINCIPAL_AMOUNT = 'UPDATE_PRINCIPAL_AMOUNT',
    ADD_PAYMENT = 'ADD_PAYMENT',
    UPDATE_PAYMENT_PLAN = 'UPDATE_PAYMENT_PLAN'
}
export interface DebtCommand {
    command: DebtCommandType;
    createDebtCommandPayload?: CreateDebtCommandPayload;
    updateDebtStatusCommandPayload?: UpdateDebtStatusCommandPayload;
    cancelDebtCommandPayload?: CancelDebtCommandPayload;
    transferDebtCommandPayload?: TransferDebtCommandPayload;
    updateDateInterestRateCommandPayload?: UpdateDateInterestRateCommandPayload;
    updateDebtPrincipalCommandPayload?: UpdateDebtPrincipalCommandPayload;
    addDebtPaymentCommandPayload?: AddDebtPaymentCommandPayload;
    updateDebtPaymentPlanCommandPayload?: UpdateDebtPaymentPlanCommandPayload;
}
export interface DebtDBItem extends Debt {
    PK: string; // MERCHANT#<merchant_id>#STORE#<store_id>
    SK: string; // DEBT#<debt_id>
    EntityIndexPK: string; // DEBT
    EntityIndexSK: string; // MERCHANT#<merchant_id>#STORE#<store_id>#DATE#<created_at>
    GSI1PK: string; // STORE#<store_id>#DEBT_STATUS#<debt_status>
    GSI1SK: string; // DEBT#MERCHANT#<merchant_id>#STORE#<store_id>#DATE#<created_at>
    GSI2PK: string; // STORE#<store_id>#DEBT_ORGIN#<debt_orign>
    GSI2SK: string; // DEBT#MERCHANT#<merchant_id>#STORE#<store_id>#DATE#<created_at>
    GSI3PK: string; // <debtor_type>#<debtor_id>
    GSI3SK: string; // DEBT#MERCHANT#<merchant_id>#STORE#<store_id>#DATE#<created_at>
    GSI4PK: string; // <creator_type>#<creator_id>
    GSI4SK: string; // DEBT#MERCHANT#<merchant_id>#STORE#<store_id>#DATE#<created_at>
    GSI5PK?: string; // MERCHANT#<merchant_id>#STORE#<store_id>#RECEIPT#<receipt_id>
    GSI5SK?: string; // DEBT#<debt_id>#DATE#<created_at>
}
export interface DebtChangeDBItem extends DebtChange {
    PK: string; // DEBT#<debt_id>
    SK: string; // DEBT_CHANGE#<version>
    EntityIndexPK: string; // DEBT_CHANGE
    EntityIndexSK: string; // MERCHANT#<merchant_id>#STORE#<store_id>#DATE#<created_at>
    GSI1PK: string; // <updator_type>#<updator_id>
    GSI1SK: string; // DEBT_CHANGE#DEBT#<debt_id>#DATE#<created_at>
    GSI2PK: string; // STORE#<store_id>#DEBT_CHANGE#<event>
    GSI2SK: string; // DEBT#<debt_id>#DATE#<created_at>
}
export interface DebtPaymentDBItem extends DebtPayment {
    PK: string; // DEBT#<debt_id>
    SK: string; // DEBT_PAYMENT#<payment_id>
    EntityIndexPK: string; // DEBT_PAYMENT
    EntityIndexSK: string; // DEBT_PAYMENT#MERCHANT#<merchant_id>#STORE#<store_id>#DATE#<created_at>
    GSI1PK: string; // <payer_type>#<payer_id>
    GSI1SK: string; // DEBT_PAYMENT#DEBT#<debt_id>#DATE#<created_at>
    GSI2PK: string; // STORE#<store_id>
    GSI2SK: string; // DEBT_PAYMENT#DATE#<created_at>
    GSI3PK: string; // USER#<user_id>
    GSI3SK: string; // DEBT_PAYMENT#DEBT#<debt_id>#DATE#<created_at>
    GSI4PK: string; // DEBT#<debt_id>
    GSI4SK: string; // DEBT_PAYMENT#DATE#<created_at>
}
export interface GetDebtAggregate {
    debt_id: string;
    merchant_id: string;
    store_id: string;
    change_cursor?: string;
    payment_cursor?: string;
    change_limit?: number;
    payment_limit?: number;
}
export interface GetDebts {
    by_store?: boolean; // get all debts for a store: use PK, SK
    by_store_and_date_range?: boolean; // get all debts for a store within a date range: use EntityIndexPK, EntityIndexSK
    by_store_and_status?: boolean; // get all debts for a store with a specific status: use GSI1PK, GSI1SK
    by_store_and_status_and_date_range?: boolean; // get all debts for a store with a specific status within a date range: use GSI1PK, GSI1SK
    by_store_and_origin?: boolean; // get all debts for a store with a specific origin: use GSI2PK, GSI2SK
    by_store_and_origin_and_date_range?: boolean; // get all debts for a store with a specific origin within a date range: use GSI2PK, GSI2SK
    by_creator?: boolean; // get all debts created by a user: use GSI4PK, GSI4SK
    by_creator_and_date_range?: boolean; // get all debts created by a user within a date range: use GSI4PK, GSI4SK
    by_debtor?: boolean; // get all debts for a debtor: use GSI3PK, GSI3SK
    by_debtor_and_date_range?: boolean; // get all debts for a debtor within a date range: use GSI3PK, GSI3SK
    by_merchant?: boolean; // get all debts for a merchant: use EntityIndexPK, EntityIndexSK
    by_merchant_and_date_range?: boolean; // get all debts for a merchant within a date range: use EntityIndexPK, EntityIndexSK
    by_receipt?: boolean; // get all debts for a receipt: use GSI5PK, GSI5SK
    store_id?: string;
    merchant_id: string;
    status?: DebtStatus;
    origin?: DebtOriginType;
    debtor_id?: string;
    debtor_type?: EntityTypes;
    creator_id?: string;
    creator_type?: EntityTypes;
    start_date?: string;
    end_date?: string;
    receipt_id?: string;
    limit?: number;
    cursor?: string;
}
export interface GetDebtPayments {
    by_debt?: boolean; // get all payments for a debt: use PK, SK
    by_debt_and_date_range?: boolean; // get all payments for a debt within a date range: use GSI4PK, GSI4SK
    by_payer?: boolean; // get all payments made by a payer: use GSI1PK, GSI1SK
    by_payer_and_date_range?: boolean; // get all payments made by a payer within a date range: use GSI1PK, GSI1SK
    by_merchant?: boolean; // get all payments for a merchant: use EntityIndexPK, EntityIndexSK
    by_merchant_and_date_range?: boolean; // get all payments for a merchant within a date range: use EntityIndexPK, EntityIndexSK
    by_store?: boolean; // get all payments for a store: use GSI2PK, GSI2SK
    by_store_and_date_range?: boolean; // get all payments for a store within a date range: use GSI2PK, GSI2SK
    debt_id?: string;
    payer_id?: string;
    payer_type?: EntityTypes;
    store_id?: string;
    merchant_id: string;
    start_date?: string;
    end_date?: string;
    limit?: number;
    cursor?: string;
}
export interface DebtPaymentsPage {
    payments: DebtPayment[];
    cursor: string;
    count: number;
}
export interface DebtChangesPage {
    changes: DebtChange[];
    cursor: string;
    count: number;
}
export interface DebtsPage {
    debts: Debt[];
    cursor: string;
    count: number;
}
export interface DebtAggregate {
    debt: Debt;
    changes: DebtChangesPage;
    payments: DebtPaymentsPage;
}
export interface DebtorSummary {
    debtor_id: string;
    debtor_type: EntityTypes;
    debtor_name: string;
    total_money_debt: number;
    total_money_payments: number;
    total_money_outstanding: number;
    total_money_overdue: number;
    total_money_defaulted: number;
    total_money_closed: number;
    total_money_interest: number;
    total_money_principal: number;
    total_money_transferred_out: number;
    total_money_transferred_in: number;
    next_due_date: string;
    stores: string[];
    overdue_count: number;
    debt_count: number;
    closed_count: number;
    debts: DebtAggregate[];
}
export interface GetDebtorSummary {
    debtor_id: string;
    debtor_type: EntityTypes;
    merchant_id: string;
    store_id: string;
}

//help.loyverse.com/help/how-discounts-are-calculated
export enum DiscountType {
    FIXED_PERCENT = 'FIXED_PERCENT',
    FIXED_AMOUNT = 'FIXED_AMOUNT',
    VARIABLE_PERCENT = 'VARIABLE_PERCENT',
    VARIABLE_AMOUNT = 'VARIABLE_AMOUNT',
    DISCOUNT_BY_POINTS = 'DISCOUNT_BY_POINTS'
}
export interface Discount extends Timestamps {
    id: string; // UUID. Optional for creation, required for update.
    type: DiscountType; // Discount type.
    name: string; // Discount name. Length between 1 and 40 characters.
    discount_amount?: number; // Discount value in money, required only for FIXED_AMOUNT.
    discount_percent?: number; // Discount value in percentage, required only for FIXED_PERCENT.
    store_id: string;
    restricted_access?: boolean; // If true, password verification is required to apply.
}
export interface DiscountPage {
    discounts: Discount[];
    count: number;
    cursor?: string;
}
export interface CreateDiscount {
    type: DiscountType;
    name: string;
    discount_amount?: number;
    discount_percent?: number;
    store_id: string;
    restricted_access?: boolean;
}
export interface UpdateDiscount {
    id: string;
    type?: DiscountType;
    name?: string;
    discount_amount?: number;
    discount_percent?: number;
    store_id: string;
    restricted_access?: boolean;
}
export interface DiscountDBItem extends Discount {
    PK: string;
    SK: string;
    GSI1PK: string;
    GSI1SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
}
export interface GetDiscountById {
    id: string;
}
export interface GetDiscounts {
    store_id: string;
    limit: number;
    cursor?: string;
}
export interface DiscountPage {
    discounts: Discount[];
    cursor?: string;
    count: number;
}
export enum CalculateDiscountCommands {
    PERCENTAGE = 'PERCENTAGE',// Apply a percentage discount to the item or receipt
    FIXED = 'FIXED',// Apply a fixed amount discount to the item or receipt
    MULTIPLE_DISCOUNTS = 'MULTIPLE_DISCOUNTS',// Apply multiple discounts (percentage + fixed) to the item or receipt
    ITEM_LEVEL_DISCOUNT = 'ITEM_LEVEL_DISCOUNT',// Apply item-level discounts where each item can have its own discounts
    RECEIPT_LEVEL_DISCOUNT = 'RECEIPT_LEVEL_DISCOUNT',// Apply receipt-level discount across all items in the receipt
    TAX_AFTER_DISCOUNT = 'TAX_AFTER_DISCOUNT',// Apply tax after discounts have been applied
    TAX_BEFORE_DISCOUNT = 'TAX_BEFORE_DISCOUNT'
}
export interface CalculateDiscount {
    item_price: number;
    discount_ids: string[];
    added_discount_id: string;
    included_discount_ids: string[];
    command: CalculateDiscountCommands;
}
export interface CalculateDiscountResultItem {
    // The list of items, used for item-level discounts
    price: number; // Original price of the item
    finalPrice: number; // Final price of the item after applying discounts
    discount_value?: number; // Total discount applied to this item
    discount_ids: string[]; // List of discount IDs applied to the item
}
export interface CalculateDiscountResult {
    discount_command: CalculateDiscountCommands; // The command that was used for this discount calculation
    item_price?: number; // The original item price (for single-item discounts)
    final_price?: number; // The final price after discounts (and possibly taxes) have been applied
    discount_value?: number; // The total amount discounted
    total_discount?: number; // The total discount applied across all items or to the receipt
    total_tax?: number; // The total tax applied (used in tax-before/after scenarios)
    items?: CalculateDiscountResultItem[]; // The list of items with their final prices and discounts
}

export interface ShopAndSmileEventMetadata {
    correlation_id?: string; // Id of the transaction this event is part of
    causation_id: string; // Id of the event that caused this event
    event_id: string;
    event_version: number;
    time: string;
    source: ShopAndSmileEventSource;
    stage: string;
    service: string;
    tags: string[];
    creator_id: string;
    creator_type: EntityTypes;
    creator_name: string;
}
export interface EventStoreKeys {
    PK: string; // EVENT#<eventId>
    SK: string; // EVENT#<eventId>
    GSI1PK: string; // CORRELATION#<correlationId>
    GSI1SK: string; // EVENT#<eventId>
    GSI2PK: string; // <entityType>#<entityId>
    GSI2SK: string; // EVENT#DATE#<eventTime>
    GSI3PK: string; // <event>
    GSI3SK: string; // EVENT#<eventId>
    GSI4PK: string; // DOMAIN#<domain>
    GSI4SK: string; // <event>#DATE#<eventTime>#EVENT#<eventId>
    GSI5PK: string; // <creatorType>#<creatorId>
    GSI5SK: string; // <entityType>#<entityId>#EVENT#<eventId>
    GSI6PK: string; // <isSuccess>
    GSI6SK: string; // <entityType>#<entityId>#DATE#<eventTime>#EVENT#<eventId>
    EntityIndexPK: string; // EntityType
    EntityIndexSK: string; // DATE#<eventTime>#EVENT#<eventId>
}
export interface TimeRange {
    from: string;
    to: string;
}

export interface Image extends Timestamps {
    image_s3_url: string;
    image_url: string;
    image_s3_key: string;
    entity_type: EntityTypes;
    entity_id: string;
    image_id: string;
    merchant_id: string;
}
export interface CreateImage {
    image_id: string;
    base64Image: string;
    entity_type: EntityTypes;
    entity_id: string;
    merchant_id: string;
}
export interface ImagePage {
    images: Image[];
    cursor?: string;
    count: number;
}
export enum ImageFileTypes {
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    BMP = 'bmp'
}
export interface UploadImageResponse {
    image_s3_key: string;
    image_id: string;
    file_name: string;
}
export interface UploadItemImageRequest {
    metadata: UploadImageRequest;
    base64Image: string;
}
export interface UploadImageRequest {
    entity_type: EntityTypes;
    entity_id: string;
    updator_id: string;
    store_id: string;
    updator_type: EntityTypes;
}
export interface UploadPromoImageRequest {
    store_id: string;
    merchant_id: string;
    updator_id: string;
    updator_type: EntityTypes;
    base64Image: string;
    item_id: string;
}
export interface ImageMetadata {
    file_type: string;
    entity_type: string;
    entity_id: string;
    image_id: string;
    image_s3_key: string;
    has_been_processed: StringBoolean;
    updator_id: string;
    updator_type: EntityTypes;
    store_id: string;
    merchant_id: string;
    [key: string]: string;
}

export enum InventoryTemplateStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    INCOMPLETE = 'INCOMPLETE',
    CANCELLED = 'CANCELLED'
}
export interface InventoryTemplate {
    created_at: string;
    updated_at: string;
    creator_id: string;
    creator_type: EntityTypes;
    counter_id: string;
    counter_type: EntityTypes;
    status: InventoryTemplateStatus;
    count_day: string;
    id: string;
    uncounted_items: string[];
    counted_items: string[];
    store_id: string;
    title: string;
    week_number: number;
}
export interface CreateInventoryTemplateDto {
    creator_id: string;
    creator_type: EntityTypes;
    counter_id: string;
    counter_type: EntityTypes;
    count_day: string;
    uncounted_items: string[];
    store_id: string;
    title: string;
}
export interface InventoryTemplatesPage extends Paginated {
    inventory_templates: InventoryTemplate[];
}
export interface GetInventoryTemplate {
    store_id: string;
    template_id: string;
}
export interface UpdateInventoryTemplate {
    store_id: string;
    counter_id?: string;
    counter_type?: EntityTypes;
    template_id: string;
    status?: InventoryTemplateStatus;
    counted_items?: string[];
    uncounted_items?: string[];
    title?: string;
    count_day?: string;
}
export interface DeleteInventoryTemplate {
    store_id: string;
    template_id: string;
}

export enum InventoryChangeReason {
    SALE = 'Sale',
    REFUND = 'Refund',
    PURCHASE_ORDER = 'Purchase Order',
    INVENTORY_TRANSFER_IN = 'Inventory Transfer In',
    INVENTORY_TRANSFER_OUT = 'Inventory Transfer Out',
    INVENTORY_COUNT = 'Inventory Count',
    INVENTORY_CORRECTION = 'Inventory Correction'
}
export enum ReasonCounted {
    SALE = 'Sale',
    REFUND = 'Refund',
    PURCHASE = 'Purchase Order',
    INVENTORY_COUNT = 'Inventory Count',
    INVENTORY_CORRECTION = 'Inventory Correction'
}
export enum InventoryChangeEvent {
    INVENTORY_CHANGE_QUANTITY_UPDATED = 'INVENTORY_CHANGE_QUANTITY_UPDATED',
    INVENTORY_CUMULATIVE_QUANTITY_UPDATED = 'INVENTORY_CUMULATIVE_QUANTITY_UPDATED',
    INVENTORY_CHANGE_REASON_UPDATED = 'INVENTORY_CHANGE_REASON_UPDATED',
    INVENTORY_CHANGE_SOURCE_UPDATED = 'INVENTORY_CHANGE_SOURCE_UPDATED',
    INVENTORY_CHANGE_DELETED_AT_UPDATED = 'INVENTORY_CHANGE_DELETED_AT_UPDATED',
    INVENTORY_CHANGE_SOURCE_ID_UPDATED = 'INVENTORY_CHANGE_SOURCE_ID_UPDATED',
    INVENTORY_CHANGE_SOURCE_TYPE_UPDATED = 'INVENTORY_CHANGE_SOURCE_TYPE_UPDATED'
}
export interface PurchaseOrderDetails {
    id: string;
    supplier: Supplier;
}
export interface InventoryChange extends Timestamps {
    id: string; // Unique change_id
    store_id: string;
    product_id: string;
    product_name: string;
    change_quantity: number;
    cumulative_quantity: number;
    reason: InventoryChangeReason;
    merchant_id: string;
    created_at: string; // ISO 8601 date string
    type: 'CHANGE';
    // Additional attributes
    source_id: string;
    source_type: EntityTypes;
    cost: number;
    price: number;
    source_name: string;
    purchase_order_details?: PurchaseOrderDetails;
    transfer_store_id?: string;
}
export interface CreateInventoryChange {
    store_id: string;
    product_id: string;
    change_quantity: number;
    reason: InventoryChangeReason;
    merchant_id: string;
    source_id: string;
    cost: number;
    source_type: EntityTypes;
    source_name: string;
    product_name: string;
    purchase_order_details?: PurchaseOrderDetails;
    price: number;
    transfer_store_id?: string;
}
export interface CurrentInventory extends Timestamps {
    id: string;
    store_id: string;
    product_id: string;
    cumulative_quantity: number;
    merchant_id: string;
    type: 'CURRENT';
    cost: number;
    price: number;
}
export interface InventoryChangeDBItem extends InventoryChange {
    // PK: `STORE#${store_id}#PRODUCT#${product_id}`
    // SK: `INVENTORY_CHANGE#DATE#${created_at}#INVENTORY_CHANGE#${id}`
    PK: string;
    SK: string;
    // GSI1PK: `PRODUCT#${product_id}`
    // GSI1SK: `INVENTORY_CHANGE#STORE#${store_id}#DATE#${created_at}`
    GSI1PK: string;
    GSI1SK: string;
    // GSI2PK: `REASON#${reason}#STORE#${store_id}`
    // GSI2SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#DATE#${created_at}`
    GSI2PK: string;
    GSI2SK: string;
    // GSI3PK: `SOURCE#${source}#STORE#${store_id}`
    // GSI3SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#DATE#${created_at}`
    GSI3PK: string;
    GSI3SK: string;
    // GSI4PK: `REASON#${reason}#SOURCE#${source}#STORE#${store_id}`
    // GSI4SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#DATE#${created_at}`
    GSI4PK: string;
    GSI4SK: string;
    // GSI5PK: `REASON#${reason}`
    // GSI5SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#STORE#${store_id}#DATE#${created_at}`
    GSI5PK: string;
    GSI5SK: string;
    // GSI6PK: `SOURCE#${source}`
    // GSI6SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#STORE#${store_id}#DATE#${created_at}`
    GSI6PK: string;
    GSI6SK: string;
    // GSI7PK: `REASON#${reason}#SOURCE#${source}`
    // GSI7SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#STORE#${store_id}#DATE#${created_at}`
    GSI7PK: string;
    GSI7SK: string;
    // EntityIndexPK: `INVENTORY_CHANGE`
    // EntityIndexSK: `MERCHANT#${merchant_id}#STORE#${store_id}#PRODUCT#${product_id}#DATE#${created_at}`
    EntityIndexPK: string;
    EntityIndexSK: string;
}
export interface CurrentInventoryDBItem extends CurrentInventory {
    // PK: `STORE#${store_id}#PRODUCT#${product_id}`
    // SK: `TYPE#CURRENT`
    PK: string;
    SK: string;
    // GSI1PK: `PRODUCT#${product_id}`
    // GSI1SK: `TYPE#CURRENT#STORE#${store_id}`
    GSI1PK: string;
    GSI1SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
}
export interface GetInventoryChanges {
    /**
     * Flags to determine which query to perform.
     * Only one should be true per request.
     */
    by_store_and_product_and_date_range?: boolean;
    by_store_and_date_range?: boolean;
    by_reason_and_store_and_product_and_date_range?: boolean;
    by_reason_and_store?: boolean;
    by_reason_across_stores?: boolean;
    by_source_and_store?: boolean;
    by_source_across_stores?: boolean;
    by_reason_and_source_and_store?: boolean;
    by_reason_and_source_across_stores?: boolean;
    by_product_and_store?: boolean;
    by_product_across_stores?: boolean;
    by_reason_and_product_and_store?: boolean;
    by_reason_and_product_across_stores?: boolean;
    by_source_and_product_and_store?: boolean;
    by_source_and_product_across_stores?: boolean;
    by_source_and_reason_and_product?: boolean;
    by_source_and_reason_and_store?: boolean;
    by_source_and_reason_across_stores?: boolean;
    /**
     * Common query parameters.
     */
    limit?: number;
    cursor?: string;
    sort_order?: SortOrder;
    /**
     * Filter parameters.
     */
    store_id?: string;
    product_id?: string;
    reason?: InventoryChangeReason;
    source_type?: EntityTypes;
    source_id?: string;
    merchant_id: string; // For merchant-level queries
    start_date?: string; // ISO 8601 date string
    end_date?: string; // ISO 8601 date string
}
export interface InventoryChangePage {
    changes: InventoryChange[];
    cursor?: string;
    count: number;
}

export enum LoanStatus {
    LOAN_PENDING = 'LOAN_PENDING',
    LOAN_PAID = 'LOAN_PAID',
    LOAN_OVERDUE = 'LOAN_OVERDUE',
    LOAN_PARTIALLY_PAID = 'LOAN_PARTIALLY_PAID',
    LOAN_NEEDS_REVIEW = 'LOAN_NEEDS_REVIEW'
}
export interface Loan extends Timestamps, CommonEmployeeAsCreatorAttributes {
    id: string;
    paid_amount: number;
    total_amount: number;
    unpaid_amount: number;
    borrower_id: string;
    borrower_type: EntityTypes;
    borrower_loan_id: string;
    due_date: string;
    overdue_amount: number;
    status: LoanStatus;
    reason: string;
    associated_payments: string[];
    loan_history: LoanHistoryItem[];
    store_id: string;
}
export interface LoanHistoryItem extends Timestamps {
    event: LoanHistoryEvent;
    created_at: string;
    creator_id: string;
    creator_name: string;
    creator_type: string;
    paid_amount: number;
    total_amount: number;
    unpaid_amount: number;
    due_date: string;
    overdue_amount: number;
}
export enum LoanHistoryEvent {
    LOAN_CREATED = 'LOAN CREATED',
    LOAN_PAID = 'LOAN PAID',
    LOAN_OVERDUE = 'LOAN OVERDUE',
    LOAN_PARTIALLY_PAID = 'LOAN PARTIALLY PAID',
    LOAN_REFUNDED = 'LOAN REFUNDED'
}
export interface BorrowerLoan extends Timestamps, CommonEmployeeAsCreatorAttributes {
    id: string;
    borrower_id: string;
    borrower_type: EntityTypes;
    total_amount: number;
    total_paid_amount: number;
    total_value_of_completed_loans: number;
    total_number_of_loans: number;
    total_number_of_completed_loans: number;
    total_unpaid_amount: number;
    total_overdue_amount: number;
    oldest_due_date: string;
    status: LoanStatus;
    incomplete_loans: Loan[];
    reason: string;
}
export interface GetLoansByCreatorDto extends Paginated {
    creator_id: string;
    by_creator_id?: boolean;
    is_borrower_loan?: boolean;
}
export interface GetLoansByBorrowerIdDto extends Paginated {
    borrower_id: string;
    by_borrower_id?: boolean;
    is_borrower_loan?: boolean;
}
export interface GetLoanByLoanIdDto {
    loan_id: string;
    by_loan_id?: boolean;
    by_borrower_loan_id?: boolean;
    borrower_loan_id?: string;
}
export interface GetLoansByStatusesDto extends Paginated {
    statuses: LoanStatus[];
    by_statuses?: boolean;
    by_borrower_id_and_status?: boolean;
    by_statuses_and_borrower_loan_id?: boolean;
    is_borrower_loan?: boolean;
    borrower_id?: string;
    borrower_loan_id?: string;
    borrower_type?: EntityTypes;
}
export interface BorrowerLoanPage {
    borrower_loans: BorrowerLoan[];
    cursor: string | null;
    count: number;
}
export interface CreateLoanDto extends CommonEmployeeAsCreatorAttributes {
    borrower_id: string;
    borrower_type: EntityTypes;
    total_amount: number;
    reason: string;
}
export interface CreateLoanAfterFailureDto {
    creator: CommonEmployeeAsCreatorAttributes;
    borrower_id: string;
    borrower_type: EntityTypes;
    total_amount: number;
    reason: string;
    previousEventId: string;
}
export interface UpdateLoanDto {
    borrower_id: string;
    borrower_type: EntityTypes;
    status: LoanStatus;
}
export interface LoanDBItem extends Loan {
    PK: string; // PK: `BORROWER_LOAN#${borrower_loan_id}`
    SK: string; // SK: `LOAN#${loan_id}`
    GSI1PK: string; // <status>
    GSI1SK: string; // LOAN#<id>
    GSI2PK: string; // <creator_type>#<creator_id>
    GSI2SK: string; // LOAN#<id>
    GSI3PK: string; // <borrower_type>#<borrower_id>
    GSI3SK: string; // LOAN#<id>
    GSI4PK: string; // <borrower_type>#<borrower_id>
    GSI4SK: string; // <status>#LOAN#<id>
    GSI5PK: string; // BORROWER_LOAN#<borrower_loan_id>
    GSI5SK: string; // <status>#LOAN#<id>
    GSI6PK: string; // LOAN#${loan_id}
    GSI6SK: string; // BORROWER_LOAN#${borrower_loan_id}
    EntityIndexPK: string; // LOAN
    EntityIndexSK: string; // LOAN#DATE#<created_at>
}
export interface BorrowerLoanDBItem extends Omit<BorrowerLoan, 'incomplete_loans'> {
    PK: string; // PK: `BORROWER_LOAN#${borrower_loan_id}`
    SK: string; // SK: `BORROWER_LOAN#${borrower_loan_id}`
    GSI1PK: string; // <status>
    GSI1SK: string; // BORROWER_LOAN#<id>
    GSI2PK: string; // <creator_type>#<creator_id>
    GSI2SK: string; // BORROWER_LOAN#<id>
    GSI3PK: string; // <borrower_type>#<borrower_id>
    GSI3SK: string; // BORROWER_LOAN#<id>
    EntityIndexPK: string; // BORROWER_LOAN
    EntityIndexSK: string; // BORROWER_LOAN#DATE#<created_at>
}
export interface LoanPage {
    loans: Loan[];
    cursor: string | null;
    count: number;
}
export interface BorrowerLoanSummary {
    borrower_loan: BorrowerLoan;
    incomplete_loans_cursor: string | null;
    incomplete_loans_count: number;
}
export interface BorrowerLoanPage {
    borrower_loans: BorrowerLoan[];
    cursor: string | null;
    count: number;
}

export interface Merchant extends Timestamps {
    id: string;
    business_name: string;
    email: string;
    country: string;
    currency: Currency;
    phone_number: string;
    address: string;
    contact_person: string;
    stores: string[];
}
export interface MerchantPage {
    merchants: Merchant[];
    count: number;
    cursor?: string;
}
export interface CreateMerchant {
    business_name: string;
    email: string;
    country: string;
    currency: Currency;
    phone_number: string;
    address: string;
    contact_person: string;
    stores: string[];
}
export interface UpdateMerchant {
    business_name?: string;
    email?: string;
    country?: string;
    currency?: Currency;
    phone_number?: string;
    address?: string;
    contact_person?: string;
    id: string;
    add_stores?: string[];
    remove_stores?: string[];
}
export interface MerchantDBItem extends Merchant {
    /*
    "Entity": "MERCHANT",
    "PK": "MERCHANT#{merchant_id}#ENTITY_TYPE#MERCHANT",
    "SK": "MERCHANT#{merchant_id}",
    "EntityIndexPK": "MERCHANT",
    "EntityIndexSK": "MERCHANT#{merchant_id}#DATE#{created_at}"
    */
    PK: string;
    SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
}

export interface EmailParams {
    bccEmailAddresses?: string[];
    ccEmailAddresses?: string[];
    toEmailAddresses: string[];
    bodyData: string;
    bodyCharset: EmailCharset;
    subjectdata: string;
    subjectCharset: EmailCharset;
    sourceEmail: string;
    replyToAddresses?: string[];
}
export enum EmailCharset {
    UTF8 = 'UTF-8',
    ISO88591 = 'ISO-8859-1',// Western European languages
    ISO885915 = 'ISO-8859-15',// Western European languages + Euro symbol
    USASCII = 'US-ASCII',// Basic English characters
    UTF16 = 'UTF-16',// Unicode characters (less commonly used)
    WINDOWS1252 = 'Windows-1252'
}

/**
 * Enum representing the various payment method types.
 */
export enum PaymentMethodType {
    /** Cash payment */
    CASH = 'CASH',
    /** Card payment */
    CARD = 'CARD',
    /** Debt payment */
    DEBT = 'DEBT',
    /** Mobile money payment */
    MOBILE_MONEY = 'MOBILE_MONEY',
    /** Payment by cheque */
    CHEQUE = 'CHEQUE',
    /** Bank transfer */
    BANK_TRANSFER = 'BANK_TRANSFER',
    /** Coupon payment */
    COUPON = 'COUPON',
    /** Payment using loyalty points */
    LOYALTY_POINTS = 'LOYALTY_POINTS',
    /** Other types of payment */
    OTHER = 'OTHER'
}
/**
 * Represents a payment type in the Loyverse system.
 */
export interface PaymentType extends Timestamps {
    /**
     * The unique identifier of the payment type.
     * If included in a POST request, it will update the existing payment type instead of creating a new one.
     */
    id: string;
    /**
     * The name of the payment type.
     */
    name: string;
    /**
     * The payment method type.
     */
    payment_type: PaymentMethodType;
    /**
     * The list of store IDs where this payment type is available.
     */
    stores: string[];
    /**
     * The ID of the merchant who owns this payment type.
     */
    merchant_id: string;
}
/**
 * Represents a create payment type request.
 */
export interface CreatePaymentType {
    /**
     * The name of the payment type.
     */
    name: string;
    /**
     * The payment method type.
     */
    payment_type: PaymentMethodType;
    /**
     * The list of store IDs where this payment type is available.
     */
    stores: string[];
    /**
     * The ID of the merchant who owns this payment type.
     */
    merchant_id: string;
}
/**
 * Represents a payment type update request.
 */
export interface UpdatePaymentType {
    /**
     * The name of the payment type.
     */
    name?: string;
    /**
     * The payment method type.
     */
    payment_type?: PaymentMethodType;
    /**
     * The list of store IDs where this payment type is available.
     */
    add_stores?: string[];
    /**
     * The list of store IDs where this payment type is no longer available.
     */
    remove_stores?: string[];
    /**
     * The ID of the payment type to update.
     */
    id: string;
}
/**
 * Represents a payment type page
 */
export interface PaymentTypePage {
    /**
     * The list of payment types.
     */
    payment_types: PaymentType[];
    /**
     * The total number of payment types.
     */
    count: number;
    /**
     * The cursor for the next page of payment types.
     */
    cursor: string;
}
/**
 * Represents a Get Payment Type request.
 */
export interface GetPaymentType {
    /**
     * The ID of the payment type to get.
     */
    id: string;
}
/**
 * Represents a Get List of Payment Type request.
 */
export interface GetPaymentTypes {
    /**
     * The cursor for the next page of payment types.
     */
    cursor?: string;
    /**
     * The number of payment types to get.
     */
    limit?: number;
    /**
     * The ID of the merchant who owns this payment type.
     */
    merchant_id: string;
    /**
     * The list of store IDs where this payment type is available.
     */
    stores?: string[];
}
/**
 * Represents a Payment Type DB Item.
 */
export interface PaymentTypeDBItem extends Timestamps, PaymentType {
    /**
     * The Primary Key.
     */
    PK: string;
    /**
     * The Sort Key.
     */
    SK: string;
    /**
     * The 1st GSI PK.
     */
    GSI1PK: string;
    /**
     * The 1st GSI SK.
     */
    GSI1SK: string;
    /**
     * The Entity Index PK.
     */
    EntityIndexPK: string;
    /**
     * The Entity Index SK.
     */
    EntityIndexSK: string;
}

export enum PaymentReason {
    DEBT = 'DEBT',
    LOAN = 'LOAN',
    RECEIPT = 'RECEIPT',
    REFUND = 'REFUND'
}
export interface ReceiptPaymentDetails {
    receipt_id: string;
    payment: ReceiptPayment;
    customer_id: string;
}
export interface RefundReceiptPaymentDetails {
    receipt_id: string;
    payment: ReceiptPayment;
    customer_id: string;
    refund_for: string;
}
export interface LoanPaymentDetails {
    borrower_id: string;
    borrower_type: EntityTypes;
    borrower_loan_id: string;
}
export interface DebtPaymentDetails {
    customer_id: string;
    customer_debt_id: string;
}
export type PaymentDetails = LoanPaymentDetails | DebtPaymentDetails | ReceiptPaymentDetails | RefundReceiptPaymentDetails;
export interface CommonPaymentAttributes {
    amount: number;
    reason: PaymentReason;
    employee: CommonEmployeeAsCreatorAttributes;
    note: string;
    payer_id: string;
    payer_type: EntityTypes;
    store_id: string;
}
export interface Allocation extends Timestamps {
    allocation_entity_type: EntityTypes;
    allocation_entity_id: string;
    amount: number;
}
export interface Payment extends Timestamps, CommonPaymentAttributes {
    id: string;
    payment_details: PaymentDetails;
    owner_id: string;
    owner_type: EntityTypes;
    allocations: Allocation[];
}
export interface PaymentsPage extends Paginated {
    payments: Payment[];
}
export interface CreateLoanPaymentDto extends CommonPaymentAttributes {
    borrower_loan_id: string;
}
export interface CreateDebtPaymentDto extends CommonPaymentAttributes {
    customer_debt_id: string;
}
export interface CreateReceiptPaymentDto extends CommonPaymentAttributes, ReceiptPaymentDetails {
}
export interface CreateRefundReceiptPaymentDto extends CommonPaymentAttributes, RefundReceiptPaymentDetails {
}
export interface PaymentDBItem extends Payment {
    PK: string; // <owner_type>#<owner_id>
    SK: string; // PAYMENT#<id>
    GSI1PK: string; // <reason>
    GSI1SK: string; // PAYMENT#<id>
    GSI2PK: string; // <creator_type>#<creator_id>
    GSI2SK: string; // PAYMENT#DATE#<created_at>
    GSI3PK: string; // <owner_type>#<owner_id>
    GSI3SK: string; // PAYMENT#DATE#<created_at>
    GSI4PK: string; // <owner_type>#<owner_id>
    GSI4SK: string; // <reason>#PAYMENT#<id>
    GSI5PK: string; // PAYMENT#<id>
    GSI5SK: string; // PAYMENT#<id>
    GSI6PK: string; // <payer_type>#<payer_id>
    GSI6SK: string; // PAYMENT#DATE#<created_at>
    GSI7PK: string; // <entity_type>#<entity_id>
    GSI7SK: string; // PAYMENT#DATE#<created_at>
    EntityIndexPK: string; // PAYMENT
    EntityIndexSK: string; // PAYMENT#DATE#<created_at>
}
export interface GetPaymentByPayerDto extends PaginationParams {
    payer_id: string;
    payer_type: EntityTypes;
}
export interface GetPaymentsByPayerAndDateRangeDto extends PaginationParams {
    payer_id: string;
    payer_type: EntityTypes;
    from: string;
    to: string;
}
export interface GetPaymentsByCreatorDto extends PaginationParams {
    creator_id: string;
    creator_type: EntityTypes;
}
export interface GetPaymentsByOwnerDto extends PaginationParams {
    owner_id: string;
    owner_type: EntityTypes;
}
export interface GetPaymentByIdDto {
    payment_id: string;
}
export interface GetPaymentsByEntityDto extends PaginationParams {
    entity_id: string;
    entity_type: EntityTypes;
}
export interface GetPaymentsByEntityAndDateRangeDto extends PaginationParams, DateRange {
    entity_id: string;
    entity_type: EntityTypes;
}
export interface GetPaymentsByReasonDto extends PaginationParams {
    reason: PaymentReason;
}
export interface GetPaymentsByOwnerAndReasonDto extends GetPaymentsByOwnerDto {
    reason: PaymentReason;
}
export interface GetPaymentsByOwnerAndDateRangeDto extends GetPaymentsByOwnerDto, DateRange {
}
export interface GetPaymentsByCreatorAndDateRangeDto extends GetPaymentsByCreatorDto, DateRange {
}
export interface GetPaymentsByDateRangeDto extends PaginationParams, DateRange {
}
export interface UpdatePaymentAllocations extends CommonEmployeeAsCreatorAttributes {
    allocations: Allocation[];
    payment_id: string;
}

export interface PosDevice extends Timestamps {
    id: string;
    name: string;
    store_id: string;
    activated: boolean;
    merchant_id: string;
    prefix: string;
    mac_address?: string;
    current_user_id?: string;
}
export interface CreatePosDevice {
    name: string;
    store_id: string;
    activated: boolean;
    merchant_id: string;
    prefix: string;
    mac_address?: string;
}
export interface UpdatePosDevice {
    name?: string;
    activated?: boolean;
    prefix?: string;
    mac_address?: string;
    merchant_id: string;
    current_user_id?: string;
    id: string;
}
export interface GetPosDevices {
    store_id: string;
    limit?: number;
    cursor?: string;
}
export interface PosDeviceDBItem extends PosDevice {
    PK: string; // MERCHANT#{merchant_id}#ENTITY_TYPE#POS_DEVICE
    SK: string; // POS_DEVICE#<id>
    GSI1PK: string; // STORE#<store_id>
    GSI1SK: string; // POS_DEVICE#<id>
    EntityIndexPK: string; // POS_DEVICE
    EntityIndexSK: string; // MERCHANT#{merchant_id}#STORE#DATE#{created_at}
}
export interface PosDevicePage {
    pos_devices: PosDevice[];
    count: number;
    cursor?: string;
}

export interface ProductNameOrUnitUpdatedEvent {
    bus_name: string;
    source: ShopAndSmileEventSource;
    detail_type: ProductChangeEvents.PRODUCT_NAME_UPDATED | ProductChangeEvents.PRODUCT_UNIT_UPDATED;
    detail: ProductNameOrUnitUpdatedEventDetail;
}
interface ProductNameOrUnitUpdatedEventDetail {
    filter?: Record<string, any>;
    data: ProductNameOrUnitUpdatedEventDetailData;
    metadata: ProductNameOrUnitUpdatedEventDetailMetadata;
}
export interface ProductNameOrUnitUpdatedEventDetailData {
    product: Product;
}
export interface ProductNameOrUnitUpdatedEventDetailMetadata extends ShopAndSmileEventMetadata {
}
export interface QuantityInUnitPackageUpdatedEvent {
    bus_name: string;
    source: ShopAndSmileEventSource;
    detail_type: ProductChangeEvents.PRODUCT_QUANTITY_IN_UNIT_PACKAGE_UPDATED;
    detail: QuantityInUnitPackageUpdatedEventDetail;
}
export interface QuantityInUnitPackageUpdatedEventDetail {
    filter?: Record<string, any>;
    data: QuantityInUnitPackageUpdatedEventDetailData;
    metadata: QuantityInUnitPackageUpdatedEventDetailMetadata;
}
export interface QuantityInUnitPackageUpdatedEventDetailData {
    parentProduct: Product;
    childProducts: Product[];
    store: Store;
}
export interface QuantityInUnitPackageUpdatedEventDetailMetadata extends ShopAndSmileEventMetadata {
}
export interface StoreProductCostUpdatedEvent {
    bus_name: string;
    source: ShopAndSmileEventSource;
    detail_type: StoreProductChangeEvents.STORE_PRODUCT_COST_UPDATED;
    detail: StoreProductCostUpdatedEventDetail;
}
interface StoreProductCostUpdatedEventDetail {
    filter?: Record<string, any>;
    data: StoreProductCostUpdatedEventDetailData;
    metadata: StoreProductCostUpdatedEventDetailMetadata;
}
// ts-to-zod fails to generate the correct schema for the StoreProduct. Thus it will be done independently.
export interface StoreProductCostUpdatedEventDetailData {
    parentStoreProduct: any;
}
export interface StoreProductCostUpdatedEventDetailMetadata extends ShopAndSmileEventMetadata {
}

export interface ProductFraction extends Timestamps {
    parent_id: string;
    fraction_id: string;
    fraction: number;
    fraction_name: string;
    parent_name: string;
    merchant_id: string;
}
export type StoreProductFraction = StoreProduct & ProductFraction;
export interface Product extends Timestamps {
    id: string;
    name: string;
    description: string;
    image: string;
    barcode: number;
    quantity_in_unit_package: number;
    global_sku: number;
    weight_kg: number;
    unit: string;
    category_id?: string;
    merchant_id: string;
    fraction: number;
    parent_id: string;
    is_parent: boolean;
    product_fractions: ProductFraction[];
    taxes: string[] | Tax[];
    version: number;
}
export interface StoreProductAttributes extends Timestamps {
    id: string;
    store_sku: string;
    store_id: string;
    inventory: number;
    is_available_for_sale: boolean;
    is_sold_by_range: boolean;
    is_manually_controlled: boolean;
    should_display_cost_on_pos: boolean;
    is_sold_by_weight: boolean;
    is_sold_online: boolean;
    is_available_in_store: boolean;
    loyalty_programs: string[] | LoyaltyProgram[];
    price: number;
    cost: number;
    low_stock_threshold: number;
    store_prefix: string;
    is_parent: boolean;
    merchant_id: string;
    version: number;
    store_product_fractions: StoreProductFraction[];
    max_selling_price: number;
    min_selling_price: number;
}
export type StoreProduct = Product & StoreProductAttributes;
export interface GetProducts {
    merchant_id: string;
    is_parent?: boolean;
    cursor?: string;
    limit?: number;
}
export interface CreateProduct {
    creator_id: string;
    name: string;
    description: string;
    image: string;
    barcode: number;
    quantity_in_unit_package: number;
    weight_kg: number;
    unit: string;
    category_id?: string;
    merchant_id: string;
    taxes: string[];
}
export interface UpdateProduct {
    id: string;
    merchant_id: string;
    updator_id: string;
    name?: string;
    description?: string;
    image?: string;
    barcode?: number;
    quantity_in_unit_package?: number;
    global_sku?: number;
    weight_kg?: number;
    unit?: string;
    category_id?: string;
    add_taxes?: string[];
    remove_taxes?: string[];
}
export interface GetStoreProducts {
    store_id: string;
    is_parent?: boolean;
    cursor?: string;
    limit?: number;
}
export interface CreateStoreProduct {
    creator_id: string;
    product_id: string;
    store_id: string;
    inventory: number;
    is_available_for_sale: boolean;
    is_sold_by_range: boolean;
    max_selling_price: number;
    min_selling_price: number;
    is_manually_controlled: boolean;
    is_sold_by_weight: boolean;
    is_sold_online: boolean;
    loyalty_programs: string[];
    price: number;
    cost: number;
    low_stock_threshold: number;
    merchant_id: string;
}
export enum StoreProductChangeEvents {
    STORE_PRODUCT_SOLD_ONLINE_UPDATED = 'STORE_PRODUCT_SOLD_ONLINE_UPDATED',
    STORE_PRODUCT_DISPLAY_COST_ON_POS_UPDATED = 'STORE_PRODUCT_DISPLAY_COST_ON_POS_UPDATED',
    STORE_PRODUCT_CREATED = 'STORE_PRODUCT_CREATED',
    STORE_SKU_UPDATED = 'STORE_SKU_UPDATED',
    STORE_PRODUCT_IS_AVAILABLE_IN_STORE_UPDATED = 'STORE_PRODUCT_IS_AVAILABLE_IN_STORE_UPDATED',
    STORE_PRODUCT_SOLD_BY_WEIGHT_UPDATED = 'STORE_PRODUCT_SOLD_BY_WEIGHT_UPDATED',
    STORE_PRODUCT_INVENTORY_UPDATED = 'STORE_PRODUCT_INVENTORY_UPDATED',
    STORE_PRODUCT_PRICE_UPDATED = 'STORE_PRODUCT_PRICE_UPDATED',
    STORE_PRODUCT_COST_UPDATED = 'STORE_PRODUCT_COST_UPDATED',
    STORE_PRODUCT_LOW_STOCK_THRESHOLD_UPDATED = 'STORE_PRODUCT_LOW_STOCK_THRESHOLD_UPDATED',
    STORE_PRODUCT_IS_AVAILABLE_FOR_SALE_UPDATED = 'STORE_PRODUCT_IS_AVAILABLE_FOR_SALE_UPDATED',
    STORE_PRODUCT_IS_SOLD_BY_RANGE_UPDATED = 'STORE_PRODUCT_IS_SOLD_BY_RANGE_UPDATED',
    STORE_PRODUCT_IS_SOLD_BY_WEIGHT_UPDATED = 'STORE_PRODUCT_IS_SOLD_BY_WEIGHT_UPDATED',
    STORE_PRODUCT_IS_SOLD_ONLINE_UPDATED = 'STORE_PRODUCT_IS_SOLD_ONLINE_UPDATED',
    STORE_PRODUCT_MANUAL_CONTROL_UPDATED = 'STORE_PRODUCT_MANUAL_CONTROL_UPDATED',
    STORE_PRODUCT_MAX_SELLING_PRICE_UPDATED = 'STORE_PRODUCT_MAX_SELLING_PRICE_UPDATED',
    STORE_PRODUCT_MIN_SELLING_PRICE_UPDATED = 'STORE_PRODUCT_MIN_SELLING_PRICE_UPDATED',
    STORE_PRODUCT_LOYALTY_PROGRAMS_UPDATED = 'STORE_PRODUCT_LOYALTY_PROGRAMS_UPDATED',
    STORE_PRODUCT_DELETED = 'STORE_PRODUCT_DELETED',
    STORE_PREFIX_UPDATED = 'STORE_PREFIX_UPDATED',
    STORE_PRODUCT_FRACTIONS_UPDATED = 'STORE_PRODUCT_FRACTIONS_UPDATED'
}
export interface UpdateStoreProduct {
    id: string;
    store_id: string;
    merchant_id: string;
    updator_id: string;
    inventory?: number;
    is_available_for_sale?: boolean;
    is_sold_by_range?: boolean;
    max_selling_price?: number;
    min_selling_price?: number;
    is_manually_controlled?: boolean;
    is_sold_by_weight?: boolean;
    is_sold_online?: boolean;
    add_loyalty_programs?: string[];
    remove_loyalty_programs?: string[];
    price?: number;
    cost?: number;
    low_stock_threshold?: number;
    is_available_in_store?: boolean;
    should_display_cost_on_pos?: boolean;
}
export interface UpdateStoreProductInventory {
    id: string;
    store_id: string;
    merchant_id: string;
    source_id: string;
    source_type: EntityTypes;
    inventory: number;
}
export interface StoreProductPage {
    products: StoreProduct[];
    cursor?: string;
    count: number;
}
export interface ProductPage {
    products: Product[];
    cursor?: string;
    count: number;
}
export interface ProductFractionPage {
    fractions: ProductFraction[];
    cursor?: string;
    count: number;
}
export type StoreProductDBItem = StoreProductAttributes & {
    PK: string; // PK = `PRODUCT#${product_id}`
    SK: string; // SK = `STORE#${store_id}`
    GSI1PK: string; // GSI1PK = `STORE#${store_id}`
    GSI1SK: string; // GSI1SK = `PRODUCT#${product_id}`
    GSI2PK: string; // GSI2PK = `IS_PARENT#${is_parent}`
    GSI2SK: string; // GSI2SK = `STORE#${id}#PRODUCT#${id}`
    EntityIndexPK: string; // EntityIndexPK = `STORE_PRODUCT`
    EntityIndexSK: string; // EntityIndexSK = `PRODUCT#${product_id}#STORE#${store_id}`
};
export interface ProductDBItem extends Product {
    PK: string; // PK = `PRODUCT#${id}`
    SK: string; // SK = `PRODUCT#${id}`
    GSI1PK: string; // GSI1PK = `MERCHANT#${id}`
    GSI1SK: string; // GSI1SK = `PRODUCT#${id}`
    GSI2PK: string; // GSI2PK = `IS_PARENT#${is_parent}`
    GSI2SK: string; // GSI2SK = `MERCHANT#${id}#PRODUCT#${id}`
    EntityIndexPK: string; // EntityIndexPK = `PRODUCT`
    EntityIndexSK: string; // EntityIndexSK = `MERCHANT#${merchant_id}#DATE#${createdAt}`
}
export interface ProductFractionDBItem extends ProductFraction {
    PK: string; // PK = `PRODUCT#${product_id}`
    SK: string; // SK = `PRODUCT_FRACTION#${id}`
    GSI1PK: string; // GSI1PK = `MERCHANT#${id}`
    GSI1SK: string; // GSI1SK = `PRODUCT_FRACTION#${id}`
    GSI2PK: string; // GSI2PK = `PRODUCT_FRACTION#${id}`
    GSI2SK: string; // GSI2SK = `PRODUCT#${product_id}`
    EntityIndexPK: string; // EntityIndexPK = `PRODUCT_FRACTION`
    EntityIndexSK: string; // EntityIndexSK = `PRODUCT#${product_id}#PRODUCT_FRACTION#${id}`
}
export interface GetProductFractions {
    parent_id?: string;
    fraction_id?: string;
    cursor?: string;
    limit?: number;
}
export interface CreateProductFraction {
    fraction_id: string;
    parent_id: string;
    merchant_id: string;
    creator_id: string;
}
export interface UpdateProductFraction {
    id: string;
    parent_id?: string;
    fraction_name?: string;
    parent_name?: string;
    merchant_id: string;
    updator_id: string;
}
export interface SKU extends Timestamps {
    global_sku: number;
    merchant_id: string;
    unique_by_merchant: string;
    product_id: string;
}
export interface SKUDBItem extends SKU {
    // PK = MERCHANT#{merchant_id}
    // SK = GLOBAL_SKU#{sku}
    // GSI1PK = GLOBAL_SKU#{sku}
    // GSI1SK = MERCHANT#{merchant_id}
    // GSI2PK = GLOBAL_SKU#{sku}
    // GSI2SK = PRODUCT#{product_id}
    // GSI3PK = unique_by_merchant
    // GSI3SK = GLOBAL_SKU#{sku}
    // EntityIndexPK = GLOBAL_SKU
    // EntityIndexSK = MERCHANT#{merchant_id}#PRODUCT#{product_id}#GLCOBAL_SKU#{sku}
    PK: string;
    SK: string;
    GSI1PK: string;
    GSI1SK: string;
    GSI2PK: string;
    GSI2SK: string;
    GSI3PK: string;
    GSI3SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
}
export interface ChangeEvent {
    changes: ChangedAttributes;
    event: ProductChangeEvents | StoreProductChangeEvents;
}
export enum ProductChangeEvents {
    PRODUCT_CONVERTED_TO_PARENT = 'PRODUCT_CONVERTED_TO_PARENT',
    PRODUCT_CONVERTED_TO_CHILD = 'PRODUCT_CONVERTED_TO_CHILD',
    PRODUCT_CREATED = 'PRODUCT_CREATED',
    PRODUCT_NAME_UPDATED = 'PRODUCT_NAME_UPDATED',
    PRODUCT_DESCRIPTION_UPDATED = 'PRODUCT_DESCRIPTION_UPDATED',
    PRODUCT_IMAGE_UPDATED = 'PRODUCT_IMAGE_UPDATED',
    PRODUCT_BARCODE_UPDATED = 'PRODUCT_BARCODE_UPDATED',
    PRODUCT_QUANTITY_IN_UNIT_PACKAGE_UPDATED = 'PRODUCT_QUANTITY_IN_UNIT_PACKAGE_UPDATED',
    PRODUCT_GLOBAL_SKU_UPDATED = 'PRODUCT_GLOBAL_SKU_UPDATED',
    PRODUCT_WEIGHT_KG_UPDATED = 'PRODUCT_WEIGHT_KG_UPDATED',
    PRODUCT_UNIT_UPDATED = 'PRODUCT_UNIT_UPDATED',
    PRODUCT_CATEGORY_UPDATED = 'PRODUCT_CATEGORY_UPDATED',
    PRODUCT_FRACTION_UPDATED = 'PRODUCT_FRACTION_UPDATED',
    PRODUCT_PARENT_UPDATED = 'PRODUCT_PARENT_UPDATED',
    PRODUCT_TAXES_UPDATED = 'PRODUCT_TAXES_UPDATED',
    PRODUCT_DELETED = 'PRODUCT_DELETED',
    PRODUCT_FRACTIONS_UPDATED = 'PRODUCT_FRACTIONS_UPDATED',
    PRODUCT_DELETED_AT_UPDATED = 'PRODUCT_DELETED_AT_UPDATED',
    PRODUCT_CATEGORY_ID_UPDATED = 'PRODUCT_CATEGORY_ID_UPDATED'
}
export interface ProductChange extends Timestamps {
    event: ProductChangeEvents;
    version: string;
    updator_id: string;
    changed_attributes: ChangedAttributes;
    change_reason: string;
    id: string;
    merchant_id: string;
    updator_name: string;
}
export interface ProductChangeDBItem extends ProductChange {
    // PK = `PRODUCT#${id}`
    // SK = `PRODUCT_VERSION#${padded_version}`
    // GSI1PK = `MERCHANT#${merchant_id}`
    // GSI1SK = `PRODUCT_CHANGE#${id}#DATE#${createdAt}`
    // GSI2PK = `USER#${updator_id}`
    // GSI2SK = `PRODUCT_CHANGE#${id}#DATE#${createdAt}`
    // GSI3PK = `MERCHANT#${merchant_id}#EVENT#${event}`
    // GSI3SK = `PRODUCT_CHANGE#${id}#DATE#${createdAt}`
    // GSI4PK = `MERCHANT#${merchant_id}#EVENT#${event}`
    // GSI4SK = `PRODUCT_CHANGE#DATE#${createdAt}`
    // GSI5PK = `USER#${updator_id}`
    // GSI5SK = `PRODUCT_CHANGE#DATE#${createdAt}`
    // GSI6PK = `USER#${updator_id}#EVENT#${event}`
    // GSI6SK = `PRODUCT_CHANGE#${id}#DATE#${createdAt}`
    // EntityIndexPK = `PRODUCT_CHANGE`
    // EntityIndexSK = `MERCHANT#${merchant_id}#DATE#${createdAt}`
    PK: string;
    SK: string;
    GSI1PK: string;
    GSI1SK: string;
    GSI2PK: string;
    GSI2SK: string;
    GSI3PK: string;
    GSI3SK: string;
    GSI4PK: string;
    GSI4SK: string;
    GSI5PK: string;
    GSI5SK: string;
    GSI6PK: string;
    GSI6SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
}
export interface ProductChangesPage {
    changes: ProductChange[];
    cursor?: string;
    count: number;
}
export interface GetProductChanges {
    /**
     * Query all product changes made by a specific user.
     *
     * **How to Query:**
     * - **Index:** GSI2
     * - **GSI2PK:** `USER#${updator_id}`
     * - **GSI2SK:** starts with `PRODUCT_CHANGE#`
     */
    by_user?: boolean;
    /**
     * Query all product changes made by an user within a date range.
     *
     * **How to Query:**
     * - **Index:** GSI5
     * - **GSI5PK:** `USER#${updator_id}`
     * - **GSI5SK Range:** Between `PRODUCT_CHANGE#DATE#${start_date}` and `PRODUCT_CHANGE#DATE#${end_date}`
     */
    by_user_and_date_range?: boolean;
    /**
     * Query By user and product
     * - **Index:** GSI2
     * - **GSI6PK:** `USER#${updator_id}`
     * - **GSI6SK:** starts with `PRODUCT_CHANGE#${product_id}`
     */
    by_user_and_product?: boolean;
    /**
     * Query By user and product within a date range
     * - **Index:** GSI2
     * - **GSI6PK:** `USER#${updator_id}`
     * - **GSI6SK Range:** Between `PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
     * - **Note:** Requires `start_date` and `end_date`
     * - **Note:** Requires `updator_id`
     */
    by_user_product_and_date_range?: boolean;
    /**
     * Query By user and event type
     * - **Index:** GSI7
     * - **GSI7PK:** `USER#${updator_id}#EVENT#${event}`
     * - **GSI7SK:** starts with `PRODUCT_CHANGE#`
     */
    by_user_and_event?: boolean;
    /**
     * Query By user and event type and product within a date range
     * - **Index:** GSI7
     * - **GSI7PK:** `USER#${updator_id}#EVENT#${event}`
     * - **GSI7SK Range:** Between `PRODUCT_CHANGE#${id}#DATE#${start_date}` and `PRODUCT_CHANGE#DATE#${end_date}`
     * - **Note:** Requires `start_date` and `end_date`
     * - **Note:** Requires `updator_id`
     */
    by_user_and_product_and_event_and_date_range?: boolean;
    /**
     * Query all versions of a specific product.
     *
     * **How to Query:**
     * - **Table:** Main Table
     * - **Partition Key (PK):** `PRODUCT#${product_id}`
     * - **Sort Key (SK):** `PRODUCT_VERSION#${padded_version}`
     */
    by_product?: boolean;
    /**
     * Query all product changes for a specific merchant.
     *
     * **How to Query:**
     * - **Index:** GSI1
     * - **GSI1PK:** `MERCHANT#${merchant_id}`
     * - **GSI1SK:** starts with "PRODUCT_CHANGE#"
     */
    by_merchant?: boolean;
    /**
     * Query all changes for a specific product within a merchant.
     *
     * **How to Query:**
     * - **Index:** GSI1
     * - **GSI1PK:** `MERCHANT#${merchant_id}`
     * - **GSI1SK:** starts with 'PRODUCT_CHANGE#${product_id}'
     */
    by_merchant_and_product?: boolean;
    /**
     * Query all product changes for a merchant within a date range.
     *
     * **How to Query:**
     * - **Index:** EntityIndex
     * - **EntityIndexPK:** `PRODUCT_CHANGE`
     * - **EntityIndexSK Range:** Between `MERCHANT#${id}#DATE#${start_date}` and `MERCHANT#${id}#DATE#${end_date}`
     */
    by_merchant_and_date_range?: boolean;
    /**
     * Query all changes for a specific product under a merchant within a date range.
     *
     * **How to Query:**
     * - **Index:** GSI1
     * - **GSI1PK:** `MERCHANT#${merchant_id}`
     * - **GSI1SK Range:** Between `PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
     */
    by_merchant_product_and_date_range?: boolean;
    /**
     * Query all product changes of a specific event type for a merchant.
     *
     * **How to Query:**
     * - **Index:** GSI3
     * - **GSI3PK:** `MERCHANT#${merchant_id}#EVENT#${event}`
     * - **GSI3SK:** starts with `PRODUCT_CHANGE#`
     */
    by_merchant_and_event?: boolean;
    /**
     * Query all product changes of a specific event type for a merchant within a date range.
     *
     * **How to Query:**
     * - **Index:** GSI4
     * - **GSI4PK:** `MERCHANT#${merchant_id}#EVENT#${event}`
     * - **GSI4SK Range:** Between `PRODUCT_CHANGE#DATE#${start_date}` and `PRODUCT_CHANGE#DATE#${end_date}`
     */
    by_merchant_event_and_date_range?: boolean;
    /**
     * Query all changes by merchant and event type and product
     * - **Index:** GSI3
     * - **GSI3PK:** `MERCHANT#${merchant_id}#EVENT#${event}`
     * - **GSI3SK:** starts with `PRODUCT_CHANGE#${product_id}`
     */
    by_merchant_event_and_product?: boolean;
    /**
     * Query all changes by merchant and event type and product within a date range
     * - **Index:** GSI3
     * - **GSI3PK:** `MERCHANT#${merchant_id}#EVENT#${event}`
     * - **GSI3SK Range:** Between `PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
     */
    by_merchant_event_product_and_date_range?: boolean;
    /**
     * Query all product changes by product and version range.
     *
     * **How to Query:**
     * - **Table:** Main Table
     * - **PK:** `PRODUCT#${product_id}`
     * - **SK Range:** Between `PRODUCT_VERSION#${start_version}` and `PRODUCT_VERSION#${end_version}`
     * - **Note:** Requires that `padded_version`
     */
    by_product_and_version_range?: boolean;
    // Query parameters
    limit?: number;
    cursor?: string;
    /**
     * Start date for date range filtering.
     * - **Format:** ISO 8601 string (e.g., '2023-09-24T12:34:56Z')
     */
    start_date?: string;
    /**
     * End date for date range filtering.
     * - **Format:** ISO 8601 string
     */
    end_date?: string;
    /**
     * Merchant ID.
     * - Required for queries involving merchants.
     */
    merchant_id: string;
    /**
     * Product ID.
     * - Required for queries involving specific products.
     */
    product_id?: string;
    /**
     * Event type.
     * - Enum of possible product change events.
     */
    event?: ProductChangeEvents;
    /**
     * user (updator) ID.
     * - Required for queries involving specific users.
     */
    updator_id?: string;
    sort_order?: 'ASC' | 'DESC';
    /**
     * Product version.
     * - Required for queries involving specific product versions.
     */
    version?: number;
    /**
     * Start version for version range filtering.
     */
    start_version?: string;
    /**
     * End version for version range filtering.
     */
    end_version?: string;
}
export interface GetStoreProductChanges {
    /**
     * Query all store_product changes made by a specific user.
     *
     * **How to Query:**
     * - **Index:** GSI2
     * - **GSI2PK:** `USER#${updator_id}`
     * - **GSI2SK:** starts with `STORE_PRODUCT_CHANGE#`
     */
    by_user?: boolean;
    /**
     * Query all store_product changes made by an user within a date range.
     *
     * **How to Query:**
     * - **Index:** GSI5
     * - **GSI5PK:** `USER#${updator_id}`
     * - **GSI5SK Range:** Between `STORE_PRODUCT_CHANGE#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#DATE#${end_date}`
     */
    by_user_and_date_range?: boolean;
    /**
     * Query By user and store_product
     * - **Index:** GSI2
     * - **GSI6PK:** `USER#${updator_id}`
     * - **GSI6SK:** starts with `STORE_PRODUCT_CHANGE#${product_id}`
     */
    by_user_and_store_product?: boolean;
    /**
     * Query By user and store_product within a date range
     * - **Index:** GSI2
     * - **GSI6PK:** `USER#${updator_id}`
     * - **GSI6SK Range:** Between `STORE_PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
     * - **Note:** Requires `start_date` and `end_date`
     * - **Note:** Requires `updator_id`
     */
    by_user_store_product_and_date_range?: boolean;
    /**
     * Query By user and event type
     * - **Index:** GSI6
     * - **GSI6PK:** `USER#${updator_id}#EVENT#${event}`
     * - **GSI6SK:** starts with `STORE_PRODUCT_CHANGE#`
     */
    by_user_and_event?: boolean;
    /**
     * Query By user and event type and store_product
     * - **Index:** GSI6
     * - **GSI6PK:** `USER#${updator_id}#EVENT#${event}`
     * - **GSI6SK:** starts with `STORE_PRODUCT_CHANGE#${product_id}`
     */
    by_user_and_store_product_and_event?: boolean;
    /**
     * Query By user and event type and store_product within a date range
     * - **Index:** GSI6
     * - **GSI6PK:** `USER#${updator_id}#EVENT#${event}`
     * - **GSI6SK Range:** Between `STORE_PRODUCT_CHANGE#${id}#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#DATE#${end_date}`
     * - **Note:** Requires `start_date` and `end_date`
     * - **Note:** Requires `updator_id`
     */
    by_user_and_store_product_and_event_and_date_range?: boolean;
    /**
     * Query all versions of a specific store_product.
     *
     * **How to Query:**
     * - **Table:** Main Table
     * - **Partition Key (PK):** `PRODUCT#${product_id}#STORE#${store_id}`
     * - **Sort Key (SK):** starts with `STORE_PRODUCT_VERSION#`
     */
    by_store_product?: boolean;
    /**
     * Query all store_product changes for a specific store.
     *
     * **How to Query:**
     * - **Index:** GSI1
     * - **GSI1PK:** `STORE#${store_id}`
     * - **GSI1SK:** starts with "STORE_PRODUCT_CHANGE#"
     */
    by_store?: boolean;
    /**
     * Query all changes for a specific store_product within a store.
     *
     * **How to Query:**
     * - **Index:** GSI1
     * - **GSI1PK:** `STORE#${store_id}`
     * - **GSI1SK:** starts with 'STORE_PRODUCT_CHANGE#${product_id}'
     */
    by_store_and_store_product?: boolean;
    /**
     * Query all store_product changes for a store within a date range.
     *
     * **How to Query:**
     * - **Index:** EntityIndex
     * - **EntityIndexPK:** `STORE_PRODUCT_CHANGE`
     * - **EntityIndexSK Range:** Between `STORE#${id}#DATE#${start_date}` and `STORE#${id}#DATE#${end_date}`
     */
    by_store_and_date_range?: boolean;
    /**
     * Query all changes for a specific store_product under a store within a date range.
     *
     * **How to Query:**
     * - **Index:** GSI1
     * - **GSI1PK:** `STORE#${store_id}`
     * - **GSI1SK Range:** Between `STORE_PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
     */
    by_store_and_store_product_and_date_range?: boolean;
    /**
     * Query all store_product changes of a specific event type for a store.
     *
     * **How to Query:**
     * - **Index:** GSI3
     * - **GSI3PK:** `STORE#${store_id}#EVENT#${event}`
     * - **GSI3SK:** starts with `STORE_PRODUCT_CHANGE#`
     */
    by_store_and_event?: boolean;
    /**
     * Query all store_product changes of a specific event type for a store within a date range.
     *
     * **How to Query:**
     * - **Index:** GSI4
     * - **GSI4PK:** `STORE#${store_id}#EVENT#${event}`
     * - **GSI4SK Range:** Between `STORE_PRODUCT_CHANGE#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#DATE#${end_date}`
     */
    by_store_and_event_and_date_range?: boolean;
    /**
     * Query all changes by store and event type and store_product
     * - **Index:** GSI3
     * - **GSI3PK:** `STORE#${store_id}#EVENT#${event}`
     * - **GSI3SK:** starts with `STORE_PRODUCT_CHANGE#${product_id}`
     */
    by_store_event_and_store_product?: boolean;
    /**
     * Query all changes by store and event type and store_product within a date range
     * - **Index:** GSI3
     * - **GSI3PK:** `STORE#${store_id}#EVENT#${event}`
     * - **GSI3SK Range:** Between `STORE_PRODUCT_CHANGE#${product_id}#DATE#${start_date}` and `STORE_PRODUCT_CHANGE#${product_id}#DATE#${end_date}`
     */
    by_store_event_store_product_and_date_range?: boolean;
    /**
     * Query all store_product changes by store_product and version range.
     *
     * **How to Query:**
     * - **Table:** Main Table
     * - **PK:** `PRODUCT#${product_id}`
     * - **SK Range:** Between `PRODUCT_VERSION#${start_version}` and `PRODUCT_VERSION#${end_version}`
     * - **Note:** Requires that `padded_version`
     */
    by_store_product_and_version_range?: boolean;
    // Query parameters
    limit?: number;
    cursor?: string;
    /**
     * Start date for date range filtering.
     * - **Format:** ISO 8601 string (e.g., '2023-09-24T12:34:56Z')
     */
    start_date?: string;
    /**
     * End date for date range filtering.
     * - **Format:** ISO 8601 string
     */
    end_date?: string;
    /**
     * Merchant ID.
     * - Required for queries involving stores.
     */
    store_id?: string;
    /**
     * Product ID.
     * - Required for queries involving specific store_products.
     */
    product_id?: string;
    /**
     * Event type.
     * - Enum of possible store_product change events.
     */
    event?: ProductChangeEvents | StoreProductChangeEvents;
    /**
     * user (updator) ID.
     * - Required for queries involving specific users.
     */
    updator_id?: string;
    sort_order?: 'ASC' | 'DESC';
    /**
     * Product version.
     * - Required for queries involving specific store_product versions.
     */
    version?: number;
    /**
     * Start version for version range filtering.
     */
    start_version?: number;
    /**
     * End version for version range filtering.
     */
    end_version?: number;
}
export interface StoreProductChangesPage {
    changes: StoreProductChange[];
    cursor?: string;
    count: number;
}
export interface StoreProductChange extends Timestamps {
    event: StoreProductChangeEvents;
    version: number;
    updator_id: string;
    changed_attributes: ChangedAttributes;
    change_reason: string;
    id: string;
    store_id: string;
    merchant_id: string;
    updator_name: string;
}
export interface StoreProductChangeDBItem extends StoreProductChange {
    // PK = `PRODUCT#${product_id}#STORE#${store_id}`
    // SK = `STORE_PRODUCT_VERSION#${padded_version}`
    // GSI1PK = `STORE#${store_id}`
    // GSI1SK = `STORE_PRODUCT_CHANGE#${product_id}#DATE#${createdAt}`
    // GSI2PK = `USER#${updator_id}`
    // GSI2SK = `STORE_PRODUCT_CHANGE#${product_id}#DATE#${createdAt}`
    // GSI3PK = `STORE#${store_id}#EVENT#${event}`
    // GSI3SK = `STORE_PRODUCT_CHANGE#${product_id}#DATE#${createdAt}`
    // GSI4PK = `STORE#${store_id}#EVENT#${event}`
    // GSI4SK = `STORE_PRODUCT_CHANGE#DATE#${createdAt}`
    // GSI5PK = `USER#${updator_id}`
    // GSI5SK = `STORE_PRODUCT_CHANGE#DATE#${createdAt}`
    // GSI6PK = `USER#${updator_id}#EVENT#${event}`
    // GSI6SK = `STORE_PRODUCT_CHANGE#${product_id}#DATE#${createdAt}`
    // EntityIndexPK = `STORE_PRODUCT_CHANGE`
    // EntityIndexSK = `STORE#${store_id}#DATE#${createdAt}`
    PK: string;
    SK: string;
    GSI1PK: string;
    GSI1SK: string;
    GSI2PK: string;
    GSI2SK: string;
    GSI3PK: string;
    GSI3SK: string;
    GSI4PK: string;
    GSI4SK: string;
    GSI5PK: string;
    GSI5SK: string;
    GSI6PK: string;
    GSI6SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
}

export interface Promotion extends Timestamps {
    id: string;
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    promotion_type: PromotionTypes;
    promotion_value: number;
    promotion_code: string;
    promotion_status: PromotionStatus;
    store_id: string;
    merchant_id: string;
    product_id: string;
    image_url: string;
    is_manual: boolean;
}
export interface PromotionPage {
    promotions: Promotion[];
    cursor?: string;
    count: number;
}
export enum PromotionTypes {
    PERCENTAGE = 'percentage',
    FIXED = 'fixed'
}
export enum PromotionStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}
export interface CreatePromotionRequest {
    title: string;
    description: string;
    start_date: string;
    end_date: string;
    product_id: string;
    image_url: string;
    promotion_type: PromotionTypes;
    promotion_value: number;
    promotion_code: string;
    store_id: string;
    merchant_id: string;
}
export interface UpdatePromotionRequest {
    id: string;
    title?: string;
    description?: string;
    start_date?: string;
    end_date?: string;
    product_id?: string;
    image_url?: string;
    promotion_type?: PromotionTypes;
    promotion_value?: number;
    promotion_code?: string;
    promotion_status?: PromotionStatus;
    is_manual?: boolean;
    store_id: string;
    merchant_id: string;
}
export interface GetPromotionsRequest {
    limit?: number;
    cursor?: string;
    by_status?: boolean;
    by_start_date_range?: boolean;
    by_start_date_range_and_status?: boolean;
    by_end_date_range?: boolean;
    by_end_date_range_and_status?: boolean;
    by_item?: boolean;
    by_item_and_status?: boolean;
    item_id?: string;
    status?: PromotionStatus;
    start_date?: string;
    end_date?: string;
    merchant_id: string;
    store_id: string;
}
export interface PromotionDBItem extends Promotion {
    PK: string; // MERCHANT#<merchant_id#STORE#<store_id>#MISC#<YYMM>
    SK: string; // PROMOTION#<id>
    GSI1PK: string; // MERCHANT#<merchant_id#STORE#<store_id>#STATUS#<PromotionStatus>
    GSI1SK: string; // PROMOTION#START_DATE<start_date>
    GSI2PK: string; // MERCHANT#<merchant_id#STORE#<store_id>
    GSI2SK: string; // PROMOTION#START_DATE#<start_date>
    GSI3PK: string; // MERCHANT#<merchant_id#STORE#<store_id>
    GSI3SK: string; // PROMOTION#END_DATE#<end_date>
    GSI4PK: string; // ITEM#<id>
    GSI4SK: string; // PROMOTION#MERCHANT#<merchant_id#STORE#<store_id>#STATUS#<PromotionStatus>
    GSI5PK: string; // MERCHANT#<merchant_id#STORE#<store_id>#STATUS#<PromotionStatus>
    GSI5SK: string; // PROMOTION#END_DATE<end_date>
    EntityIndexPK: string;
    EntityIndexSK: string;
}
export interface PromotionDiscount {
    id: string;
    type: DiscountType;
    name: string;
    discount_amount: number;
    discount_percent: number;
    stores: string[];
    restricted_access: boolean;
    created_at: string; // ISO 8601 date string
    updated_at: string; // ISO 8601 date string
    deleted_at: string | null; // ISO 8601 date string or null if not deleted
}
export interface UpdatePromotionDiscount {
    id: string;
    type: DiscountType;
    name: string;
    discount_amount: number;
    discount_percent: number;
    stores: string[];
    restricted_access: boolean;
}
export interface CreatePromotionDiscount {
    type: DiscountType;
    name: string;
    discount_amount: number;
    discount_percent: number;
    stores: string[];
    restricted_access: boolean;
}
export interface PromotionDiscountPage {
    discounts: PromotionDiscount[];
    cursor: string;
}

export enum PurchaseStatus {
    DRAFT = 'DRAFT',
    CLOSED = 'CLOSED',
    PENDING = 'PENDING',
    CANCELLED = 'CANCELLED'
}
export enum PurchaseOrderPaymentStatus {
    UNPAID = 'UNPAID',
    PAID = 'PAID',
    PARTIALLY_PAID = 'PARTIALLY_PAID'
}
export interface PurchaseOrderPayment extends Timestamps {
    id: string;
    purchase_order_id: string;
    amount_paid: number;
    payment_location: string;
    merchant_id: string;
    store_id: string;
    supplier_id: string;
    amount_due: number;
    total_paid: number;
    total_amount: number;
    creator_id: string;
    creator_name: string;
}
export interface CreatePurchaseOrderPayment {
    amount_paid: number;
    payment_location: string;
    merchant_id: string;
    purchase_order_id: string;
    store_id: string;
    creator_id: string;
}
export interface GetAllPurchaseOrderPayments {
    by_merchant?: boolean;
    by_supplier?: boolean;
    by_date?: string;
    by_purchase_order?: boolean;
    by_store?: boolean;
    supplier_id?: string;
    cursor?: string;
    limit?: number;
    merchant_id?: string;
    purchase_order_id?: string;
    store_id?: string;
    start_date?: string;
    end_date?: string;
}
export interface PurchaseOrderPaymentSummaries {
    merchants: PurchaseOrderPaymentMerchantSummary[];
    stores: PurchaseOrderPaymentStoreSummary[];
    suppliers: PurchaseOrderPaymentSupplierSummary[];
    purchase_orders: PurchaseOrderPaymentPurchaseOrderSummary[];
}
export interface PurchaseOrderPaymentSummary {
    total_money: number;
    total_due: number;
    total_paid: number;
    count: number;
}
export interface PurchaseOrderPaymentMerchantSummary extends PurchaseOrderPaymentSummary {
    merchant_id: string;
}
export interface PurchaseOrderPaymentSupplierSummary extends PurchaseOrderPaymentSummary {
    supplier_id: string;
}
export interface PurchaseOrderPaymentPurchaseOrderSummary extends PurchaseOrderPaymentSummary {
    purchase_order_id: string;
}
export interface PurchaseOrderPaymentStoreSummary extends PurchaseOrderPaymentSummary {
    store_id: string;
}
export interface PurchaseOrderPaymentPage {
    purchase_order_payments: PurchaseOrderPayment[];
    cursor: string | null | undefined;
    count: number;
    summary: PurchaseOrderPaymentSummaries | null | undefined;
}
export interface PurchaseOrder extends Timestamps {
    id: string;
    purchase_date: string;
    ordered_by: string;
    note: string;
    supplier_id: string;
    purchase_status: PurchaseStatus;
    total_cost: number;
    items: PurchaseOrderItem[];
    total_received: number;
    payment_status: PurchaseOrderPaymentStatus;
    is_draft: boolean;
    store_id: string;
    merchant_id: string;
    total_ordered: number;
    supplier_name: string;
}
export interface PurchaseOrderItem extends Timestamps {
    id: string;
    quantity: number;
    cost: number;
    received: number;
    name: string;
    in_stock: number;
    received_at?: string;
}
export interface UpdatePurchaseOrderItem {
    quantity: number;
    id: string;
}
export interface ReceivePurchaseOrderItem {
    quantity: number;
    id: string;
    cost: number;
}
export interface CreatePurchaseOrder {
    purchase_date: string;
    ordered_by: string;
    note: string;
    supplier_id: string;
    items: UpdatePurchaseOrderItem[];
    store_id: string;
    merchant_id: string;
}
export enum UpdatePurchaseOrderCommand {
    ADD = 'ADD',
    REMOVE = 'REMOVE',
    CANCEL = 'CANCEL',
    COMMENT = 'COMMENT'
}
export interface UpdatePurchaseOrder {
    store_id: string;
    id: string;
    items: UpdatePurchaseOrderItem[];
    command: UpdatePurchaseOrderCommand;
    updator_id: string;
    note?: string;
}
export interface ReceivePurchaseOrder {
    // purchase order id
    id: string;
    items: ReceivePurchaseOrderItem[];
    creator_id: string;
    store_id: string;
}
export interface PurchaseOrderPage {
    purchase_orders: PurchaseOrder[];
    cursor: string | null | undefined;
    count: number;
}
export interface GetAllPurchaseOrders {
    by_merchant?: boolean;
    by_supplier?: boolean;
    by_date?: boolean;
    by_payment_status?: boolean;
    by_purchase_status?: boolean;
    by_payment_status_and_date?: boolean;
    by_purchase_status_and_date?: boolean;
    by_store?: boolean;
    by_store_and_date?: boolean;
    supplier_id?: string;
    merchant_id: string;
    cursor?: string;
    purchase_status?: string;
    payment_status?: string;
    store_id?: string;
    start_date?: string;
    end_date?: string;
    limit?: number;
}
export interface GetPurchaseOrder {
    id: string;
}
export interface DeletePurchaseOrder {
    id: string;
}

export interface ReceiptCreatedEvent {
    bus_name: string;
    source: ShopAndSmileEventSource;
    detail_type: ReceiptEvents.SaleReceiptCreated | ReceiptEvents.RefundReceiptCreated;
    detail: ReceiptCreatedEventDetail;
}
export interface ReceiptCreatedEventDetail {
    filter?: Record<string, any>;
    data: ReceiptCreatedEventDetailData;
    metadata: ReceiptCreatedEventDetailMetadata;
}
export interface ReceiptCreatedEventDetailData {
    receipt: Receipt;
}
export interface ReceiptCreatedEventDetailMetadata extends ShopAndSmileEventMetadata {
}

export enum ReasonMissing {
    NA = 'NA',
    OUT_OF_STOCK = 'OUT_OF_STOCK',
    CANCELLED = 'CANCELLED'
}
export enum ReceiptStatus {
    CONFIRMED = 'CONFIRMED',
    UNCONFIRMED = 'UNCONFIRMED',
    AUTOCONFIRMED = 'AUTOCONFIRMED',
    REFUND = 'REFUND',
    MISSING = 'MISSING'
}
export interface MissingItem {
    quantity: number;
    reason: ReasonMissing;
    employee_id: string;
    employee_name: string;
    item_id: string;
}
export interface CartItem extends Timestamps {
    item_id: string;
    item_name: string;
    quantity: number;
    missing: MissingItem;
    is_confirmed: boolean;
}
export interface ConfirmedQuantityUpdate {
    quantity: number;
    updator: EmployeeSummary;
    item_id: string;
}
export interface ExtendedLineItem extends LineItem {
    missing: MissingItem;
    is_confirmed: boolean;
}
export interface ReceiptHistory {
    receipt_number: string;
    history_item: ReceiptHistoryItem[];
}
export enum ReceiptHistoryItemType {
    SALE = 'SALE',
    DEBT_PAYMENT = 'DEBT PAYMENT',
    REFUND = 'REFUND',
    UPDATE_CUSTOMER = 'CUSTOMER UPDATE',
    COMMENT = 'COMMENT'
}
export interface HistoryPaymentType {
    payment_type_id: string;
    payment_type_name: string;
    payment_type: PaymentMethodType;
    amount: number;
}
export interface SaleReceiptHistoryItem {
    history_item_type: ReceiptHistoryItemType.SALE;
    event: string;
    value: number;
    remaining_debt: number;
    payments: HistoryPaymentType[];
    comment?: string;
    date: string;
    receipt_number: string;
}
export interface DebtPaymentHistoryItem {
    history_item_type: ReceiptHistoryItemType.DEBT_PAYMENT;
    event: string;
    paid_amount: number;
    remaining_debt: number;
    payment_id: string;
    comment?: string;
    date: string;
    received_by: EmployeeSummary;
}
export interface RefundReceiptHistoryItem {
    history_item_type: ReceiptHistoryItemType.REFUND;
    event: string;
    refund_receipt_number: string;
    refunded_payments: HistoryPaymentType[];
    amount_refunded: number;
    comment?: string;
    remaining_debt: number;
    date: string;
    refunded_by: EmployeeSummary;
}
export interface ReceiptHistoryUpdateCustomerItem {
    history_item_type: ReceiptHistoryItemType.UPDATE_CUSTOMER;
    event: string;
    previous_customer_id: string;
    new_customer_id: string;
    date: string;
    updated_by: EmployeeSummary;
}
export interface ReceiptHistoryCommentItem {
    history_item_type: ReceiptHistoryItemType.COMMENT;
    event: string;
    comment: string;
    date: string;
    commented_by: EmployeeSummary;
}
//////// SHOP AND SMILE RECEIPT ///////////
export interface LineItem {
    id: string;
    item_id: string;
    item_name: string;
    sku: string;
    quantity: number;
    line_quantity: number;
    fraction: number;
    price: number;
    gross_total_money: number;
    total_money: number;
    cost: number;
    cost_total: number;
    line_note?: string;
    line_taxes: ReceiptTax[];
    total_discount: number;
    line_discounts: ReceiptDiscount[];
    confirmed_quantity: number;
    confirmed_quantity_traceback: ConfirmedQuantityTraceback[];
    missing: MissingItem;
    missing_quantity_traceback: MissingItemTraceback[];
    is_confirmed: boolean;
    is_sold_by_weight: boolean;
}
export interface ReceiptDiscount extends Discount {
    money_amount: number;
}
export interface ReceiptTax extends Tax {
    money_amount: number;
}
export interface Receipt extends Timestamps {
    receipt_number: string;
    note?: string;
    receipt_type: ReceiptType;
    refund_for?: string;
    order?: string;
    created_at: string;
    updated_at: string;
    source: string;
    cancelled_at?: any;
    total_money: number;
    total_tax: number;
    points_earned: number;
    points_deducted: number;
    points_balance: number;
    customer_id: string;
    total_discount: number;
    employee_id: string;
    store_id: string;
    pos_device_id: string;
    dining_option?: string;
    total_discounts: ReceiptDiscount[];
    total_taxes: ReceiptTax[];
    tip: number;
    surcharge: number;
    line_items: LineItem[];
    payments: ReceiptPayment[];
    associated_refunds: string[];
    total_receipt_items: number;
    confirmation_type: ConfirmationType;
    receipt_status: ReceiptStatus;
    comments: Comment[];
    client: Client;
    waiter?: EmployeeSummary;
    is_confirmable: boolean;
    confirmed_by?: EmployeeSummary;
    customer?: Customer;
    customer_name: string;
    employee_name: string;
    pos_device_name: string;
    customer_updated_traceback: CustomerUpdateTraceback[];
    has_debt: boolean;
    custom_receipt_number: string;
    failed_checks: ReceiptFailedChecks[];
    merchant_id: string;
    threshold: Threshold;
}
export interface ReceiptFailedChecks {
    note: string;
    check_type: any;
    created_at: string;
}
export interface ReceiptPayment extends Timestamps {
    payment_type_id: string;
    payment_type: PaymentMethodType;
    money_amount: number;
    note?: string;
}
export interface ReceiptDBItem extends Receipt {
    PK: string; // RECEIPT#<receipt_number>
    SK: string; // RECEIPT#<receipt_number>
    EntityIndexPK: string; // RECEIPT
    EntityIndexSK: string; // RECEIPT#DATE#<created_at>
    GSI1PK: string; // <receipt_status>
    GSI1SK: string; // RECEIPT#DATE#<date>
    GSI2PK: string; // <customer_id>
    GSI2SK: string; // RECEIPT#DATE#<date>
    GSI3PK: string; // STORE#<store_id>
    GSI3SK: string; // RECEIPT#DATE#<date>
    GSI4PK: string; // POS_DEVICE#<pos_device_id>
    GSI4SK: string; // RECEIPT#DATE#<date>
    GSI5PK: string; // POS_DEVICE#<id>
    GSI5SK: string; // RECEIPT#<custom_receipt_number>
    GSI6PK: string; // STORE#<store_id>#STATUS#<receipt_status>
    GSI6SK: string; // RECEIPT#DATE#<date>
    GSI7PK: string; // STORE#<store_id>#CONFIRMATION_TYPE#<confirmation_type>
    GSI7SK: string; // RECEIPT#DATE#<date>
}
export interface CreateReceiptHistoryItem {
    receiptHistory: ReceiptHistoryItem;
    id: string;
    receipt_number: string;
    created_at: string;
    merchant_id: string;
}
export interface UpdateReceiptItem extends Partial<ReceiptDBItem> {
    receipt_number: string;
}
export type ReceiptHistoryDbItem = ReceiptHistoryItem & {
    PK: string;
    SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
};
export type ReceiptHistoryItem = SaleReceiptHistoryItem | DebtPaymentHistoryItem | RefundReceiptHistoryItem | ReceiptHistoryUpdateCustomerItem | ReceiptHistoryCommentItem;
export interface Client extends Timestamps {
    id: string;
    name: string;
    phone_number: string;
    email?: string;
    address?: string;
    business_name?: string;
}
export interface Comment extends Timestamps {
    comment: string;
    employee_id: string;
    employee_name: string;
}
export enum ConfirmationType {
    SOFT = 'SOFT',
    HARD = 'HARD'
}
export interface ConfirmedQuantityTraceback extends Timestamps {
    quantity: number;
    confirmed_by: string;
    note: string;
}
export interface CustomerUpdateTraceback extends Timestamps {
    customer: string;
    updator: string;
    note: string;
}
export interface MissingItemTraceback extends Timestamps {
    quantity: number;
    reported_by: string;
    note: string;
}
export interface GetReceipts {
    cursor?: string;
    limit?: number;
    start_date?: string;
    end_date?: string;
    receipt_status?: ReceiptStatus;
    customer_id?: string;
    store_id?: string;
    confirmation_type?: ConfirmationType;
    pos_device_id?: string;
    by_status?: boolean;
    by_date_range?: boolean;
    by_date_range_and_status?: boolean;
    by_customer?: boolean;
    by_customer_and_date_range?: boolean;
    by_date_range_and_status_and_missing?: boolean;
    by_store?: boolean;
    by_store_and_date_range?: boolean;
    by_store_and_status?: boolean;
    by_store_and_date_range_and_status?: boolean;
    by_store_and_confirmation_type?: boolean;
    by_pos_device?: boolean;
    sort_order?: SortOrder;
}
export interface ReceiptsPage {
    receipts: Receipt[];
    cursor?: string;
    count: number;
}
export enum UpdateReceiptCommand {
    CONFIRM_SOFT_RECEIPT = 'CONFIRM_SOFT_RECEIPT',
    CONFIRM_HARD_RECEIPT = 'CONFIRM_HARD_RECEIPT',
    ADD_COMMENT = 'ADD_COMMENT',
    UPDATE_CONFIRMED_QUANTITY = 'UPDATE_CONFIRMED_QUANTITY',
    ADD_MISSING_QUANTITY = 'ADD_MISSING_QUANTITY',
    DELETE_MISSING_QUANTITY = 'DELETE_MISSING_QUANTITY',
    SET_RECEIPT_TO_MISSING = 'SET_RECEIPT_TO_MISSING',
    ADD_WAITER = 'ADD_WAITER',
    ADD_CUSTOMER = 'ADD_CUSTOMER'
}
export interface UpdateReceipt {
    receipt_number: string;
    merchant_id: string;
    command: UpdateReceiptCommand;
    waiter?: EmployeeSummary;
    comments?: Comment[];
    receipt_status?: {
        receipt_status: ReceiptStatus;
        updator: EmployeeSummary;
    };
    customer?: {
        customer_id: string;
        updator_id: string;
    };
}
export interface UpdateLineItem {
    receipt_number: string;
    confirmed_quantity?: ConfirmedQuantityUpdate[];
    missing?: MissingItem[];
    command: UpdateReceiptCommand;
    merchant_id: string;
}
export interface ConfirmReceipt {
    receipt_number: string;
    updator: EmployeeSummary;
    confirmation_type: ConfirmationType;
    command: UpdateReceiptCommand;
    merchant_id: string;
}
export interface Threshold extends Timestamps {
    min_quantity: number;
    min_total_money: number;
    updated_by: EmployeeSummary[];
    store_id: string;
    merchant_id: string;
}
export interface ThresholdItem extends Timestamps, Threshold {
    PK: string;
    SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
}
export interface CreateThreshold {
    min_quantity: number;
    min_total_money: number;
    updated_by: EmployeeSummary;
    store_id: string;
    merchant_id: string;
}
export interface UpdateThreshold {
    min_quantity?: number;
    min_total_money?: number;
    updated_by: EmployeeSummary;
    store_id: string;
    merchant_id: string;
}
export enum ReceiptEvents {
    SaleReceiptCreated = 'SaleReceiptCreated',
    RefundReceiptCreated = 'RefundReceiptCreated'
}

export interface SalesReport extends Timestamps {
    gross_sales: number;
    refunds: number;
    discounts: number;
    cost_of_products: number;
    expenses: number;
    entity_type: EntityTypes;
    sale_receipt_count: number;
    refund_receipt_count: number;
    expense_count: number;
    source_entity_id: string;
    source_entity_type: EntityTypes;
    version: number;
}
export interface StoreSalesReport extends SalesReport {
    store_id: string; // ID of the store
}
export interface EmployeeSalesReport extends SalesReport {
    employee_id: string; // ID of the employee
    employee_name: string; // Name of the employee
}
export interface ProductSalesReport extends SalesReport {
    product_id: string; // ID of the product
    quantity: number; // Quantity of products sold
    product_name: string; // Name of the product
    sku: string; // SKU of the product
}
export interface SalesReportDBItem {
    PK: string; // <SALES_REPORT_{type}>#<entity_id>
    SK: string; // <SALES_REPORT_{type}>_VERSION#<padded_version>
    GSI1PK: string; // <SALES_REPORT_{type}>#<entity_id>
    GSI1SK: string; // <SALES_REPORT_{type}>_VERSION#<padded_version>
    GSI2PK: string; // <source_entitytype>#<entity_id>
    GSI2SK: string; // <SALES_REPORT_{type}>#<entity_id>
    EntityIndexPK: string; // <SALES_REPORT_{type}>
    EntityIndexSK: string; // <SALES_REPORT_{type}>#DATE#<date>
}
export interface StoreSalesReportDBItem extends StoreSalesReport, SalesReportDBItem {
}
export interface StoreSalesReportDBItemSnapshot extends AggregateStoreSalesReport {
    PK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#<product_id>
    SK: string; // PRODUCT_SALES_REPORT_VERSION#<padded_version>
    GSI1PK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#<product_id>
    GSI1SK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#DATE#<date
    EntityIndexPK: string; // PRODUCT_SALES_REPORT_SNAPSHOT
    EntityIndexSK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#DATE#<date>
}
export interface EmployeeSalesReportDBItem extends EmployeeSalesReport, SalesReportDBItem {
}
export interface EmployeeSalesReportDBItemSnapshot extends AggregateEmployeeSalesReport {
    PK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#<product_id>
    SK: string; // PRODUCT_SALES_REPORT_VERSION#<padded_version>
    GSI1PK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#<product_id>
    GSI1SK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#DATE#<date
    EntityIndexPK: string; // PRODUCT_SALES_REPORT_SNAPSHOT
    EntityIndexSK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#DATE#<date>
}
export interface ProductSalesReportDBItem extends ProductSalesReport, SalesReportDBItem {
}
export interface ProductSalesReportDBItemSnapshot extends AggregateProductSalesReport {
    PK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#<product_id>
    SK: string; // PRODUCT_SALES_REPORT_VERSION#<padded_version>
    GSI1PK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#<product_id>
    GSI1SK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#DATE#<date
    EntityIndexPK: string; // PRODUCT_SALES_REPORT_SNAPSHOT
    EntityIndexSK: string; // PRODUCT_SALES_REPORT_SNAPSHOT#DATE#<date>
}
export interface SalesReportExtensions {
    net_sales: number;
    gross_profit: number;
    net_profit: number;
    gross_margin: number;
    net_margin: number;
}
export interface AggregateStoreSalesReport extends StoreSalesReport, SalesReportExtensions {
}
export interface AggregateEmployeeSalesReport extends EmployeeSalesReport, SalesReportExtensions {
}
export interface AggregateProductSalesReport extends ProductSalesReport, SalesReportExtensions {
}
export interface AggregateSalesReport {
    store: AggregateStoreSalesReport;
    employees: AggregateEmployeeSalesReport[];
    products: AggregateProductSalesReport[];
}
export interface GetSalesReport {
    merchant_id: string;
    store_id: string;
    products?: string[];
    from?: string;
    to?: string;
    date?: string;
    by_date?: boolean;
    by_date_range?: boolean;
    by_products_and_date?: boolean;
    by_products_and_date_range?: boolean;
}
export interface GetInventoryValue {
    merchant_id: string;
    store_id: string;
    date?: string;
}
export interface GetProductsInventoryValue {
    merchant_id: string;
    store_id: string;
    date: string;
    cursor?: string;
    limit?: number;
}
export interface ProductInventoryValue {
    price: number;
    cost: number;
    inventory_value: number;
    retail_value: number;
    potential_profit: number;
    margin: number;
    created_at: string;
    product_id: string;
    product_name: string;
    store_id: string;
    in_stock: number;
    merchant_id: string;
}
export interface ProductInventoryValuePage {
    inventory_values: ProductInventoryValue[];
    count: number;
    cursor?: string;
}
export interface StoreInventoryValue {
    store_id: string;
    merchant_id: string;
    inventory_value: number;
    potential_profit: number;
    retail_value: number;
    margin: number;
    created_at: string;
}
export interface MerchantInventoryValue {
    merchant_id: string;
    merchant_name: string;
    inventory_value: number;
    potential_profit: number;
    retail_value: number;
    margin: number;
    created_at: string;
}
export interface StoreValueSnapshotDBItem extends StoreInventoryValue {
    PK: string; // MERCHANT#<merchant_id>#STORE_VALUE_SNAPSHOT#<store_id>
    SK: string; // STORE_VALUE_SNAPSHOT#<datetime>
    EntityIndexPK: string; //STORE_VALUE_SNAPSHOT
    EntityIndexSK: string; //MERCHNAT#<merchant_id>#STORE#<store_id>#DATE#<datetime>
}
export interface UpdateStoreInventorySQSMessage {
    store_id: string;
    merchant_id: string;
}
/**
 * Interval types for date-range segmentation
 */
export enum SalesReportInterval {
    HOURLY = 'hourly',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    ANNUALLY = 'annually',
    NONE = 'none'
}
/**
 * Request options for retrieving interval-based reports
 */
export interface GetSalesReportByDateRangeIntervalOptions {
    /**
     * The entity type (STORE, USER, STORE_PRODUCT, etc.)
     */
    entityType: EntityTypes;
    /**
     * The entity ID (e.g. store_id, employee_id, product_id)
     */
    entityId: string;
    /**
     * The start date/time of the requested range (ISO string)
     */
    from: string;
    /**
     * The end date/time of the requested range (ISO string)
     */
    to: string;
    /**
     * The interval to group data by (hourly, daily, weekly, etc.)
     */
    interval?: SalesReportInterval;
    /**
     * If true, each interval is cumulative of all previous intervals
     */
    is_cumulative?: boolean;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface InventoryLevel {
    variant_id: string;
    store_id: string;
    in_stock: number;
    updated_at: string;
    update_id?: string; // Does not come with the webhook
}
export interface InventoryLevelsOutput {
    inventory_levels: InventoryLevel[];
}
export interface InventoryLevelProps {
    variant_id: string;
    store_id: string;
    stock_after: number;
}
export interface InventoryLevelsProps {
    inventory_levels: InventoryLevelProps[];
}
export interface BofrakUploadImageProps {
    image_url: string;
    item_id: string;
}
export interface BofrakOkResponse {
    OK: boolean;
    message?: string;
}
export interface Currency {
    code: string;
    decimal_places: number;
}
export interface BackupDto {
    sku: string;
    name: string;
    price: number;
    inventory: number;
    image_s3_key: string;
    old_image_url: string;
    merchant_id: string;
    trigger_image_backup: boolean;
    new_image_url?: string;
}
export interface BackupItem {
    PK: string;
    SK: string;
    GSI1PK: string;
    GSI1SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
    EntityType: EntityTypes;
    sku: string;
    name: string;
    price: number;
    inventory: number;
    image_s3_key: string;
    merchant_id: string;
    old_image_url: string;
    trigger_image_backup: boolean;
    new_image_url?: string;
}
export interface MerchantInfo {
    id: string;
    business_name: string;
    email: string;
    country: string;
    currency: Currency;
    created_at: string;
}
export interface WriteResponse {
    message: string;
    isSuccessful: boolean;
}
export enum GSI_DESCRIPTORS {
    CONFIRMATION_TYPE = 'CONFIRMATION_TYPE',
    REASON = 'REASON',
    SOURCE_TYPE = 'SOURCE_TYPE',
    DATE = 'DATE',
    FAILURE = 'FAILURE',
    SUCCESS = 'SUCCESS',
    CORRELATION = 'CORRELATION',
    DOMAIN = 'DOMAIN',
    PURCHASE_ORDER_COUNT = 'PURCHASE_ORDER_COUNT',
    PAYMENT_STATUS = 'PAYMENT_STATUS',
    PURCHASE_STATUS = 'PURCHASE_STATUS',
    LOAN_COUNT = 'LOAN_COUNT',
    PHONE_NUMBER = 'PHONE_NUMBER',
    START_DATE = 'START_DATE',
    END_DATE = 'END_DATE',
    YEAR_MONTH = 'YYMM',
    STATUS = 'STATUS',
    IS_MOTHER = 'IS_MOTHER',
    MAC_ADDRESS = 'MAC_ADDRESS',
    MOTHER = 'MOTHER',
    CHILD = 'CHILD',
    ENTITY_TYPE = 'ENTITY_TYPE',
    PURCHASE_ORDER_PAYMENT_SUMMARY_VERSION = 'PURCHASE_ORDER_PAYMENT_SUMMARY_VERSION',
    VERSION = 'VERSION',
    STORE_SALES_REPORT_VERSION = 'STORE_SALES_REPORT_VERSION',
    EMPLOYEE_SALES_REPORT_VERSION = 'EMPLOYEE_SALES_REPORT_VERSION',
    PRODUCT_SALES_REPORT_VERSION = 'PRODUCT_SALES_REPORT_VERSION',
    STORE_SALES_REPORT_SNAPSHOT_VERSION = 'STORE_SALES_REPORT_SNAPSHOT_VERSION',
    EMPLOYEE_SALES_REPORT_SNAPSHOT_VERSION = 'EMPLOYEE_SALES_REPORT_SNAPSHOT_VERSION',
    PRODUCT_SALES_REPORT_SNAPSHOT_VERSION = 'PRODUCT_SALES_REPORT_SNAPSHOT_VERSION',
    PRODUCT_INVENTORY_VALUE_VERSION = 'PRODUCT_INVENTORY_VALUE_VERSION',
    TOTAL_DUE = 'TOTAL_DUE',
    DUE_DATE = 'DUE_DATE',
    POS_RECEIPT = 'POS_RECEIPT',
    COGNITO_USER = 'COGNITO_USER',
    COGNITO_USERNAME = 'COGNITO_USERNAME',
    IS_PARENT = 'IS_PARENT',
    GLOBAL_SKU = 'GLOBAL_SKU',
    INVENTORY_TYPE = 'INVENTORY_TYPE',
    DEBT_STATUS = 'DEBT_STATUS',
    DEBT_ORIGIN = 'DEBT_ORIGIN',
    DEBT_VERSION = 'DEBT_VERSION'
}
export enum SortOrder {
    ASC = 'ASC',
    DESC = 'DESC'
}
export enum EntityTypes {
    ACCOUNT = 'ACCOUNT',
    BACKUP = 'BACKUP',
    BORROWER_LOAN = 'BORROWER_LOAN',
    CURRENT_INVENTORY = 'CURRENT_INVENTORY',
    CUSTOMER = 'CUSTOMER',
    CUSTOMER_DEBT = 'CUSTOMER_DEBT',
    CUSTOMER_LIABILITIES = 'CUSTOMER_LIABILITIES',
    DEBT = 'DEBT',
    DEBT_CHANGE = 'DEBT_CHANGE',
    DEBT_PAYMENT = 'DEBT_PAYMENT',
    DISCOUNT = 'DISCOUNT',
    EMPLOYEE_SALES_REPORT = 'EMPLOYEE_SALES_REPORT',
    EMPLOYEE_SALES_REPORT_SNAPSHOT = 'EMPLOYEE_SALES_REPORT_SNAPSHOT',
    ERROR = 'ERROR',
    EVENT = 'EVENT',
    IMAGE = 'IMAGE',
    INVENTORY_CHANGE = 'INVENTORY_CHANGE',
    INVENTORY_DISCREPANCY = 'INVENTORY_DISCREPANCY',
    INVENTORY_HISTORY = 'INVENTORY_HISTORY',
    INVENTORY_TEMPLATE = 'INVENTORY_TEMPLATE',
    ITEM_INVENTORY_VALUE = 'ITEM_INVENTORY_VALUE',
    LOAN = 'LOAN',
    LOYALTY_PROGRAM = 'LOYALTY_PROGRAM',
    MERCHANT = 'MERCHANT',
    PAYMENT = 'PAYMENT',
    PAYMENT_TYPE = 'PAYMENT_TYPE',
    POS_DEVICE = 'POS_DEVICE',
    PRODUCT = 'PRODUCT',
    PRODUCT_CHANGE = 'PRODUCT_CHANGE',
    PRODUCT_FRACTION = 'PRODUCT_FRACTION',
    PRODUCT_FRACTION_CHANGE = 'PRODUCT_FRACTION_CHANGE',
    PRODUCT_SALES_REPORT = 'PRODUCT_SALES_REPORT',
    PRODUCT_SALES_REPORT_SNAPSHOT = 'PRODUCT_SALES_REPORT_SNAPSHOT',
    PRODUCT_VERSION = 'PRODUCT_VERSION',
    PROMOTION = 'PROMOTION',
    PURCHASE_ORDER = 'PURCHASE_ORDER',
    PURCHASE_ORDER_ITEM = 'PURCHASE_ORDER_ITEM',
    PURCHASE_ORDER_PAYMENT = 'PURCHASE_ORDER_PAYMENT',
    PURCHASE_ORDER_PAYMENT_HISTORY = 'PURCHASE_ORDER_PAYMENT_HISTORY',
    PURCHASE_ORDER_PAYMENT_SUMMARY = 'PURCHASE_ORDER_PAYMENT_SUMMARY',
    RECEIPT = 'RECEIPT',
    RECEIPT_HISTORY = 'RECEIPT_HISTORY',
    STORE = 'STORE',
    STORE_PRODUCT = 'STORE_PRODUCT',
    STORE_PRODUCT_CHANGE = 'STORE_PRODUCT_CHANGE',
    STORE_PRODUCT_VERSION = 'STORE_PRODUCT_VERSION',
    STORE_SALES_REPORT = 'STORE_SALES_REPORT',
    STORE_SALES_REPORT_SNAPSHOT = 'STORE_SALES_REPORT_SNAPSHOT',
    STORE_VALUE_SNAPSHOT = 'STORE_VALUE_SNAPSHOT',
    SUPPLIER = 'SUPPLIER',
    SYSTEM = 'SYSTEM',
    TAX = 'TAX',
    THRESHOLD = 'THRESHOLD',
    USER = 'USER'
}
export enum RelationshipTypes {
    MOTHER = 'MOTHER',
    CHILD = 'CHILD',
    INVENTORY_VARIANT = 'INVENTORY_VARIANT'
}
export enum DefaultPricingType {
    FIXED = 'FIXED',
    VARIABLE = 'VARIABLE'
}
export interface Paginated {
    cursor?: string | null | undefined;
    count: number;
}
export interface EmployeePage {
    employees: Employee[];
    cursor?: string;
    count: number;
}
export enum Form {
    SQUARE = 'SQUARE',
    CIRCLE = 'CIRCLE',
    SUN = 'SUN',
    OCTAGON = 'OCTAGON'
}
export enum Color {
    GREY = 'GREY',
    RED = 'RED',
    PINK = 'PINK',
    ORANGE = 'ORANGE',
    YELLOW = 'YELLOW',
    GREEN = 'GREEN',
    BLUE = 'BLUE',
    PURPLE = 'PURPLE'
}
export interface Component {
    variant_id: string;
    quantity: number;
}
export type UpdateAvailabilityPropsStore = {
    store_id: string;
    available_for_sale: boolean;
};
export type UpdateAvailabilityPropsVariant = {
    variant_id: string;
    item_id: string;
    stores: UpdateAvailabilityPropsStore[];
};
export interface Timestamps {
    /**
     * The ISO 8601 formatted date-time string indicating when this payment type was created.
     * @example "2020-03-25T19:55:23.077Z"
     */
    created_at: string;
    /**
     * The ISO 8601 formatted date-time string indicating when this payment type was last updated.
     * @example "2020-03-30T08:05:10.020Z"
     */
    updated_at: string;
    /**
     * The ISO 8601 formatted date-time string indicating when this payment type was deleted.
     * This field is optional and will be present only if the payment type has been deleted.
     * @example "2020-04-02T23:45:20.050Z"
     */
    deleted_at?: string | null;
}
export type AuthUser = CustomerUserAttributes | MerchantUserAttributes | AdminUserAttributes;
export interface Employee extends Timestamps {
    id: string;
    name: string;
    roles: string[];
    username: string;
    email: string;
    phone_number: string;
    stores: string[];
    merchant_id: string;
    is_active: boolean;
    is_inactive_since?: string;
    cognito_id: string | null | undefined;
    cognito_username: string | null | undefined;
    auth_user?: AuthUser;
}
export interface EmployeeSummary {
    id: string;
    name: string;
}
export enum ErrorCodes {
    NOT_FOUND = 'NOT_FOUND',
    FORBIDDEN = 'FORBIDDEN',
    UNAUTHORIZED = 'UNAUTHORIZED',
    BAD_REQUEST = 'BAD_REQUEST',
    INVALID_PARAMETER = 'INVALID_PARAMETER',
    INVALID_REQUEST = 'INVALID_REQUEST',
    INVALID_COMMAND = 'INVALID_COMMAND',
    CONFLICTING_REQUEST = 'CONFLICTING_REQUEST',
    INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
    NO_RESPONSE = 'NO_RESPONSE'
}
export interface DateRange {
    to: string;
    from: string;
}
export enum ReceiptType {
    SALE = 'SALE',
    REFUND = 'REFUND'
}
export interface TotalMoneyRange {
    to: number;
    from: number;
}
export interface EventBusDto {
    data: any;
    metadata: Record<string, unknown>;
}
export enum ShopAndSmileEventSource {
    SHOP_AND_SMILE = 'shop-and-smile',
    SHOP_AND_SMILE_PRODUCTS = 'shop-and-smile.products',
    SHOP_AND_SMILE_INVENTORY = 'shop-and-smile.inventory',
    SHOP_AND_SMILE_RECEIPTS = 'shop-and-smile.receipts',
    SHOP_AND_SMILE_REPORTS = 'shop-and-smile.reports'
}
export interface PaginationParams {
    cursor?: string | null | undefined;
    limit?: number;
}
export interface UploadImageToItemProps {
    item_id: string;
    image_url: string;
}
export interface Connection {
    userId: string;
    connectionId: string;
    connected: boolean;
    stage: string;
    domainName: string;
}
export interface ConnectionItem {
    PK: string;
    SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
    GSI1PK: string;
    GSI1SK: string;
    userId: string;
    connectionId: string;
    connected: boolean;
    stage: string;
    domainName: string;
}
export interface SSMParameterConfig {
    path: string;
    outputKey: string;
    description?: string;
    secure?: boolean;
}
// Define the user roles as an enum
export enum UserRole {
    MANAGER = 'MANAGER',
    ADMIN = 'ADMIN',
    WAITER = 'WAITER',
    SYSTEM = 'SYSTEM',
    CASHIER = 'CASHIER',
    RECEIPT_CHECKER = 'RECEIPT_CHECKER',
    INVENTORY_COUNTER = 'INVENTORY_COUNTER',
    SUPPLIER = 'SUPPLIER'
}
export enum PaymentName {
    CASH = 'Cash',
    DEBT = 'Divida',
    BANK_CARD = 'Bank Card',
    MOBILE_MONEY = 'Mobile Money',
    EMOLA = 'Emola',
    MPEZA = 'Mpeza',
    CARD = 'Card',
    SPLIT = 'Split'
}
// Define the user interface
export interface User {
    name: string;
    surname: string;
    phonenumber: string;
    code: string;
    user_id: string;
    role: UserRole;
    auth_type?: AuthType;
}
export interface CreateUserProps {
    name: string;
    surname: string;
    phonenumber?: string;
    code: string;
    role: UserRole;
    merchant_id: string;
    stores: string[];
}
export interface ReceiptQueryParams extends PaginationParams {
    receipt_numbers?: string;
    since_receipt_number?: string;
    before_receipt_number?: string;
    store_id?: string;
    order?: string;
    updated_at_min?: string;
    updated_at_max?: string;
    created_at_min?: string;
    created_at_max?: string;
}
export interface CommonEmployeeAsCreatorAttributes {
    creator_id: string;
    creator_type: EntityTypes;
    creator_name: string;
}
export interface UserStore {
    merchant_id: string;
    store_id: string;
    user_id: string;
}
export interface CognitoStoresAttribute extends Timestamps {
    stores: UserStore[];
}
export enum StringBoolean {
    TRUE = 'true',
    FALSE = 'false'
}
export interface ChangedAttributes {
    [key: string]: {
        old: any;
        new: any;
    };
}

export interface Store extends Timestamps {
    id: string;
    name: string;
    address: string | null;
    city: string;
    region: string | null;
    postal_code: string | null;
    country_code: string;
    phone_number: string;
    description: string | null;
    merchant_id: string;
    contact_person: string;
    email: string | null;
    contact_person_phone_number: string;
    tax_number: string | null;
    store_prefix: string;
}
export interface StorePage {
    stores: Store[];
    count: number;
    cursor?: string;
}
export interface CreateStore {
    name: string;
    merchant_id: string;
    contact_person: string;
    contact_person_phone_number: string;
    address?: string;
    city: string;
    region?: string;
    postal_code?: string;
    country_code?: string;
    phone_number: string;
    description?: string;
    email?: string;
    tax_number?: string;
}
export interface UpdateStore {
    name?: string;
    contact_person?: string;
    contact_person_phone_number?: string;
    address?: string;
    city?: string;
    region?: string;
    postal_code?: string;
    country_code?: string;
    phone_number?: string;
    description?: string;
    email?: string;
    id: string;
    merchant_id: string;
    tax_number?: string;
}
export interface StoreDBItem extends Store {
    PK: string; // MERCHANT#{merchant_id}
    SK: string; // STORE#{store_id}
    EntityIndexPK: string; // STORE
    EntityIndexSK: string; // MERCHANT#{merchant_id}#DATE#{created_at}
}
export interface GetStoresByMerchantDto {
    merchant_id: string;
    cursor?: string;
    limit?: number;
}

export interface Supplier extends Timestamps {
    id: string;
    name: string;
    contact?: string;
    email?: string;
    phone_number?: string;
    website?: string;
    address_1: string;
    address_2?: string;
    city?: string;
    region?: string;
    postal_code?: string;
    country_code?: string;
    note?: string;
    merchant_id: string;
}
export interface SupplierPage {
    cursor?: string;
    count?: number;
    suppliers: Supplier[];
}
export interface CreateSupplier {
    name: string;
    merchant_id: string;
    email?: string;
    phone_number?: string;
    website?: string;
    address_1?: string;
    address_2?: string;
    city?: string;
    region?: string;
    postal_code?: string;
    note?: string;
    country_code?: string;
    contact?: string;
}
export interface UpdateSupplier {
    id: string;
    name?: string;
    email?: string;
    phone_number?: string;
    website?: string;
    address_1?: string;
    address_2?: string;
    city?: string;
    region?: string;
    postal_code?: string;
    note?: string;
    country_code?: string;
    contact?: string;
}
export interface SupplierDBItem extends Supplier {
    PK: string; // SUPPLIER#id
    SK: string; // SUPPLIER#id
    GSI1PK: string; // MERCHANT#id
    GSI1SK: string; // SUPPLIER#id
    EntityIndexPK: string; // SUPPLIER
    EntityIndexSK: string; // MERCHANT#id#DATE#created_at
}
export interface GetSupplierById {
    id: string;
}
export interface GetSuppliers {
    merchant_id: string;
    cursor?: string;
    limit?: number;
}

//help.loyverse.com/help/how-taxes-are-calculated
export enum TaxType {
    INCLUDED = 'INCLUDED',
    ADDED = 'ADDED'
}
/**
 * Tax interface
 * @interface
 * @name Tax
 * @property {string} id - The tax id
 * @property {TaxType} type - The tax type
 * @property {string} name - The tax name
 * @property {number} rate - The tax rate. For example, a value of "5.255" corresponds to a percentage of 5.255%
 * @property {string} merchant_id - The merchant id
 * @property {string} created_at - The created at timestamp
 * @property {string} updated_at - The updated at timestamp
 * @property {string} deleted_at - The deleted at timestamp
 * @example
 * {
 *  id: 'tax_id',
 * type: TaxType.INCLUDED,
 * name: 'tax_name',
 * rate: 0.1,
 * merchant_id: 'merchant_id',
 *
 */
export interface Tax extends Timestamps {
    id: string;
    type: TaxType;
    name: string;
    rate: number;
    merchant_id: string;
}
/**
 * Tax page interface
 * @interface
 * @name TaxPage
 * @property {Tax[]} taxes - The tax list
 * @property {number} count - The tax count
 * @property {string} cursor - The cursor
 * @example
 * {
 *  taxes: [],
 * count: 0,
 * cursor: 'cursor',
 * }
 */
export interface TaxPage {
    taxes: Tax[];
    count: number;
    cursor?: string;
}
/**
 * Create tax interface
 * @interface
 * @name CreateTax
 * @property {string} name - The tax name
 * @property {TaxType} type - The tax type
 * @property {number} rate - The tax rate
 * @property {string} merchant_id - The merchant id
 * @example
 * {
 *  name: 'tax_name',
 * type: TaxType.INCLUDED,
 * rate: 0.1,
 * merchant_id: 'merchant_id',
 * }
 */
export interface CreateTax {
    name: string;
    type: TaxType;
    rate: number;
    merchant_id: string;
}
/**
 * Update tax interface
 * @interface
 * @name UpdateTax
 * @property {string} id - The tax id
 * @property {string} name - The tax name
 * @property {TaxType} type - The tax type
 * @property {number} rate - The tax rate
 * @property {string} merchant_id - The merchant id
 * @example
 * {
 *  id: 'tax_id',
 * name: 'tax_name',
 * type: TaxType.INCLUDED,
 * rate: 0.1,
 * merchant_id: 'merchant_id',
 * }
 */
export interface UpdateTax {
    id: string;
    name?: string;
    type?: TaxType;
    rate?: number;
    merchant_id: string;
}
/**
 * Tax DB item interface
 * @interface
 * @name TaxDBItem
 * @property {string} PK - The partition key
 * @property {string} SK - The sort key
 * @property {string} GSI1PK - The global secondary index 1 partition key
 * @property {string} GSI1SK - The global secondary index 1 sort key
 * @property {string} EntityIndexPK - The tax entity index partition key
 * @property {string} EntityIndexSK - The tax entity index sort key
 * @property {string} id - The tax id
 * @property {TaxType} type - The tax type
 * @property {string} name - The tax name
 * @property {number} rate - The tax rate
 * @property {string} merchant_id - The merchant id
 * @property {string} created_at - The created at timestamp
 * @property {string} updated_at - The updated at timestamp
 * @property {string} deleted_at - The deleted at timestamp
 * @example
 * {
 * PK: 'TAX#{tax_id}',
 * SK: 'TAX#{tax_id}',
 * GSI1PK: 'STORE#{merchant_id}',
 * GSI1SK: 'TAX#{tax_id}',
 * EntityIndexPK: 'TAX',
 * EntityIndexSK: 'STORE#{merchant_id}#DATE#{created_at}',
 * id: 'tax_id',
 * type: TaxType.INCLUDED,
 * name: 'tax_name',
 * rate: 0.1,
 * merchant_id: 'merchant_id',
 * created_at: 'timestamp',
 * updated_at: 'timestamp',
 * deleted_at: 'timestamp',
 * }
 */
export interface TaxDBItem extends Tax {
    PK: string;
    SK: string;
    GSI1PK: string;
    GSI1SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
}
/**
 * Get tax by id interface
 * @interface
 * @name GetTaxById
 * @property {string} id - The tax id
 * @example
 * {
 * id: 'tax_id',
 * }
 */
export interface GetTaxById {
    id: string;
}
/**
 * Get taxes by merchant id interface
 * @interface
 * @name GetTaxesByStoreId
 * @property {string} merchant_id - The merchant id
 * @property {number} limit - The limit
 * @property {string} cursor - The cursor
 * @example
 * {
 * merchant_id: 'merchant_id',
 * limit: 10,
 * cursor: 'cursor',
 * }
 */
export interface GetTaxes {
    merchant_id: string;
    limit: number;
    cursor?: string;
}
/**
 * Calculate tax commands
 * @enum
 * @name CalculateTaxCommands
 *
 */
export enum CalculateTaxCommands {
    ADDED_TO_THE_PRICE = 'ADDED_TO_THE_PRICE',
    INCLUDED_IN_THE_PRICE = 'INCLUDED_IN_THE_PRICE',
    MULTIPLE_ADDED_TO_THE_PRICE = 'MULTIPLE_ADDED_TO_THE_PRICE',
    MULTIPLE_INCLUDED_IN_THE_PRICE = 'MULTIPLE_INCLUDED_IN_THE_PRICE',
    ADDED_TO_THE_PRICE_WITH_INCLUDED = 'ADDED_TO_THE_PRICE_WITH_INCLUDED'
}
/**
 * Calculate tax interface
 * @interface
 * @name CalculateTax
 * @property {number} amount - The amount
 * @property {Tax[]} taxes - The tax list
 * @property {CalculateTaxCommands} command - The calculate tax command
 * @example
 * {
 * amount: 100,
 * taxes: [],
 * command: CalculateTaxCommands.ADDED,
 * }
 */
export interface CalculateTax {
    item_price: number;
    tax_ids: string[];
    added_tax_id: string;
    included_tax_ids: string[];
    command: CalculateTaxCommands;
}
export interface CalculateTaxResult {
    command: CalculateTaxCommands;
    item_price: number;
    tax_value: number | null; // Tax value for single added/included tax
    pre_tax_price: number | null; // Pre-tax price for included tax calculations
    total_tax: number | null; // Total tax value for multiple taxes (added/included)
    added_tax: number | null; // Added tax value for "added-with-included"
}

export interface UserItem extends Employee {
    PK: string;
    SK: string;
    EntityIndexPK: string;
    EntityIndexSK: string;
    GSI1PK: string;
    GSI1SK: string;
    GSI2PK: string;
    GSI2SK: string;
    GSI3PK?: string; // COGNITO_USER#{cognito_id}
    GSI3SK?: string; // COGNITO_USER#{cognito_id}
    GSI4PK?: string; // COGNITO_USERNAME#{cognito_username}
    GSI4SK?: string; // COGNITO_USERNAME#{cognito_username}
    PIN: string;
}
export interface UpdateUserItem extends Partial<UserItem> {
    GSI1PK: string;
    GSI1SK: string;
    GSI2PK?: string;
    GSI2SK?: string;
    GSI3PK?: string;
    GSI3SK?: string;
    GSI4PK?: string;
    GSI4SK?: string;
    PIN: string;
}
export interface CreateUser {
    name: string;
    roles: string[];
    username: string;
    email: string;
    phone_number: string;
    PIN: string;
    stores: string[];
    merchant_id: string;
}
export type UpdateUser = Partial<Employee> & Partial<UpdateMerchantUserRequestBody> & {
    id: string;
    auth_type: AuthType;
    is_active?: boolean;
    PIN?: string;
    stores?: string[];
};