import { Merchant, Store } from '@bofrak-backend/shared';
import {
  Loader,
  NavBar,
  StoreSelectionModal,
  useAuth,
} from '@bofrak-backend/shared-ui';
import { Box, Button, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { apiAdapter } from './api';
import ProductList from './components/items/products';
import ProductListPage from './components/items/products/AddProduct';
import { STAGE } from './constants/environment';
import { routes } from './constants/routes';
import {
  accessTokenAtom,
  adminAtom,
  merchantAtom,
  selectedStoreAtom,
} from './recoil/atoms';

function App() {
  const query = new URLSearchParams(useLocation().search);
  const [storeId, setStoreId] = useState<string | null>(null);
  const setSelectedStore = useSetRecoilState(selectedStoreAtom);
  const [merchant, setMerchant] = useRecoilState(merchantAtom);
  const setAccessToken = useSetRecoilState(accessTokenAtom);
  const setAdmin = useSetRecoilState(adminAtom);

  const {
    employee,
    signOut,
    isLoading,
    errorMessage,
    currentStore,
    availableStores,
    requiresStoreSelection,
    setCurrentStoreById,
  } = useAuth({
    applicationName: merchant?.business_name ?? '',
    stage: STAGE,
  });

  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Open the modal if store selection is required
  useEffect(() => {
    if (requiresStoreSelection) {
      onOpen();
    }
  }, [requiresStoreSelection, onOpen]);

  useEffect(() => {
    setAdmin(employee);

    const storeIdFromUrl = query.get('store_id');
    if (storeIdFromUrl) {
      setStoreId(storeIdFromUrl);
    } else {
      if (currentStore) {
        setStoreId(currentStore.id);
        setSelectedStore(currentStore);
      }
    }

    if (currentStore && storeId !== currentStore.id) {
      setSelectedStore(currentStore);
      setStoreId(currentStore.id);
    }
  }, [employee, query, currentStore]);

  useQuery(
    `storeId_${storeId}`,
    () => apiAdapter.getStore(storeId!, employee!.merchant_id),
    {
      enabled: !!storeId && !!employee && !currentStore,
      keepPreviousData: true,
      onSuccess: (data) => {
        setSelectedStore(data as Store);
      },
    },
  );

  useQuery(
    `get-merchant-${employee?.id}`,
    () => apiAdapter.getMerchant(employee?.merchant_id as string),
    {
      enabled: !!employee,
      keepPreviousData: true,
      onSuccess: (data) => {
        setMerchant(data as Merchant);
      },
    },
  );

  useEffect(() => {
    async function onStartup() {
      const urlParams = new URLSearchParams(window.location.search);
      const encryptedToken = urlParams.get('token');

      if (encryptedToken) {
        try {
          const decryptedObject = await apiAdapter.decryptUserData({
            encryptedCredentials: encryptedToken,
          });

          setAccessToken(decryptedObject.AccessToken);
        } catch (error) {
          console.error('Failed to decrypt token:', error);
        }
      }
    }
    onStartup();
  }, []);

  useEffect(() => {
    if (employee && storeId) {
      const hasToken = query.has('token');
      const hasStoreId = query.has('store_id');

      if (hasToken || hasStoreId) {
        query.delete('token');
        query.delete('store_id');
        const newParamsString = query.toString();

        navigate(
          {
            pathname: window.location.pathname,
            search: newParamsString ? `?${newParamsString}` : '',
          },
          { replace: true },
        );
      }
    }
  }, [employee, storeId]);

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh">
        <Loader />
      </Box>
    );
  }

  if (errorMessage) {
    return (
      <Box p={4}>
        <Text color="red.500">{errorMessage}</Text>
        <Button onClick={signOut} mt={4} colorScheme="blue">
          Sign Out
        </Button>
      </Box>
    );
  }

  return employee ? (
    <>
      <NavBar
        appName="Items"
        homePath={routes.home}
        merchant={merchant ? merchant.business_name : ''}
        signOut={signOut}
        setCurrentStore={setSelectedStore}
        stage={STAGE}
        storeId={storeId ?? ''}
        user={employee}
        merchantId={merchant?.id ?? employee.merchant_id}
        availableStores={availableStores}
        onChangeStore={setCurrentStoreById}
      />

      <Routes>
        <Route path={'/items'} element={<ProductList />} />
        <Route path={'/'} element={<ProductList />} />
        <Route path={routes.newProduct} element={<ProductListPage />} />
      </Routes>
      <StoreSelectionModal
        availableStores={availableStores}
        isOpen={isOpen}
        onClose={() => {
          // Prevent closing the modal without selecting a store
          if (!currentStore) {
            return;
          }
          onClose();
        }}
        onSelectStore={setCurrentStoreById}
      />
    </>
  ) : (
    <VStack height={'100vh'} width={'full'}>
      <Loader />
      <StoreSelectionModal
        availableStores={availableStores}
        isOpen={isOpen}
        onClose={() => {
          // Prevent closing the modal without selecting a store
          if (!currentStore) {
            return;
          }
          onClose();
        }}
        onSelectStore={setCurrentStoreById}
      />
    </VStack>
  );
}

export default App;
