/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  colors,
  GetStoreProductChanges,
  SortOrder,
  StoreProduct,
  StoreProductChange,
  StoreProductChangeEvents,
} from '@bofrak-backend/shared';
import { DatePicker, ProductHistoryTable } from '@bofrak-backend/shared-ui';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { apiAdapter } from '../../../api';
import Error from '../../resuable/Error';

interface ProductHistoryProps {
  product: StoreProduct;
  filters: StoreProductChangeEvents[];
}

// Can add more in the future
enum FetchCommands {
  by_store_event_store_product_and_date_range = 'by_store_event_store_product_and_date_range',
  by_store_event_and_store_product = 'by_store_event_and_store_product',
  by_store_and_event = 'by_store_event_and_store',
}

// Can add more in the future
const getFlagFromCommand = (command: FetchCommands) => {
  switch (command) {
    case FetchCommands.by_store_event_store_product_and_date_range:
      return { by_store_event_store_product_and_date_range: true };
    case FetchCommands.by_store_event_and_store_product:
      return { by_store_event_and_store_product: true };
    case FetchCommands.by_store_and_event:
      return { by_store_and_event: true };
  }
};

const getLabelFromEvent = (event: StoreProductChangeEvents) => {
  switch (event) {
    case StoreProductChangeEvents.STORE_PRODUCT_SOLD_ONLINE_UPDATED:
      return 'Sold Online Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_DISPLAY_COST_ON_POS_UPDATED:
      return 'Display Cost Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_COST_UPDATED:
      return 'Cost Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_PRICE_UPDATED:
      return 'Price Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_MIN_SELLING_PRICE_UPDATED:
      return 'Min Selling Price Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_MAX_SELLING_PRICE_UPDATED:
      return 'Max Selling Price Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_FRACTIONS_UPDATED:
      return 'Fractions Updated';
    case StoreProductChangeEvents.STORE_PREFIX_UPDATED:
      return 'Prefix Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_DELETED:
      return 'Deleted';
    case StoreProductChangeEvents.STORE_PRODUCT_LOYALTY_PROGRAMS_UPDATED:
      return 'Loyalty Programs Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_MANUAL_CONTROL_UPDATED:
      return 'Manual Control Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_IS_SOLD_BY_WEIGHT_UPDATED:
      return 'Sold By Weight Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_IS_AVAILABLE_FOR_SALE_UPDATED:
      return 'Available For Sale Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_LOW_STOCK_THRESHOLD_UPDATED:
      return 'Low Stock Threshold Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_INVENTORY_UPDATED:
      return 'Inventory Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_SOLD_BY_WEIGHT_UPDATED:
      return 'Sold By Weight Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_IS_AVAILABLE_IN_STORE_UPDATED:
      return 'Available In Store Updated';
    case StoreProductChangeEvents.STORE_SKU_UPDATED:
      return 'SKU Updated';
    case StoreProductChangeEvents.STORE_PRODUCT_CREATED:
      return 'Created';
    default:
      return event;
  }
};

const ProductHistory = ({ product, filters }: ProductHistoryProps) => {
  const [selectedEvents, setSelectedEvents] =
    useState<StoreProductChangeEvents[]>(filters);
  const [dateRange, setDateRange] = useState<{
    start: Date | null;
    end: Date | null;
  }>({
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
    end: new Date(),
  });

  const isAllSelected = selectedEvents.length === filters.length;

  const fetchCommand = useMemo(() => {
    if (isAllSelected && !dateRange.start) {
      return FetchCommands.by_store_event_and_store_product;
    } else {
      return FetchCommands.by_store_event_store_product_and_date_range;
    }
  }, [isAllSelected, dateRange, selectedEvents]);

  const fetchInventoryChanges = async ({ pageParam = {} as any }) => {
    const fetchDtoBase: GetStoreProductChanges = {
      product_id: product.id,
      store_id: product.store_id,
      limit: 25,
      sort_order: SortOrder.DESC,
      ...getFlagFromCommand(fetchCommand),
    };

    if (dateRange.start) {
      fetchDtoBase.start_date = dateRange.start.toISOString();
      fetchDtoBase.end_date = dateRange.end?.toISOString();
    }

    console.log(dateRange);

    let allChanges: StoreProductChange[] = [];
    const nextPageParams: any = {};

    if (
      fetchCommand ===
        FetchCommands.by_store_event_store_product_and_date_range &&
      selectedEvents.length > 0
    ) {
      // Fetch for each event individually
      const responses = await Promise.all(
        selectedEvents.map(async (event) => {
          const fetchDto: GetStoreProductChanges = {
            ...fetchDtoBase,
            event,
            cursor: pageParam[event as string] || undefined,
          };
          const response = await apiAdapter.getStoreProductChanges({
            storeProductChangeDto: fetchDto,
            merchant_id: product.merchant_id,
          });
          return { event, ...response };
        }),
      );

      // Combine changes and cursors
      for (const { event, changes, cursor } of responses) {
        allChanges = [...allChanges, ...changes];
        if (cursor) {
          nextPageParams[event] = cursor;
        }
      }
    } else {
      // Single fetch
      const fetchDto: GetStoreProductChanges = {
        ...fetchDtoBase,
        cursor: pageParam.cursor || undefined,
      };
      const response = await apiAdapter.getStoreProductChanges({
        storeProductChangeDto: fetchDto,
        merchant_id: product.merchant_id,
      });
      allChanges = response.changes;
      if (response.cursor) {
        nextPageParams.cursor = response.cursor;
      }
    }

    return {
      changes: allChanges,
      nextPageParams:
        Object.keys(nextPageParams).length > 0 ? nextPageParams : undefined,
    };
  };

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [
      'inventoryChanges',
      product.id,
      product.store_id,
      dateRange,
      selectedEvents,
      fetchCommand,
    ],
    fetchInventoryChanges,
    {
      getNextPageParam: (lastPage) => lastPage.nextPageParams,
    },
  );

  const handleEventChange = (values: StoreProductChangeEvents[]) => {
    setSelectedEvents(values);
  };

  const handleSelectAll = () => {
    setSelectedEvents(isAllSelected ? [] : filters);
  };

  const selectedEventsLabel = () => {
    if (isAllSelected || selectedEvents.length === 0) {
      return 'All Events';
    } else {
      return selectedEvents.length === 1
        ? getLabelFromEvent(selectedEvents[0])
        : `${selectedEvents.length} Events`;
    }
  };

  if (error) {
    return (
      <Error
        description={
          error instanceof Error
            ? (error as Error).message
            : 'An error occurred'
        }
      />
    );
  }

  const allChanges = data?.pages.flatMap((page) => page.changes) ?? [];

  return (
    <VStack align="stretch" spacing={4}>
      <HStack width="100%">
        <DatePicker
          isRange={true}
          onDateChosen={(start: Date, end?: Date) => {
            console.log({ start, end }, 'jhfhf');
            setDateRange({ start, end: end || null });
          }}
        />
        <Spacer />
        <Box>
          <Menu closeOnSelect={false}>
            <MenuButton
              bg={colors.blue}
              color="white"
              as={Button}
              rightIcon={<ChevronDownIcon />}>
              {selectedEventsLabel()}
            </MenuButton>

            <MenuList maxWidth="240px">
              <MenuItem onClick={handleSelectAll}>
                <Checkbox
                  isChecked={isAllSelected}
                  pointerEvents="none"
                  mr={2}
                />
                <Text>Select All</Text>
              </MenuItem>
              <MenuDivider />
              <CheckboxGroup
                colorScheme="blue"
                value={selectedEvents}
                onChange={handleEventChange}>
                {filters.map((filter) => (
                  <MenuItem key={filter}>
                    <Checkbox value={filter}>
                      {getLabelFromEvent(filter)}
                    </Checkbox>
                  </MenuItem>
                ))}
              </CheckboxGroup>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
      <Button
        isLoading={isFetching}
        borderRadius="full"
        onClick={() => refetch()}
        bg={colors.green}
        color="white">
        Fetch
      </Button>
      <ProductHistoryTable data={allChanges} />
      {hasNextPage && (
        <Flex justify="center" mt={4}>
          <Button
            bg={colors.green}
            borderRadius={'full'}
            onClick={() => fetchNextPage()}
            isLoading={isFetchingNextPage}
            isDisabled={!hasNextPage}>
            Load More
          </Button>
        </Flex>
      )}
    </VStack>
  );
};

export default ProductHistory;
