// useAuth.ts
import { useEffect, useState } from 'react';
import { Employee, EncryptCredentialsDto, Store } from '@bofrak-backend/shared';
import { apiAdapter } from '../utils/api';

interface UseAuthReturn {
  decryptedCredentials: EncryptCredentialsDto | null;
  encryptedCredentials: string | null;
  errorMessage: string | null;
  isLoading: boolean;
  employee: Employee | null;
  signOut: () => Promise<void>;
  currentStore: Store | null;
  availableStores: Store[];
  requiresStoreSelection: boolean;
  setCurrentStoreById: (storeId: string) => Promise<void>;
}

interface UseAuthParams {
  stage: string;
  applicationName: string;
}

export const useAuth = ({ stage }: UseAuthParams): UseAuthReturn => {
  const [decryptedCredentials, setDecryptedCredentials] =
    useState<EncryptCredentialsDto | null>(null);
  const [encryptedCredentials, setEncryptedCredentials] = useState<
    string | null
  >(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [currentStore, setCurrentStore] = useState<Store | null>(null);
  const [storeId, setStoreId] = useState<string | null>(null);
  const [availableStores, setAvailableStores] = useState<Store[]>([]);

  // Function to handle user sign out
  const signOut = async (): Promise<void> => {
    setIsLoading(true);

    try {
      let accessToken: string | undefined;

      if (decryptedCredentials) {
        accessToken = decryptedCredentials.AccessToken;
      } else if (encryptedCredentials) {
        const decrypted = await apiAdapter.decryptUserData({
          encryptedCredentials,
        });
        accessToken = decrypted.AccessToken;
      } else {
        const storedEncrypted = localStorage.getItem('encryptedCredentials');
        if (storedEncrypted) {
          const decrypted = await apiAdapter.decryptUserData({
            encryptedCredentials: storedEncrypted,
          });
          accessToken = decrypted.AccessToken;
        }
      }

      if (accessToken) {
        await apiAdapter.signOutUser({
          accessToken,
        });
      }
    } catch (error) {
      console.error('Error during sign out:', error);
    }

    // Clear all relevant states and localStorage
    setEncryptedCredentials(null);
    setDecryptedCredentials(null);
    setEmployee(null);
    setCurrentStore(null);
    setErrorMessage(null);
    localStorage.clear();

    setIsLoading(false);

    // Redirect to the authenticator app with a sign out query param
    const authenticatorUrl = `https://authenticator.${stage}.shopnsmile.org`;

    window.location.href = `${authenticatorUrl}?signOut=true`;
  };

  // Function to set current store by ID
  const setCurrentStoreById = async (
    selectedStoreId: string,
  ): Promise<void> => {
    if (!employee) return;

    try {
      const store = await apiAdapter.getStore(
        selectedStoreId,
        employee.merchant_id,
      );
      setCurrentStore(store);
      setStoreId(selectedStoreId);
      localStorage.setItem('selectedStoreId', selectedStoreId);
    } catch (error) {
      console.error('Failed to set current store:', error);
      setErrorMessage('Failed to select store.');
    }
  };

  // Initial authentication and credential fetching
  useEffect(() => {
    const fetchEncryptedCredentials = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let encrypted = urlParams.get('token')
        ? decodeURIComponent(urlParams.get('token')!)
        : null;

      if (!encrypted) {
        encrypted = localStorage.getItem('encryptedCredentials');
      }

      if (encrypted) {
        setEncryptedCredentials(encrypted);
        try {
          const decrypted = await apiAdapter.decryptUserData({
            encryptedCredentials: encrypted,
          });
          setDecryptedCredentials(decrypted);

          const { Employee: emp } = decrypted;
          setEmployee(emp);

          localStorage.setItem('encryptedCredentials', encrypted);
        } catch (error) {
          console.error('Error decrypting credentials:', error);
          setErrorMessage('Invalid session');
        }
      } else {
        await signOut();
        return;
      }

      const store_id =
        urlParams.get('store_id') || localStorage.getItem('selectedStoreId');

      if (store_id) {
        setStoreId(store_id);
      }

      setIsLoading(false);
    };

    fetchEncryptedCredentials();
  }, []);

  // Fetch current store or available stores based on employee data
  useEffect(() => {
    const fetchStore = async (store_id: string, merchant_id: string) => {
      try {
        const store = await apiAdapter.getStore(store_id, merchant_id);
        setCurrentStore(store);
      } catch (error) {
        console.error('Failed to fetch store:', error);
        setErrorMessage('Failed to fetch store data.');
      }
    };

    const fetchAllStores = async (storeIds: string[], merchant_id: string) => {
      try {
        const storesData: Store[] = await Promise.all(
          storeIds.map((id) => apiAdapter.getStore(id, merchant_id)),
        );
        setAvailableStores(storesData);

        if (storesData.length === 1) {
          // Only one store, set it automatically
          setCurrentStore(storesData[0]);
          setStoreId(storesData[0].id);
          localStorage.setItem('selectedStoreId', storesData[0].id);
        }
      } catch (error) {
        console.error('Failed to fetch stores:', error);
        setErrorMessage('Failed to fetch stores.');
      }
    };

    if (employee && storeId) {
      fetchStore(storeId, employee.merchant_id);
    }

    if (employee) {
      const { merchant_id, stores } = employee;

      if (stores.length > 0) {
        fetchAllStores(stores, merchant_id);
      } else {
        setErrorMessage('No stores available for this employee.');
      }
    }
  }, [storeId, employee]);

  // Determine if store selection is required
  const requiresStoreSelection: boolean =
    !!employee && availableStores.length > 1 && !currentStore;

  return {
    decryptedCredentials,
    encryptedCredentials,
    errorMessage,
    isLoading,
    signOut,
    employee,
    currentStore,
    availableStores,
    requiresStoreSelection,
    setCurrentStoreById,
  };
};
