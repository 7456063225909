import {
  Employee,
  Merchant,
  Store,
  StoreProduct,
} from '@bofrak-backend/shared';
import { atom } from 'recoil';
import { localPersistEffect } from './atom-effects';

export const searchQueryAtom = atom<string>({
  key: 'searchQuery',
  default: '',
});

export const createdItemIdAtom = atom<string>({
  key: 'createdItemAtom',
  default: undefined,
  effects_UNSTABLE: [localPersistEffect], // Make the atom persistent
});

export const loginModalAtom = atom({
  key: 'loginModalState',
  default: {
    isOpen: false,
    selectedUser: null as Employee | null,
    pin: '',
    showPassword: false,
  },
});

export const usersAtom = atom<Employee[]>({
  key: 'usersState',
  default: [],
  effects_UNSTABLE: [],
});

export const userAtom = atom<Employee | null>({
  key: 'userState',
  default: null,
  effects_UNSTABLE: [],
});

export const selectedStoreAtom = atom<Store | null>({
  key: 'selectedStoreAtom',
  default: null,
  effects_UNSTABLE: [],
});

export const merchantAtom = atom<Merchant | null>({
  key: 'merchantAtom',
  default: null,
  effects_UNSTABLE: [],
});

export const adminAtom = atom<Employee | null>({
  key: 'adminAtom',
  default: null,
  effects_UNSTABLE: [],
});

export const accessTokenAtom = atom<string | undefined>({
  key: 'accessTokenAtom',
  default: '',
  effects_UNSTABLE: [],
});

export const storeProductsAtom = atom<StoreProduct[] | undefined>({
  key: 'storeProductsAtom',
  default: [],
  effects_UNSTABLE: [],
});

export const selectedItemProduct = atom<StoreProduct | null>({
  key: 'selectedItemProduct',
  default: null,
  effects_UNSTABLE: [],
});

// export const importCurrentSubItemAtom = atom<Product | null>({
//   key: 'importCurrentSubItemAtom',
//   default: null,
//   effects_UNSTABLE: [localPersistEffect],
// });
