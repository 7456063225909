import { UserRole } from '../types/shared.types';

export const userRoles = Object.values(UserRole);

export const userRolesWithoutSystem = Object.values(UserRole).filter(
  (role) => role !== UserRole.SYSTEM,
);

export const defaultImage =
  'https://odoo-community.org/web/image/product.template/3936/image_1024?unique=6b8ea98';

export const colors = {
  primary: '#007AFF',
  green: '#34c759',
  blue: '#007AFF',
  red: '#ff3a30',
  cyan: '#64d3ff',
  gray: '#c7c7cc',
  black: '#000000',
};
