/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  colors,
  GetInventoryChanges,
  InventoryChange,
  InventoryChangeReason,
  SortOrder,
  StoreProduct,
} from '@bofrak-backend/shared';
import {
  DatePicker,
  ProductPurchaseHistoryTable,
} from '@bofrak-backend/shared-ui';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { apiAdapter } from '../../../api';
import Error from '../../resuable/Error';

interface ProductPurchaseHistoryProps {
  product: StoreProduct;
  filters: InventoryChangeReason[];
}

enum FetchCommands {
  by_store_and_product_and_date_range = 'by_store_and_product_and_date_range',
  by_reason_and_store_and_product_and_date_range = 'by_reason_and_store_and_product_and_date_range',
  by_product_and_store = 'by_product_and_store',
}

const getFlagFromCommand = (command: FetchCommands) => {
  switch (command) {
    case FetchCommands.by_store_and_product_and_date_range:
      return { by_store_and_product_and_date_range: true };
    case FetchCommands.by_reason_and_store_and_product_and_date_range:
      return { by_reason_and_store_and_product_and_date_range: true };
    case FetchCommands.by_product_and_store:
      return { by_product_and_store: true };
  }
};

const ProductPurchaseHistory = ({
  product,
  filters,
}: ProductPurchaseHistoryProps) => {
  const [selectedReasons, setSelectedReasons] =
    useState<InventoryChangeReason[]>(filters);
  const [dateRange, setDateRange] = useState<{
    start: Date | null;
    end: Date | null;
  }>({
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
    end: new Date(),
  });

  const isAllSelected = selectedReasons.length === filters.length;

  const fetchCommand = useMemo(() => {
    if (isAllSelected && !dateRange.start) {
      return FetchCommands.by_product_and_store;
    } else if (isAllSelected && dateRange.start) {
      return FetchCommands.by_store_and_product_and_date_range;
    } else if (
      dateRange.start &&
      selectedReasons.length > 0 &&
      !isAllSelected
    ) {
      return FetchCommands.by_reason_and_store_and_product_and_date_range;
    } else {
      return FetchCommands.by_product_and_store;
    }
  }, [isAllSelected, dateRange, selectedReasons]);

  const fetchInventoryChanges = async ({ pageParam = {} as any }) => {
    const fetchDtoBase: GetInventoryChanges = {
      product_id: product.id,
      store_id: product.store_id,
      merchant_id: product.merchant_id,
      limit: 25,
      sort_order: SortOrder.DESC,
      ...getFlagFromCommand(fetchCommand),
    };

    if (dateRange.start) {
      fetchDtoBase.start_date = dateRange.start.toISOString();
      fetchDtoBase.end_date = dateRange.end?.toISOString();
    }

    console.log(dateRange);

    let allChanges: InventoryChange[] = [];
    const nextPageParams: any = {};

    if (
      fetchCommand ===
        FetchCommands.by_reason_and_store_and_product_and_date_range &&
      selectedReasons.length > 0
    ) {
      // Fetch for each reason individually
      const responses = await Promise.all(
        selectedReasons.map(async (reason) => {
          const fetchDto: GetInventoryChanges = {
            ...fetchDtoBase,
            reason,
            cursor: pageParam[reason as string] || undefined,
          };
          const response = await apiAdapter.getInventoryChanges({
            getInventoryChangesDto: fetchDto,
            merchant_id: product.merchant_id,
          });
          return { reason, ...response };
        }),
      );

      // Combine changes and cursors
      for (const { reason, changes, cursor } of responses) {
        allChanges = [...allChanges, ...changes];
        if (cursor) {
          nextPageParams[reason] = cursor;
        }
      }
    } else {
      // Single fetch
      const fetchDto: GetInventoryChanges = {
        ...fetchDtoBase,
        cursor: pageParam.cursor || undefined,
      };
      const response = await apiAdapter.getInventoryChanges({
        getInventoryChangesDto: fetchDto,
        merchant_id: product.merchant_id,
      });
      allChanges = response.changes;
      if (response.cursor) {
        nextPageParams.cursor = response.cursor;
      }
    }

    return {
      changes: allChanges,
      nextPageParams:
        Object.keys(nextPageParams).length > 0 ? nextPageParams : undefined,
    };
  };

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(
    [
      'inventoryChanges',
      product.id,
      product.store_id,
      dateRange,
      selectedReasons,
      fetchCommand,
    ],
    fetchInventoryChanges,
    {
      getNextPageParam: (lastPage) => lastPage.nextPageParams,
    },
  );

  const handleReasonChange = (values: InventoryChangeReason[]) => {
    setSelectedReasons(values);
  };

  const handleSelectAll = () => {
    setSelectedReasons(isAllSelected ? [] : filters);
  };

  const selectedReasonsLabel = () => {
    if (isAllSelected || selectedReasons.length === 0) {
      return 'All Reasons';
    } else {
      return selectedReasons.length === 1
        ? selectedReasons[0]
        : `${selectedReasons.length} Reasons`;
    }
  };

  if (error) {
    return (
      <Error
        description={
          error instanceof Error
            ? (error as Error).message
            : 'An error occurred'
        }
      />
    );
  }

  const allChanges = data?.pages.flatMap((page) => page.changes) ?? [];

  return (
    <VStack align="stretch" spacing={4}>
      <HStack width="100%">
        <DatePicker
          isRange={true}
          onDateChosen={(start: Date, end?: Date) => {
            console.log({ start, end }, 'jhfhf');
            setDateRange({ start, end: end || null });
          }}
        />
        <Spacer />
        <Box>
          <Menu closeOnSelect={false}>
            <MenuButton
              bg={colors.blue}
              color="white"
              as={Button}
              rightIcon={<ChevronDownIcon />}>
              {selectedReasonsLabel()}
            </MenuButton>

            <MenuList maxWidth="240px">
              <MenuItem onClick={handleSelectAll}>
                <Checkbox
                  isChecked={isAllSelected}
                  pointerEvents="none"
                  mr={2}
                />
                <Text>Select All</Text>
              </MenuItem>
              <MenuDivider />
              <CheckboxGroup
                colorScheme="blue"
                value={selectedReasons}
                onChange={handleReasonChange}>
                {filters.map((filter) => (
                  <MenuItem key={filter}>
                    <Checkbox value={filter}>{filter}</Checkbox>
                  </MenuItem>
                ))}
              </CheckboxGroup>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
      <Button
        isLoading={isFetching}
        borderRadius="full"
        onClick={() => refetch()}
        bg={colors.green}
        color="white">
        Fetch
      </Button>
      <ProductPurchaseHistoryTable data={allChanges} />
      {hasNextPage && (
        <Flex justify="center" mt={4}>
          <Button
            bg={colors.green}
            borderRadius={'full'}
            onClick={() => fetchNextPage()}
            isLoading={isFetchingNextPage}
            isDisabled={!hasNextPage}>
            Load More
          </Button>
        </Flex>
      )}
    </VStack>
  );
};

export default ProductPurchaseHistory;
